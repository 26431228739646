import React, { Component } from "react";
import Modal from "react-responsive-modal";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { Button, CardImg, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import { Get, Post } from "../../function/Koneksi";
import classnames from "classnames";

const listFile = {
  akta_kematian: '',
  kartu_keluarga: '',
}

class AktaKematian extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataDiproses: [],
			dataSelesai: [],
			dataDitolak: [],
			dataDokumenOutput: [],

      detailSurat: null,
      dataPelayanan: null,
			dataPelapor: null,
      fotoPersyaratan: null,
      titlePersyaratan: '',
      linkGambar: listFile,
			active_tab_icon: "1",
      pagination: [],
      status: {
        alert: false,
        modalValidasi: false,
        modalUpload: false,
        modalDetail: false,
        tolakValidasi: false,
        fotoPersyaratan: false,
        btnForm: false,
        btnAksi: false,
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      // dt:frmDef,
      // ============== Select 2 ==============
      frmTingkat: [],
      tingkatStat: [],
      frmDetail: [],
      detailStat: [],
      judul: "Tambah Jabatan Perangkat",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
    };
  }

  componentDidMount(tab = '1') {
    let endPoint = '';

    switch (tab) {
      case '1':
        endPoint = 'pelayanan?jenis_layanan=Penerbitan Akta Kematian&status=Diajukan';
        break;

      case '2':
        endPoint = 'pelayanan?jenis_layanan=Penerbitan Akta Kematian&status=Diproses';
        break;

      case '3':
        endPoint = 'pelayanan?jenis_layanan=Penerbitan Akta Kematian&status=Selesai';
        break;

      case '4':
        endPoint = 'pelayanan?jenis_layanan=Penerbitan Akta Kematian&status=Ditolak';
        break;

      default:
        break;
    }

    Get(endPoint, null, (value) => {
      const data = value.results.data;

      switch (tab) {
				case '1':
					this.setState({ data })
					break;
			
				case '2':
					this.setState({ dataDiproses: data})
					break;
			
				case '3':
					this.setState({ dataSelesai: data})
					break;
			
				case '4':
					this.setState({ dataDitolak: data})
					break;
			
				default:
					break;
			}

			this.setState({
        active_tab_icon: tab
      });
    });
  }

  tombolAksi = (cell, value) => {

    const btnDetail = (
      <Button
        size="sm"
        color="info"
        className="btn-square"
        onClick={() => {
          let status = { ...this.state.status };
          status.modalDetail = true;
          this.setState({
            status,
            detailSurat: value,
            dataPelayanan: JSON.parse(value.data_pelayanan),
            dataPelapor: JSON.parse(value.data_pelapor),
            dataDokumenOutput: JSON.parse(value.dokumen_output)
          });
          // console.log(value);
          // console.log(JSON.parse(value.data_pelayanan));
        }}
        style={{ padding: "0.25rem 0.5rem", borderRadius: "25px" }}
      >
        {/* <i className="fa fa-eye"></i> */}
        Detail
      </Button>
    )

    if (value.data_status.length > 0) {
      if (value.data_status_baru.status == "Diajukan") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {btnDetail}
            <Button
              size="sm"
              color="warning"
              className="btn-square"
              onClick={() => {
                // Tampilkan modal validasi
                let status = { ...this.state.status };
                status.modalValidasi = true;
                this.setState({ status, detailSurat: value });
              }}
							style={{ borderRadius: "25px" }}
            >
              Validasi
            </Button>
          </div>
        );
      } else if (value.data_status_baru.status == "Diproses") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {btnDetail}
            <Button
              size="sm"
              color="success"
              className="btn-square"
              onClick={() => {
                let status = { ...this.state.status };
                status.modalUpload = true;
                this.setState({ status, detailSurat: value });
              }}
              style={{ padding: "0.25rem 0.5rem", borderRadius: "25px" }}
            >
              Upload
            </Button>
          </div>
        );
      } else if (value.data_status_baru.status == "Selesai") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {btnDetail}
          </div>
        );
      } else if (value.data_status_baru.status == "Ditolak") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {btnDetail}
          </div>
        );
      }
    } else {
      return "";
    }
  };

  tutupForm() {
    let status = { ...this.state.status };
    status.modalValidasi = false;
    status.tolakValidasi = false;
    status.modalUpload = false;
    status.modalDetail = false;
    status.fotoPersyaratan = false;

    this.setState({
      status,
      alert: null,
      detailSurat: null,
      dataPelayanan: null,
			dataPelapor: null,
			dataDokumenOutput: null,
      linkGambar: listFile,
    });
  }

  hideAlert = () => {
    let status = { ...this.state.status };
    status.alert = false;
    this.setState({ status });
  };

  updateSurat = (uuid_surat) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Ya, saya yakin"
          confirmBtnBsStyle="warning"
          confirmBtnStyle={{ fontWeight: 600 }}
          cancelBtnText="Batal"
          cancelBtnBsStyle="default"
          cancelBtnStyle={{ fontWeight: 600 }}
          type="warning"
          title="Apakah anda yakin syarat yang diberikan telah sesuai ?"
          onCancel={() => {
            this.setState({ alert: null });
          }}
          onConfirm={() => {
            if (this.state.detailSurat) {
              let bodyFormData = {
                status: "Diproses",
              };
              Post(
                "pelayanan/verifikasi-status",
                this.state.detailSurat.uuid,
                bodyFormData,
                (data) => {
                  console.log(data);
                  if (data.status == 200) {
                    let status = { ...this.state.status };
                    status.alert = true;
                    this.setState({
                      status,
                      basicType: "success",
                      basicTitle: "Validasi Pengajuan Pelayanan",
                      pesanAlert: "Berhasil validasi data",
                    });
                    this.componentDidMount(this.state.active_tab_icon);
                  } else {
                    let status = { ...this.state.status };
                    status.alert = true;
                    this.setState({
                      status,
                      basicType: "danger",
                      basicTitle: "Validasi Pengajuan Pelayanan",
                      pesanAlert: "Gagal validasi data",
                    });
                  }
                  this.tutupForm();
                }
              );
            }
          }}
        >
          Proses ini tidak dapat dibatalkan, pastikan sudah memeriksa seluruh
          persyaratan dengan teliti
        </SweetAlert>
      ),
    });
  };

  tolakSurat = (uuid_surat) => {
    let alasan = document.getElementById("alasan").value;
    if (alasan == "") {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Penolakan Pengajuan Layanan",
        pesanAlert: "Isikan alasan penolakan terlebih dahulu",
      });
    } else {
      this.setState({
        alert: (
          <SweetAlert
            showCancel
            confirmBtnText="Ya, saya yakin"
            confirmBtnBsStyle="warning"
            confirmBtnStyle={{ fontWeight: 600 }}
            cancelBtnText="Batal"
            cancelBtnBsStyle="default"
            cancelBtnStyle={{ fontWeight: 600 }}
            type="warning"
            title="Apakah anda yakin ingin menolak pengajuan layanan ini ?"
            onCancel={() => {
              this.setState({ alert: null });
            }}
            onConfirm={() => {
              if (this.state.detailSurat) {
                let bodyFormData = {
                  status: "Ditolak",
                  keterangan: alasan,
                };
                Post(
                  "pelayanan/verifikasi-status",
                  uuid_surat,
                  bodyFormData,
                  (data) => {
                    console.log(data);
                    if (data.status == 200) {
                      let status = { ...this.state.status };
                      status.alert = true;
                      this.setState({
                        status,
                        basicType: "success",
                        basicTitle: "Validasi Pengajuan Pelayanan",
                        pesanAlert:
                          "Berhasil melakukan penolakan pengajuan layanan",
                      });
                      this.componentDidMount(this.state.active_tab_icon);
                    } else {
                      let status = { ...this.state.status };
                      status.alert = true;
                      this.setState({
                        status,
                        basicType: "danger",
                        basicTitle: "Validasi Pengajuan Pelayanan",
                        pesanAlert:
                          "Gagal melakukan penolakan pengajuan layanan",
                      });
                    }
                    this.tutupForm();
                  }
                );
              }
            }}
          >
            Proses ini tidak dapat dibatalkan, pastikan anda sudah teliti dalam
            melakukan proses penolakan pengajuan
          </SweetAlert>
        ),
      });
      // this.tutupForm()
    }
  };

  onFileChange = (event, attribute) => {
    const file = event.target.files[0];
    const fileType = file.type.split('/')
    const type = fileType[1]
    
    if (type == "pdf") {
      let bodyFormData = new FormData();
      bodyFormData.append("files", file);
  
      Post("upload-file/pdf", null, bodyFormData, (response) => {
        const link = response.data.results
        let linkGambar = {...this.state.linkGambar};

        linkGambar[attribute] = link
  
        this.setState({ linkGambar });
      });
    } else {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Upload file",
        pesanAlert: "Format file harus berupa PDF",
      });
      document.getElementById("file-upload").value = null
    }

  };

  uploadFiles = (e) => {
    e.preventDefault()

    let isUploadEnable = true
    // Cek upload button
    Object.keys(this.state.linkGambar).map((attribute) => {
      isUploadEnable = isUploadEnable && this.state.linkGambar[attribute] != ''
    })

    if (isUploadEnable) {
      let bodyFormData = {
        status: "Selesai",
        dokumen_output: this.state.linkGambar,
      };

      Post(
        "pelayanan/verifikasi-status",
        this.state.detailSurat.uuid,
        bodyFormData,
        (data) => {
          console.log(data);
          if (data.status == 200) {
            let status = { ...this.state.status };
            status.alert = true;
            this.setState({
              status,
              basicType: "success",
              basicTitle: "Upload Berkas",
              pesanAlert: "Berhasil upload berkas",
            });
            this.componentDidMount(this.state.active_tab_icon);
          } else {
            let status = { ...this.state.status };
            status.alert = true;
            this.setState({
              status,
              basicType: "danger",
              basicTitle: "Upload Berkas",
              pesanAlert: "Gagal upload berkas",
            });
          }
          this.tutupForm();
        }
      );
    } else {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Upload berkas",
        pesanAlert: "Pilih berkas terlebih dahulu",
      });
    }
  };

	toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {

      this.setState({ active_tab_icon: tab });

			let endPoint = '';

			switch (tab) {
				case "1":
					endPoint = "pelayanan?jenis_layanan=Penerbitan Akta Kematian&status=Diajukan"
					break;
			
				case "2":
					endPoint = "pelayanan?jenis_layanan=Penerbitan Akta Kematian&status=Diproses"
					break;
			
				case "3":
					endPoint = "pelayanan?jenis_layanan=Penerbitan Akta Kematian&status=Selesai"
					break;
			
				case "4":
					endPoint = "pelayanan?jenis_layanan=Penerbitan Akta Kematian&status=Ditolak"
					break;
			
				default:
					break;
			}

			Get(endPoint, null, (value) => {
				const data = value.results.data;
				
				switch (tab) {
					case '1':
						this.setState({ data })
						break;
				
					case '2':
						this.setState({ dataDiproses: data})
						break;
				
					case '3':
						this.setState({ dataSelesai: data})
						break;
				
					case '4':
						this.setState({ dataDitolak: data})
						break;
				
					default:
						break;
				}

			});
    }
  }

  render() {
    var data = this.state.data;

    switch (this.state.active_tab_icon) {
      case "1":
        data = this.state.data
        break;

      case "2":
        data = this.state.dataDiproses
        break;

      case "3":
        data = this.state.dataSelesai
        break;

      case "4":
        data = this.state.dataDitolak
        break;
    }

    const columns = [
      {
        dataField: "tgl_melapor",
        text: "Tanggal Melapor",
        sort: false,
      },
      {
        dataField: "jenis_layanan",
        text: "Layanan",
        sort: true,
      },
      {
        dataField: "nik_pelapor",
        text: "NIK",
        sort: true,
      },
      {
        dataField: "nama_pelapor",
        text: "Nama",
        sort: true,
      },
      {
        dataField: "data_status_baru.status",
        text: "status",
        sort: true,
        // formatter: (value)=> {
        //   return value[0]
        // }
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi,
      },
    ];

		let bootstrapTable = (
			<div>
				<BootstrapTable keyField="id" data={data} columns={columns} />
				{data.length != 0 ? (
          <div className="pull-right text-white">
            {this.state.awal}
            {this.state.sebelum}
            {this.state.hal.map(dt => {
              return dt;
            })}
            {this.state.setelah}
            {this.state.akhir}
          </div>
        ) : ''}
			</div>
		)
    
    let uploadButton = true
    // Cek upload button
    Object.keys(this.state.linkGambar).map((attribute) => {
      uploadButton = uploadButton && this.state.linkGambar[attribute] != ''
    })

    return (
      <div>
        <div className="container-fluid">
          <SweetAlert
            show={this.state.status.alert}
            type={this.state.basicType}
            title={this.state.basicTitle}
            confirmBtnBsStyle="info"
            onConfirm={this.hideAlert}
          >
            {this.state.pesanAlert}
          </SweetAlert>

          {this.state.alert}

          <div className="row">
            <div className="col-lg-12 p-0 m-0">
              <div className="card col-lg-12 p-10">
								<div className="card-header" style={{ padding: "20px" }}>
									<div className="row">
										<div className="col-sm-6 text-left">
											<h6
												style={{ color: "black", fontSize: "17px" }}
												className="mb-0"
											>
												Data Pengajuan Penerbitan Akta Kematian
											</h6>
										</div>
									</div>
								</div>
								<div
									className="card-body datatable-react"
									style={{ paddingTop: "15px" }}
								>
									<div className="row">
										<div className="col-sm-12 p-0">
											<Nav tabs className="tabs-color">
												<NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "1"
														})}
														onClick={() => {
															this.toggle_icon("1");
														}}
													>
														Diajukan
													</NavLink>
												</NavItem>
												<NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "2"
														})}
														onClick={() => {
															this.toggle_icon("2");
														}}
													>
														Diproses
													</NavLink>
												</NavItem>
												<NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "3"
														})}
														onClick={() => {
															this.toggle_icon("3");
														}}
													>
														Selesai
													</NavLink>
												</NavItem>
												<NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "4"
														})}
														onClick={() => {
															this.toggle_icon("4");
														}}
													>
														Ditolak
													</NavLink>
												</NavItem>
											</Nav>
											<TabContent activeTab={this.state.active_tab_icon}>
												<TabPane tabId="1">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "1" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="2">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "2" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="3">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "3" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="4">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "4" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
											</TabContent>
										</div>
									</div>
									<div className="row" style={{ marginTop: "10px" }}>
										<div className="col-sm-12">
											<Link to="/admin/dashboard">Kembali ke dashboard</Link>
										</div>
									</div>
								</div>
              </div>
            </div>
          </div>
          {/* Modal validasi */}
          <Modal
            open={this.state.status.modalValidasi}
            styles={{
              modal: {
                width: "85%",
              },
            }}
            onClose={() => {
              // Tutup modal validasi
              let status = { ...this.state.status };
              status.modalValidasi = false;
              this.setState({ status });
            }}
          >
            <Form className="theme-form">
              <div className="modal-header">
                <h5 className="modal-title">
                  Form Validasi Penerbitan Akta Kematian
                </h5>
              </div>
              <div className="modal-body">
                {this.state.detailSurat ? (
                  <div>
                    {/* Detail Surat */}
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Nama
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.nama_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        NIK
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.nik_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Jenis Layanan
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.jenis_layanan}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Tanggal Pengajuan
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.tgl_melapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Nomor HP
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.no_hp_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Pekerjaan
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.pekerjaan_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Alamat
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6 style={{ marginBottom: "0px", fontWeight: 400 }}>
                          {this.state.detailSurat.alamat_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  // disabled={this.state.status.btnForm}
                  onClick={() => {
                    let status = { ...this.state.status };
                    status.tolakValidasi = true;

                    this.setState({ status });
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Tolak
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  // disabled={this.state.status.btnForm}
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.state.detailSurat) {
                      this.updateSurat(this.state.detailSurat.uuid);
                    }
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Terima
                </button>
              </div>
            </Form>
          </Modal>
          {/* Modal Detail Gambar */}
          <Modal
            open={this.state.status.fotoPersyaratan}
            styles={{
              modal: {
                width: "90%",
              },
            }}
            onClose={() => {
              let status = { ...this.state.status };
              status.fotoPersyaratan = false;
              this.setState({ status });
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.state.titlePersyaratan}</h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  {this.state.fotoPersyaratan != '' ? (
                    <img
                    src={this.state.fotoPersyaratan}
                    style={{ width: "100%" }}
                  />
                  ) : (
                    <h6 style={{ textAlign: "center" }}>Tidak ada Foto</h6>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                // disabled={this.state.status.btnForm}
                onClick={() => {
                  let status = { ...this.state.status };
                  status.fotoPersyaratan = false;
                  this.setState({ status });
                }}
                style={{ fontWeight: 600 }}
              >
                Tutup
              </button>
            </div>
          </Modal>
          {/* Modal form penolakan pengajuan */}
          <Modal
            open={this.state.status.tolakValidasi}
            styles={{
              modal: {
                width: "80%",
              },
            }}
            onClose={() => {
              let status = { ...this.state.status };
              status.tolakValidasi = false;
              this.setState({ status });
            }}
          >
            <Form className="theme-form">
              <div className="modal-header">
                <h5 className="modal-title">Penolakan Pengajuan Layanan</h5>
              </div>
              <div className="modal-body">
                <FormGroup className="row" style={{ marginBottom: "0px" }}>
                  <Label
                    className="col-sm-3 col-form-label"
                    style={{ fontWeight: 600 }}
                  >
                    Alasan Penolakan
                  </Label>
                  <div className="col-sm-9" style={{ textAlign: "center" }}>
                    <Input type="textarea" id="alasan" nama="alasan" />
                  </div>
                </FormGroup>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-info"
                  // disabled={this.state.status.btnForm}
                  onClick={() => {
                    let status = { ...this.state.status };
                    status.tolakValidasi = false;
                    this.setState({ status });
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Batal
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  // disabled={this.state.status.btnForm}
                  onClick={(e) => {
                    e.preventDefault();
                    this.tolakSurat(this.state.detailSurat.uuid);
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Simpan
                </button>
              </div>
            </Form>
          </Modal>
          {/* Modal upload berkas */}
          <Modal
            open={this.state.status.modalUpload}
            styles={{
              modal: {
                width: "85%",
              },
            }}
            onClose={() => {
              let status = { ...this.state.status };
              status.modalUpload = false;
              this.setState({ 
                status, 
                linkGambar: listFile
              });
            }}
          >
            <Form className="theme-form" onSubmit={this.uploadFiles}>
              <div className="modal-header">
                <h5 className="modal-title">Form Upload Berkas</h5>
              </div>
              <div className="modal-body">
                {Object.keys(this.state.linkGambar).map((attribute) => {
                  let titleFile = 'dokumen'

                  switch (attribute) {
                    case 'akta_kematian':
                      titleFile = "Akta Kematian"
                      break;
                    
                    case 'kartu_keluarga':
                      titleFile = "Kartu Keluarga"
                      break;
                  }

                  return (
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        File {titleFile}
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <input id="file-upload" type="file" onChange={(event) => {
                          this.onFileChange(event, attribute)
                        }} />
                        {this.state.linkGambar[attribute] != "" ? (
                          <a href={this.state.linkGambar[attribute]}>Lihat file</a>
                        ) : (
                          ""
                        )}
                      </div>
                    </FormGroup>
                  )
                })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-info"
                  // disabled={this.state.status.btnForm}
                  onClick={() => {
                    this.tutupForm();
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Batal
                </button>
                <button
                  type="submit"
                  disabled={uploadButton ? false : true}
                  className="btn btn-success"
                  style={{ fontWeight: 600 }}
                >
                  Simpan
                </button>
              </div>
            </Form>
          </Modal>
          {/* Modal detail berkas */}
          <Modal
            open={this.state.status.modalDetail}
            styles={{
              modal: {
                width: "75%",
              },
            }}
            onClose={() => {
              let status = { ...this.state.status };
              status.modalDetail = false;
              this.setState({ status });
            }}
          >
            <Form className="theme-form">
              <div className="modal-header">
                <h5 className="modal-title">
                  Form Pengajuan Penerbitan Akta Kematian
                </h5>
              </div>
              <div className="modal-body">
                {this.state.dataPelayanan ? (
                  <div>
                    {this.state.dataDokumenOutput ?
                      Object.keys(this.state.dataDokumenOutput).map((val, index) => {
                        let dokumenOutput = this.state.dataDokumenOutput

                        let titleFile = 'dokumen'

                        switch (val) {
                          case 'akta_kematian':
                            titleFile = "Akta Kematian"
                            break;
                          
                          case 'kartu_keluarga':
                            titleFile = "Kartu Keluarga"
                            break;
                        }

                        return (
                          <FormGroup className="row" style={{ marginBottom: "0px" }}>
                            <Label
                              className="col-sm-4 col-form-label pb-1"
                              style={{ fontWeight: 600 }}
                            >
                              Berkas {titleFile}
                            </Label>
                            <div
                              className="col-sm-8"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <a href={dokumenOutput[val]}>Lihat file</a>
                            </div>
                          </FormGroup>
                        )
                      })
                    : ''}
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Kepala Keluarga</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        Nama Kepala Keluarga
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.nama_kepala_keluarga}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Nomor Kepala Keluarga
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.no_kk}
                        </h6>
                      </div>
                    </FormGroup>
                    </div>
                    {/* Jenazah */}
                    {/* <hr/> */}
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Jenazah</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        NIK
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.nik_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Nama Lengkap
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.nama_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Jenis Kelamin
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.jk_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Tanggal Lahir
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.tgl_lahir_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Umur
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.umur_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Tempat Lahir
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.tmpt_lahir_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Agama
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.agama}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Pekerjaan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.pekerjaan_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Alamat
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.alamat_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Desa / Kelurahan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.deskel_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kecamatan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.kecamatan_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kab/Kota
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.kabkota_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Provinsi
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.provinsi_jenazah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Anak ke
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.anak_ke}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Tanggal Kematian
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.tgl_kematian}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Pukul
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.pukul_kematian}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Sebab Kematian
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.sebab_kematian}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Tempat Kematian
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.tempat_kematian}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Yang menerangkan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.yang_menerangkan}
                        </h6>
                      </div>
                    </FormGroup>
                    </div>
                    {/* <hr/> */}
                    {/* Data Ayah */}
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Ayah</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        NIK
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.nik_ayah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Nama Lengkap
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.nama_ayah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Tanggal Lahir
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.tgl_lahir_ayah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Umur
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.umur_ayah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Pekerjaan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.pekerjaan_ayah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Alamat
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.alamat_ayah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Desa / Kelurahan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.deskel_ayah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kecamatan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.kecamatan_ayah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kab/Kota
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.kabkota_ayah}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Provinsi
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.provinsi_ayah}
                        </h6>
                      </div>
                    </FormGroup>
                    </div>
                    {/* <hr/> */}
                    {/* Data Ibu */}
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Ibu</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        NIK
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.nik_ibu}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Nama Lengkap
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.nama_ibu}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Tanggal Lahir
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.tgl_lahir_ibu}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Umur
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.umur_ibu}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Pekerjaan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.pekerjaan_ibu}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Alamat
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.alamat_ibu}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Desa / Kelurahan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.deskel_ibu}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kecamatan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.kecamatan_ibu}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kab/Kota
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.kabkota_ibu}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Provinsi
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.provinsi_ibu}
                        </h6>
                      </div>
                    </FormGroup>
                    </div>
                    {/* <hr/> */}
                    {/* Pelapor */}
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Pelapor</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        NIK
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat.nik_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Nama Lengkap
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat.nama_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Tanggal Lahir
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelapor.tgl_lahir_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Umur
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelapor.umur_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Pekerjaan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat.pekerjaan_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Alamat
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.detailSurat.alamat_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Desa / Kelurahan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelapor.deskel_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kecamatan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelapor.kecamatan_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kab/Kota
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelapor.kabkota_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Provinsi
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelapor.provinsi_pelapor}
                        </h6>
                      </div>
                    </FormGroup>
                    </div>
                    {/* <hr/> */}
                    {/* Saksi I */}
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Saksi I</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        NIK
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.nik_saksi_1}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Nama Lengkap
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.nama_saksi_1}
                        </h6>
                      </div>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Tanggal Lahir
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.tgl_lahir_saksi_1}
                        </h6>
                      </div>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Umur
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.umur_saksi_1}
                        </h6>
                      </div>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Pekerjaan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.pekerjaan_saksi_1}
                        </h6>
                      </div>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Alamat
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.alamat_saksi_1}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Desa / Kelurahan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.deskel_saksi_1}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kecamatan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.kecamatan_saksi_1}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kab/Kota
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.kabkota_saksi_1}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Provinsi
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.provinsi_saksi_1}
                        </h6>
                      </div>
                    </FormGroup>
                    </div>
                    {/* <hr/> */}
                    {/* Saksi II */}
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-3"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Saksi II</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <div style={{ padding: "10px", border: "1px solid black", borderRadius: "15px"}}>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        NIK
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.nik_saksi_2}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Nama Lengkap
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.nama_saksi_2}
                        </h6>
                      </div>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Tanggal Lahir
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.tgl_lahir_saksi_2}
                        </h6>
                      </div>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Umur
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.umur_saksi_2}
                        </h6>
                      </div>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Pekerjaan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.pekerjaan_saksi_2}
                        </h6>
                      </div>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Alamat
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.alamat_saksi_2}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Desa / Kelurahan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.deskel_saksi_2}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kecamatan
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.kecamatan_saksi_2}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Kab/Kota
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.kabkota_saksi_2}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: "0px", paddingLeft: "1.5rem" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1"
                        style={{ fontWeight: 600 }}
                      >
                        Provinsi
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontWeight: 400,
                            fontSize: "inherit",
                          }}
                        >
                          {this.state.dataPelayanan.provinsi_saksi_2}
                        </h6>
                      </div>
                    </FormGroup>
                    </div>
                    <hr />
                    <FormGroup className="row" style={{ marginBottom: "0px" }}>
                      <Label
                        className="col-sm-3 col-form-label pt-0"
                        style={{ fontWeight: 600 }}
                      >
                        <h5><b>Persyaratan</b></h5>
                      </Label>
                      <div
                        className="col-sm-9"
                        style={{ display: "flex", alignItems: "center" }}
                      />
                    </FormGroup>
                    <div className="row">
                      {/* Looping foto persyaratan */}
                      {Object.keys(
                        JSON.parse(this.state.detailSurat.dokumen_input)
                      ).map((val, index) => {
                        let dokumen_input = JSON.parse(
                          this.state.detailSurat.dokumen_input
                        );
                        let nama_syarat = "Foto Persyaratan";

                        switch (val) {
                          case "surat_kematian_desa":
                            nama_syarat = "Surat Kematian Desa";
                            break;

                          case "kk_bersangkutan":
                            nama_syarat = "KK yang bersangkutan";
                            break;

                          default:
                            break;
                        }

                        return (
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            {dokumen_input[val] != "" ? (
                              <img
                              src={dokumen_input[val]}
                              style={{ width: "200px", height: "200px", objectFit: "cover", cursor: "pointer" }}
                              onClick={() => {
                                let status = { ...this.state.status };
                                status.fotoPersyaratan = true;
                                this.setState({
                                  status,
                                  fotoPersyaratan: dokumen_input[val],
                                  titlePersyaratan: nama_syarat,
                                });
                              }}
                            />
                            ) : (
                              <div style={{ height: "200px", width: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <p style={{ textAlign: "center", fontWeight: 600 }}>Tidak ada foto persyaratan</p>
                              </div>
                            )}
                            <p style={{ textAlign: "center" }}>{nama_syarat}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-info"
                  // disabled={this.state.status.btnForm}
                  onClick={() => {
                    this.tutupForm();
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Tutup
                </button>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    );
  }
}

export default AktaKematian;
