// import { JENIS_DESA, JENIS_DUSUN } from '../function/Koneksi';

export const MENUITEMS = [
	{
		title: 'Dashboard',
		icon: 'desktop',
		type: 'link',
		path: '/admin/dashboard',
		badgeType: 'primary',
		badgeValue: 'new',
		sidebartitle: 'General',
		active: true,
		// children: [
		// 	{ path: '/dashboard/default', title: 'Default', type: 'link' },
		// 	{ path: '/dashboard/ecommerce', title: 'E-Commerce', type: 'link' }
		// ]
	},
	{
		path: '/admin/pengguna',
		title: 'Pengguna',
		icon: 'settings',
		type: 'link'
	},
];
