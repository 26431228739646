import React, { Component } from "react";

// Import authService
import { Link, Redirect } from "react-router-dom";
import { DataToken } from "../../../function/Format";
import { JENIS_DESA } from "../../../function/Koneksi";
import MenuAdmin from "./menu.admin";
import MenuSuper from "./menu.superadmin";
import Notificationbaru from "./notification.baru";
// import { borderRadius } from "react-select/src/theme";


const jwt = require('jsonwebtoken');
class HeaderBaru extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      layanan: [],
      perizinan: [],
      navMenus: false,
      sidebar: false,
      navigate: false,
      akun: DataToken(sessionStorage.getItem("access_token"))
    };
  }
  logOut(e) {
    e.preventDefault();
    sessionStorage.removeItem("access_token");
    // localStorage.clear();
    this.setState({ navigate: true });
  }

  openCloseSidebar = () => {
    if (this.state.sidebar) {
      this.setState({ sidebar: false });
      document
        .querySelector(".page-body-wrapper")
        .classList.add("sidebar-close");
    } else {
      this.setState({ sidebar: true });
      document
        .querySelector(".page-body-wrapper")
        .classList.remove("sidebar-close");
    }
  };
  componentDidMount() {
    var contentwidth = window.innerWidth;
    if (contentwidth > 1) {
      document
        .querySelector(".page-body-wrapper")
        .classList.add("sidebar-close");
    }
  }

  render() {

    const { navigate, akun } = this.state;
    // console.log(akun);
    if (navigate) {
      return <Redirect to="/" push={true} />;
    }

    let logoAplikasi = (
      <img
        className="align-self-center"
        src={ require("../../../assets/images/digdes.png")}
        alt=""
      />
    )

    return (
      <div className="bayu-main-header">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="bayu-header-right" style={{ width: "fit-content" }}>
            <div className="mobile-sidebar">
              <div className="media-body text-right switch-sm">
              {this.state.akun.sub.role && this.state.akun.sub.role == "Admin" 
              ? 
              (
                <i onClick={this.openCloseSidebar} className="fa fa-bars" />
              ) 
              : <></>
                // <MenuSuper />
              }
                
              </div>
            </div>
          </div>

          <div className="bayu-header-left">
            <div className="logo-wrapper">
              {/* <a href="/"> */}
              <Link to={process.env.PUBLIC_URL}>
                {logoAplikasi}
              </Link>
            </div>
          </div>
          <div className="bayu-header-right" style={{ width: "fit-content" }}>
            <div className="nav-right">
              <ul>
                {/* <Notificationbaru /> */}

                <li>
                  <a href="#" onClick={this.logOut.bind(this)}>
                    <i className="icofont icofont-logout pow"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* {this.state.akun.sub.jenis && this.state.akun.sub.jenis == "Capil" || this.state.akun.sub.jenis == "KUA" || this.state.akun.sub.jenis == "Kecamatan" 
        ? 
        (
          <MenuAdmin />
        ) 
        : (
          <MenuSuper />
        )} */}
        {this.state.akun.sub.jenis && this.state.akun.sub.jenis == "Capil" || this.state.akun.sub.jenis == "KUA" || this.state.akun.sub.jenis == "Kecamatan" 
        ? 
        (
          <MenuAdmin />
        ) 
        : <></>
          // <MenuSuper />
        }
      </div>
    );
  }
}

export default HeaderBaru;
