import React, { Component } from "react";

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import Datepicker from "react-datepicker";
import Lightbox from "react-image-lightbox";

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { PostStatus, Post, Get, Delete, cekLogin } from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import DatePicker from "reactdatepicker";

//json file
// var data = require('../assets/json/company');
const frmDef = {
	uuid: null,
	id_instansi: '',
	nomor_wa_perangkat: [{isi:[""]}],
	status: '',
};

const jwt = require('jsonwebtoken');

class KonfigurasiWA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
			dt: frmDef,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      frmInstansi: [],
      dataInstansi: [],
      get_all_nomor: null,
      dataUser: jwt.decode(sessionStorage.getItem('access_token')),
    };
  }


  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  inputChange = (event) => {
		console.log(event.target.value);
		this.setState({ 
			dt: {...this.state.dt, 
				nomor_wa_perangkat: {...this.state.dt.nomor_wa_perangkat,
				}
			}
		})
		console.log(this.state.dt);
	}

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    let where = [];
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }

    // let id_wil = dataUser.sub.id_wilayah
    Get("konfigurasi-instansi/instansi/"+ this.state.dataUser.sub.jenis +'/' + this.state.dataUser.sub.id_wilayah + link, null, dtkat => {
      console.log("Data Konfigurasi Instansi")
      console.log(dtkat)
      let nomor_wa_perangkat = JSON.parse(dtkat.results.nomor_wa_perangkat)
      console.log("Data cookk")
      console.log(nomor_wa_perangkat)
      this.setState({ data: dtkat.results, get_all_nomor: nomor_wa_perangkat, pagination: dtkat.results });
      this.pagination(dtkat.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i == data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            onClick={() => this.fetch({ page: p })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            onClick={() => this.fetch({ page: n })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key == "Enter") {
      let addRole = {
        q: e.target.value
      };
      cekLogin("jenis-surat", addRole, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();

      if (this.state.dataInstansi) {
          let get_nomor_wa = JSON.stringify(this.state.dt.nomor_wa_perangkat);
          let pch = get_nomor_wa.split(':');
          let pch2 = pch[1].split('}');
          let nomor_wa = pch2[0];
          let set = JSON.parse(nomor_wa)

          let add = {
            uuid: document.getElementById("uuid").value,
            id_instansi: this.state.dataInstansi.uuid,
            nomor_wa_perangkat: JSON.stringify(set),
          };
          
          
          let psn = "";
          let resstat = 204;
          let metode = "create";

          if (add.uuid == "") {
            psn = "Tambah";
            resstat = 201;
            add.uuid = null;
            add.status='0';
          } else {
            psn = "Ubah";
            resstat = 200;
            metode = "update";
            add.status = this.state.dt.status.value
          }

          console.log("Data SImpannya")
          console.log(add)
          
          PostStatus("konfigurasi-instansi/" + metode, add.uuid, add, res => {
            console.log(res);
            if (res === resstat) {
              this.setState({
                show: true,
                basicType: "success",
                basicTitle: "Data Konfigurasi Instansi",
                pesanAlert: "Berhasil " + psn + " Data"
              });
              this.state.status.form = false;
              this.state.status.btnForm = false;
            } else {
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Data Konfigurasi Instansi",
                pesanAlert: "Gagal " + psn + " Data"
              });
            }
            this.componentDidMount();
            this.forceUpdate();
          });
      }
      else{
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Perangkat",
          pesanAlert: "Data Perangkat Tidak Boleh Kosong"
        });
      }
  };
  // ========================================================================

  
  // ============================== Ubah Data ===============================

  ubahData = (status, data) => {
    console.log("DATA UBAH ADLAH")
    console.log(data)
		this.state.status.form = true;
    if(status=="update"){
      let nomor_wa_perangkat = [
          { 
            isi:[""]
          }
      ]

      if (data.nomor_wa_perangkat != '') {
        nomor_wa_perangkat = JSON.parse(data.nomor_wa_perangkat)
        console.log("BERAPA")
        console.log(nomor_wa_perangkat)
      }

      this.state.dt.uuid = data.uuid;
      this.state.dt.status = {value: data.status, label: data.status==0?'Aktif':'Tidak Aktif'};
      this.state.dt.nomor_wa_perangkat = [
        { 
          isi:nomor_wa_perangkat
        }
    ];

      this.forceUpdate();

      // this.setState({
      //   data: data
      // })
    }
    else{
      this.setState({
        data:{

        }
      })
    }
		this.state.judul = 'Ubah Konfigurasi Whatsapp';
		// this.setState({ view: 'none', input: 'inherit' });
		this.forceUpdate();
	};

  // ubahData = data => {
  //   console.log("IDNYA")
  //   console.log(data)

  //   this.setState({
  //     dt:{
  //       uuid: data.uuid,
  //       nama: data.nama,
  //       nip: data.nip,
  //       jabatan: data.jabatan,
  //       pangkat: data.pangkat,
  //       status: {
  //         value: data.status, 
  //         label: data.status == '0'? "Aktif" : "Tidak Aktif"
  //       }
  //     }
  //   })
      
  //     // this.state.status.btnAksi = false;
  //     this.forceUpdate();
  //     this.bukaForm();
  // };
  
 
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("konfigurasi-instansi/delete", id, res => {
      if (res == 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Konfigurasi Instansi",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Konfigurasi Instansi",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.dt.uuid = null
    
    this.state.status.form = false;
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    // console.log(row)
    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Ubah Konfigurasi Instansi",
              statUbah: false
            });
            this.ubahData(row);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        <Button 
          size="xs" 
          color="danger" 
          className="btn-icon" 
          onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>
      </>
    );
  };

  
	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
        case 'status':
					this.state.dt.status = e;
					break;
			}
		} else {
			switch (sel) {
        case 'status':
          this.state.dt.status = null;
          break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

  componentDidMount() {
    this.fetch({ page: null, where: [] });

    Get("instansi/wilayah/"+ this.state.dataUser.sub.jenis+'/'+this.state.dataUser.sub.id_wilayah, null, data => {
      if(data.results){
        console.log("Instansi Data didmount")
        console.log(data)
        this.setState({ 
          dataInstansi: data.results
        });
      }
    });

  }

  render() {
    // console.log("Data Instansi")
    // console.log(this.state.dataInstansi.uuid)
    console.log("Data WA")
    // console.log(this.state.get_all_nomor)
    console.log(this.state.dt.nomor_wa_perangkat)
    // console.log(this.state.dataUser)
    // console.log(this.state.dataUser.sub.id_wilayah)
    // console.log(jwt)
    // console.log(dataUser)
    // console.log(dataUser.sub.id_wilayah)
    // console.log(this.state.id_jenisUser)
    // console.log(this.state.prvStat)
    // console.log(this.state.kbktStat)
    // console.log(this.state.kecStat)
    const { photoIndex, isOpen } = this.state;

    var size = Math.ceil(
      this.state.pagination.total / this.state.pagination.per_page
    );
    // var size = this.state.pagination.total;
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    var data = this.state.data;

    // var data = require('../assets/json/company');

    const frmDef = {
      uuid: null,
      id_instansi: '',
      nomor_wa_perangkat: [{isi:[""]}],
      status: '',
    };
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "nomor_wa_perangkat",
        text: "Nomor HP",
        sort: true
      },

      {
        dataField: "data_instansi.nama",
        text: "Nama Instansi",
        sort: true
      },
      {
        dataField: "data_instansi.tingkat",
        text: "Tingkat Instansi",
        sort: true
      },
      {
        dataField: "data_instansi",
        text: "Wilayah",
        sort: true,
        formatter: (cell, obj) => {
          let isinya = obj.data_instansi
          console.log("Data aoa ")
          console.log(isinya)
          if(isinya.data_kecamatan){
            return (isinya.data_kecamatan.nama);
          }
          else{
            return (isinya.data_kabkota.nama);
          }
        },
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        formatter: (cell, obj) => {
          if(obj.status=='0'){
            return "Aktif";
          }
          else{
            return "Tidak Aktif";
          }
        },
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    return (
      <div>
        {/* <Breadcrumb title="Penguna" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div
										className="row"
										style={{ border: '3px outset black', borderRadius: '25px', padding: '20px' }}
									>
                    <div className="col-md-12 text-right">
											{
												this.state.data.uuid
												?
													<Button  size="md" color="success" className="btn-icon" onClick={() => this.ubahData('update',this.state.data)} disabled={this.state.status.btnAksi}><i className="fa fa-edit"></i> Ubah Konfigurasi</Button> 
												:
													<Button  size="md" color="success" className="btn-icon" onClick={() => this.ubahData('create','')} disabled={this.state.status.btnAksi}><i className="fa fa-plus"></i> Tambah Konfigurasi</Button> 
											}
                    </div>
                    <div>
                      <br/>&nbsp;<br/>
                    </div>

                    {
										this.state.data.uuid
										?
											<>
												<div className="col-md-12" align="center">
													{/* <img src={this.state.data.foto_desa} style={{ width: '350px' }} /> */}
													<br/><br/>
												</div>

												<div className="col-6">

                          <div className="col-md-12">
														<b>Nama Instansi</b>
													</div>
													<div className="col-md-12">
                            {this.state.data.data_instansi.nama}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
                            &nbsp;
                          </div>

                          <div className="col-md-12">
														<b>Notifikasi Whatsapp</b>
													</div>
													<div className="col-md-12">
                            {this.state.data.status == 1 ? "Tidak Aktif" : "Aktif"}
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
                            &nbsp;
                          </div>


												</div>
                      {/* ====================== */}
												<div className="col-6">
                        <div className="col-md-12">
														<b>Wilayah Instansi</b>
													</div>
													<div className="col-md-12">
                            {
                              this.state.data.data_instansi.data_kecamatan!=null
                              ?
                                <>
                                  {this.state.data.data_instansi.data_kecamatan.nama}
                                </>
                              : this.state.data.data_instansi.data_kabkota!=null
                              ?
                                <>
                                  {this.state.data.data_instansi.data_kabkota.nama}
                                </>
                              :
                                <>
                                </>
                            }
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
													</div>

													<div className="col-md-12">
														<b>Nomor Whatsapp</b>
													</div>
													<div className="col-md-12">
                            {
                              this.state.get_all_nomor.map((dt, i) => {
                                return (
                                  <tr>
                                    <td style={{ padding: '5px 5px 5px 5px' }}>{i + 1}.</td>
                                    <td style={{ padding: '5px 5px 5px 5px' }}>{dt}</td>
                                  </tr>
                                );
                              })
                            }
													</div>

													<div className="col-md-12">
														<b>&nbsp;</b>
													</div>
													<div className="col-md-12">
														&nbsp;
														</div>
												</div>
											</>
										:
										(
											<div className="col-md-12 text-center">
												<h4 align="center">Konfigurasi Whatsapp Belum Ada</h4>
											</div>
										)
										}




								  </div>
               </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <Modal
          size="lg"
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="UUID"
                defaultValue={this.state.dt.uuid}
              />
              
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Instansi
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="Masukkan Nama Instansi"
                    required
                    defaultValue={this.state.dataInstansi.nama}
                    readOnly
                    style={{ backgroundColor:"#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Wilayah
                </Label>
                <Col sm="9">
                  {
                    this.state.dataInstansi.data_kecamatan != null
                    ?
                    <>
                      <input
                        className="form-control"
                        id="nama"
                        type="text"
                        placeholder="Masukkan Wilayah Instansi"
                        required
                        defaultValue={this.state.dataInstansi.data_kecamatan.nama}
                        readOnly
                        style={{ backgroundColor:"#c9d1d8" }}
                      />
                    </>
                    :
                    this.state.dataInstansi.data_kebkota != null
                    ?
                    <>
                      <input
                        className="form-control"
                        id="nama"
                        type="text"
                        placeholder="Masukkan WIlayah Instansi"
                        required
                        defaultValue={this.state.dataInstansi.data_kabkota.nama}
                        readOnly
                        style={{ backgroundColor:"#c9d1d8" }}
                      />
                    </>
                    :
                    <>
                    </>
                  }
                </Col>
              </FormGroup>

              {this.state.dt.uuid
              ?
              <>
                <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                    Status Notifikasi WA
                  </Label>
                  <Col sm="9">
                    <Select
                      // isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'status');
                      }}
                      value={this.state.dt.status}
                      defaultValue={this.state.dt.status}
                      name="status"
                      options={[
                        {value: '0', label: 'Aktif'},
                        {value: '1', label: 'Tidak AKtif'},
                      ]}
                      placeholder="Pilih Status"
                      isClearable
                    />
                  </Col>
                </FormGroup>
              </>
              :
              <>
              </>
              }




              {this.state.dt.nomor_wa_perangkat.length > 0 ? (
								<>
									<FormGroup className="row" style={{ marginBottom: '5px' }}>
										<Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
											<b>Nomor HP Perangkat</b>
										</Label>
									</FormGroup>
									{this.state.dt.nomor_wa_perangkat.map((dti, i) => {
										return (
											<div style={{ border: '1px solid black', borderRadius: '10px', padding: '10px', marginBottom: '15px' }}>
												{/* Tombol hapus petunjuk */}
												
												{dti.isi.map((dt_isi, j) => {
													return (
														<>
															<FormGroup className="row" style={{ marginBottom: '10px' }}>
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
																	Nomor HP {j+1}
																</Label>
																<Col sm="8">
																	<input
																		className="form-control"
																		type="text"
																		placeholder="Contoh: 62823xxxxx"
																		required
																		defaultValue={dt_isi}
																		onChange={(event2) => {
																			let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
																			isi[j] = event2.target.value

																			let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
																			nomor_wa_perangkat[i] = {...nomor_wa_perangkat[i], isi}
			
																			this.setState({ 
																				dt: {...this.state.dt, 
																					nomor_wa_perangkat
																				}
																			})
																		}}
																	/>
																</Col>
																{j > 0 ? (
																	<Col lg="1">
																		<button
																			className="btn btn-danger"
																			title="Hapus detail petunjuk ini"
																			style={{ padding: '6px 15px' }}
																			onClick={() => {
																				let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
																				isi.splice(j, 1)

																				let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
																				nomor_wa_perangkat[i] = {...nomor_wa_perangkat[i], isi}
				
																				this.setState({ 
																					dt: {...this.state.dt, 
																						nomor_wa_perangkat
																					}
																				})
																			}}
																		>-</button>
																	</Col>
																) : ''}
															</FormGroup>
														</>
													)
												})}
												<div className="row">
													<div className="col-sm-12">
														<button
															type="button"
															className="btn btn-info"
															// disabled={this.state.status.btnForm}
															onClick={() => {
																let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
																isi.push('')
																let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
																nomor_wa_perangkat[i].isi = isi
																this.setState({ 
																	dt: {...this.state.dt,
																		nomor_wa_perangkat
																	}
																})
															}}
														>
															Tambah No HP Perangkat
														</button>
													</div>
												</div>
											</div>
										)
									})}
								</>
							) : ''}


















            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                // disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                // disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      
      </div>
    );
  }
}

export default KonfigurasiWA;
