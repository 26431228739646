import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, Redirect } from 'react-router-dom';
import { Button, CardImg, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import API_URL, { Get, GetMarketplace, Post } from '../../function/Koneksi';
import classnames from "classnames";
import Datetime from 'react-datetime';

import "react-datetime/css/react-datetime.css";
import Select from 'react-select';
import NumberFormat from 'react-number-format';

const listFile = {
  surat_izin_usaha_mikro: '',
}

let frmDef = {
	nik_pelapor: '',
	nama_pelapor: '',
	alamat_pelapor: '',
	no_hp_pelapor: '',

	nik_pemilik: '',
	email_pemilik: '',
	jenis_kelamin_pemilik: null,
	nama_pemilik: '',
	pendidikan_terakhir_pemilik: '',
	telp_pemilik: '',
	alamat_pemilik: '',
	kota_pemilik: '',
	kecamatan_pemilik: '',
	kelurahan_pemilik: '',
	rw_pemilik: '',
	rt_pemilik: '',
	kode_pos_pemilik: '',
	npwp_pemilik: '',
	jenis_npwp_pemilik: null,

	legalitas_usaha:{
		siup: false,
		situ: false,
		tdp: false,
		npwp: false,
		domisili: false,
		halal: false,
		hak_paten: false,
		p_irt: false,
	},
	no_surat: '',
	nama_usaha: '',
	alamat_usaha: '',
	telp_usaha: '',
	kode_pos_usaha: '',
	jenis_usaha: null,
	dusun_usaha: '',
	kota_usaha: '',
	kecamatan_usaha: '',
	kelurahan_usaha: '',
	rw_usaha: '',
	rt_usaha: '',
	tahun_berdiri: '',
	bentuk_perusahaan: null,
	uraian_usaha: '',
	sarana_usaha: '',
	jumlah_karyawan: '',
	permodalan_sendiri: '',
	permodalan_luar: '',
	kategori_omset: null,
	nominal_omset: '',
	kategori_aset: null,
	nominal_aset: '',
	sumber_bahan_baku: null,
	pemasaran: null,
	status_tempat_usaha: null,
	linkGambar: {
		surat_pengantar_lokasi_usaha: {
			link: '',
			type: '',
		},
		fotocopy_ktp: {
			link: '',
			type: '',
		},
		fotocopy_kk: {
			link: '',
			type: '',
		},
		pas_foto: {
			link: '',
			type: '',
		},
		npwp: {
			link: '',
			type: '',
		},
	},
}

const jwt = require('jsonwebtoken');
const dateFormat = require('dateformat');

const dataUser = jwt.decode(sessionStorage.getItem('access_token'));

class IzinUsahaMikro extends Component {
	constructor(props) {
		super(props);
		this.state = {
			idWilayah: dataUser ? dataUser.sub.id_wilayah : null,
			listDesa: [],
			listPerangkat: [],
			selectedDesa: null,
			selectedTTD: null,

			data: [],
			dataDiproses: [],
			dataSelesai: [],
			dataDitolak: [],
			dataDokumenOutput: [],
			dt: frmDef,

			noSuratAuto: false,
			noSuratKecamatan: null,
			detailSurat: null,
			dataPelayanan: null,
			fotoPersyaratan: null,
			titlePersyaratan: '',
			linkGambar: listFile,
			active_tab_icon: "1",
			pagination: [],
			status: {
				alert: false,
				modalValidasi: false,
				modalCreate: false,
				modalPenerbitan: false,
				modalUpload: false,
				modalDetail: false,
				tolakValidasi: false,
				fotoPersyaratan: false,
				btnForm: false,
				btnAksi: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			// dt:frmDef,
			// ============== Select 2 ==============
			frmTingkat: [],
			tingkatStat: [],
			frmDetail: [],
			detailStat: [],
			judul: 'Tambah Jabatan Perangkat',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			tanggal_surat_kecamatan: new Date(),
			dataUser: jwt.decode(sessionStorage.getItem('access_token'))
		};
	}

	componentDidMount(tab = '1') {

		if (this.props.location.state) {
			console.log(this.props.location.state);
	
			this.fetch({ tab })

			this.setState({ idWilayah: this.state.dataUser.sub.id_wilayah })
	
			let jenisWilayah = this.props.location.state.data_instansi.jenis
			let idJenisLayanan = this.props.location.state.uuid;
	
			Get('pelayanan/get-nomor-surat/' + idJenisLayanan, null, (dtkat) => {
				console.log(dtkat);
				if (dtkat.results) {
					this.setState({ 
						noSuratAuto: true,
						noSuratKecamatan: dtkat.results.nomor_surat,
					})
				}
			});
	
			// Get List Desa
			GetMarketplace('kecamatan/list' + jenisWilayah + '/' + this.state.dataUser.sub.id_wilayah, null, (data) => {
				console.log(data);
				let listDesa = [];
	
				if (data.result) {
					data.result.map((val) => {
						listDesa.push({ value: val.id, label: val.nama })
					})
					this.setState({ listDesa })
					console.log(listDesa);
				}
			})
	
			// Get list perangkat
			Get('perangkat/list-instansi/' + jenisWilayah + '/' + this.state.dataUser.sub.id_wilayah, null, (val) => {
				console.log(val);
				if (val.results) {
					this.setState({ listPerangkat: val.results })
				}
			});
		}
		
	}

	fetch = (params = {}) => {
		let link = '';
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '&page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '&page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '&page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '&page=1';
					break;
				default:
					link = '&page=' + params.page;
					break;
			}
		}

		let endPoint = '';
    switch (params.tab) {
      case '1':
        endPoint = 'pelayanan?jenis_layanan=IUMK&status=Diajukan';
        break;

      case '2':
        endPoint = 'pelayanan?jenis_layanan=IUMK&status=Diproses';
        break;

      case '3':
        endPoint = 'pelayanan?jenis_layanan=IUMK&status=Selesai';
        break;

      case '4':
        endPoint = 'pelayanan?jenis_layanan=IUMK&status=Ditolak';
        break;

      default:
        break;
    }

		// Get data from db
		Get(endPoint + link, null, (dtkat) => {
			console.log(dtkat);
			if (dtkat.results.data) {
				switch (params.tab) {
					case '1':
						this.setState({
							data: dtkat.results.data,
							loading: false,
							pagination: dtkat.results
						});
						break;
	
					case '2':
						this.setState({
							dataDiproses: dtkat.results.data,
							loading: false,
							pagination: dtkat.results
						});
						break;
	
					case '3':
						this.setState({
							dataSelesai: dtkat.results.data,
							loading: false,
							pagination: dtkat.results
						});
						break;
	
					case '4':
						this.setState({
							dataDitolak: dtkat.results.data,
							loading: false,
							pagination: dtkat.results
						});
						break;
				}
				this.pagination(dtkat.results, params.tab);
			}
		});
	};

	pagination = (data, tab) => {
		// const data = this.state.pagination;
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						key={i}
						onClick={() => this.fetch({ page: i, tab: tab })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p, tab: tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n, tab: tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}

			awal = (
				<a
					onClick={() => this.fetch({ page: 1, tab: tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page, tab: tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	tombolAksi = (cell, value) => {
		
		const btnDetail = (
      <Button
        size="sm"
        color="info"
        className="btn-square"
        onClick={() => {
          let status = { ...this.state.status };
          // status.modalDetail = true;
          status.modalCreate = true;
          console.log(value);
          this.setState({
						judul: 'Detail Surat Izin Usaha Mikro Kecil',
            status,
            detailSurat: value,
            dataPelayanan: JSON.parse(value.data_pelayanan),
            // dataPelapor: JSON.parse(value.data_pelapor),
						// dataDokumenOutput: JSON.parse(value.dokumen_output)
          });
          console.log(value);
          console.log(JSON.parse(value.data_pelayanan));
          // console.log(JSON.parse(value.data_pelayanan));
          // console.log(JSON.parse(value.data_pelapor));
        }}
        style={{ 
					padding: "0.25rem 0.5rem", 
					borderRadius: "50%",
					width: "30px",
					height: "30px",
				}}
				title="Detail"
      >
        <i className="fa fa-eye"></i>
        {/* Detail */}
      </Button>
    )

		if (value.data_status.length > 0) {
			if (value.data_status_baru.status == 'Diajukan') {
				return (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
						{btnDetail}
						{/* Btn Edit */}
						<Button
							size="sm"
							color="success"
							className="btn-square"
							onClick={() => {
								let status = { ...this.state.status };
								status.modalCreate = true;

								let dataPelayanan = JSON.parse(value.data_pelayanan)

								let linkGambar = {}
								Object.keys(dataPelayanan.linkGambar).map((attribute) => {
									let type = dataPelayanan.linkGambar[attribute].split('.')
									linkGambar[attribute] = {
										link: dataPelayanan.linkGambar[attribute],
										type: type[type.length - 1],
									}
								})
								console.log(linkGambar);

								this.setState({
									judul: "Edit Surat Izin Usaha Mikro Kecil",
									status,
									detailSurat: value,
									dataPelayanan: null,
									selectedDesa: dataPelayanan.id_desa,
									dt: {
										nik_pelapor: value.nik_pelapor,
										nama_pelapor: value.nama_pelapor,
										pekerjaan_pelapor: value.pekerjaan_pelapor,
										alamat_pelapor: value.alamat_pelapor,
										no_hp_pelapor: value.no_hp_pelapor,

										nik_pemilik: dataPelayanan.nik,
										email_pemilik: dataPelayanan.email,
										jenis_kelamin_pemilik: {
											value: dataPelayanan.jenis_kelamin,
											label: dataPelayanan.jenis_kelamin,
										},
										nama_pemilik: dataPelayanan.nama_pemilik,
										pendidikan_terakhir_pemilik: dataPelayanan.pendidikan_terakhir,
										telp_pemilik: dataPelayanan.telp,
										alamat_pemilik: dataPelayanan.alamat,
										kota_pemilik: dataPelayanan.kota,
										kecamatan_pemilik: dataPelayanan.kecamatan,
										kelurahan_pemilik: dataPelayanan.kelurahan,
										rw_pemilik: dataPelayanan.rw,
										rt_pemilik: dataPelayanan.rt,
										kode_pos_pemilik: dataPelayanan.kode_pos,
										npwp_pemilik: dataPelayanan.npwp,
										jenis_npwp_pemilik: {
											value: dataPelayanan.jenis_npwp.value,
											label: dataPelayanan.jenis_npwp.value,
										},

										legalitas_usaha: dataPelayanan.legalitas_usaha,
										no_surat: dataPelayanan.no_surat,
										nama_usaha: dataPelayanan.nama_usaha,
										alamat_usaha: dataPelayanan.alamat_usaha,
										telp_usaha: dataPelayanan.telp_usaha,
										kode_pos_usaha: dataPelayanan.kode_pos_usaha,
										jenis_usaha: {
											value: dataPelayanan.jenis_usaha.value,
											label: dataPelayanan.jenis_usaha.value
										},
										dusun_usaha: dataPelayanan.dusun_usaha,
										kota_usaha: dataPelayanan.kota_usaha,
										kecamatan_usaha: dataPelayanan.kecamatan_usaha,
										kelurahan_usaha: dataPelayanan.kelurahan_usaha,
										rw_usaha: dataPelayanan.rw_usaha,
										rt_usaha: dataPelayanan.rt_usaha,
										tahun_berdiri: dataPelayanan.tahun_berdiri,
										bentuk_perusahaan: {
											value: dataPelayanan.bentuk_perusahaan.value,
											label: dataPelayanan.bentuk_perusahaan.value,
										},
										uraian_usaha: dataPelayanan.uraian_usaha,
										sarana_usaha: dataPelayanan.sarana_usaha,
										jumlah_karyawan: dataPelayanan.jumlah_karyawan,
										permodalan_sendiri: dataPelayanan.permodalan_sendiri,
										permodalan_luar: dataPelayanan.permodalan_luar,
										kategori_omset: {
											value: dataPelayanan.omset.kategori_omset.value,
											label: dataPelayanan.omset.kategori_omset.value,
										},
										nominal_omset: dataPelayanan.omset.nominal_omset,
										kategori_aset: {
											value: dataPelayanan.aset.kategori_aset.value,
											label: dataPelayanan.aset.kategori_aset.value,
										},
										nominal_aset: dataPelayanan.aset.nominal_aset,
										sumber_bahan_baku: {
											value: dataPelayanan.sumber_bahan_baku.value,
											label: dataPelayanan.sumber_bahan_baku.value,
										},
										pemasaran: {
											value: dataPelayanan.pemasaran.value,
											label: dataPelayanan.pemasaran.value,
										},
										status_tempat_usaha: {
											value: dataPelayanan.status_tempat_usaha.value,
											label: dataPelayanan.status_tempat_usaha.value,
										},
										// linkGambar: dataPelayanan.linkGambar ? dataPelayanan.linkGambar : this.state.dt.linkGambar
										linkGambar
									}
								});
								console.log(this.state.dt);
								console.log(value);
								console.log(JSON.parse(value.data_pelayanan));
							}}
							style={{ 
								padding: "0.25rem 0.5rem", 
								borderRadius: "50%",
								width: "30px",
								height: "30px",
							}}
							title="Edit"
						>
							<i className="fa fa-pencil"></i>
						</Button>
						{/* Btn Penerbitan */}
						<Button
							size="sm"
							color="warning"
							className="btn-square"
							onClick={() => {
								this.setState({ 
									status: {...this.state.status, modalPenerbitan: true},
									detailSurat: value,
									dataPelayanan: JSON.parse(value.data_pelayanan),
									selectedTTD: null
								});
							}}
							style={{ borderRadius: "25px" }}
						>
							Terbitkan
						</Button>
					</div>
				);
			} else if (value.data_status_baru.status == 'Diproses') {
				return (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
						{btnDetail}
						<Button
							size="sm"
							color="success"
							className="btn-square"
							onClick={() => {
								// let status = { ...this.state.status };
								// status.modalUpload = true;
								// this.setState({ status, detailSurat: value });
								this.setState({ 
									status: {...this.state.status, modalPenerbitan: true},
									detailSurat: value,
									dataPelayanan: JSON.parse(value.data_pelayanan),
									// alert : (
									// 	<SweetAlert
									// 		showCancel
									// 		confirmBtnText="Ya, saya yakin"
									// 		confirmBtnBsStyle="warning"
									// 		confirmBtnStyle={{ fontWeight: 600 }}
									// 		cancelBtnText="Batal"
									// 		cancelBtnBsStyle="default"
									// 		cancelBtnStyle={{ fontWeight: 600 }}
									// 		type="warning"
									// 		title="Apakah anda yakin surat ini telah selesai ?"
									// 		onCancel={() => {
									// 			this.setState({ alert: null });
									// 		}}
									// 		onConfirm={() => {
									// 			this.tutupForm();

									// 			let bodyFormData = {
									// 				status: "Selesai",
									// 				status: "Selesai",
									// 				dokumen_output: '',
									// 			};
									
									// 			Post(
									// 				"pelayanan/verifikasi-status-kecamatan",
									// 				this.state.detailSurat.uuid,
									// 				bodyFormData,
									// 				(data) => {
									// 					console.log(data);
									// 					if (data.status == 200) {
									// 						let status = { ...this.state.status };
									// 						status.alert = true;
									// 						this.setState({
									// 							status,
									// 							basicType: "success",
									// 							basicTitle: "Penerbitan Surat",
									// 							pesanAlert: "Berhasil menerbitkan surat",
									// 						});
									// 						this.componentDidMount(this.state.active_tab_icon);
									// 					} else {
									// 						let status = { ...this.state.status };
									// 						status.alert = true;
									// 						this.setState({
									// 							status,
									// 							basicType: "danger",
									// 							basicTitle: "Penerbitan Surat",
									// 							pesanAlert: "Gagal menerbitkan surat",
									// 						});
									// 					}
									// 				}
									// 			);
									// 		}}
									// 	>
									// 		Proses ini tidak dapat dibatalkan, pastikan sudah memeriksa seluruh data dengan teliti
									// 	</SweetAlert>
									// )
								});
							}}
							style={{ padding: '0.25rem 0.5rem', borderRadius: "25px" }}
						>
							Terbitkan
						</Button>
					</div>
				);
			} else if (value.data_status_baru.status == "Selesai") {
				return (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
						{btnDetail}
						<a
							href={ API_URL + 'cetak-surat/surat-usaha-mikro/' + value.uuid }
							target="blank"
						>
							<Button
								size="sm"
								color="success"
								className="btn-square"
								style={{ padding: "0.25rem 0.5rem", borderRadius: "25px" }}
							>
								<span style={{ color: 'white' }}>Cetak</span>
							</Button>
						</a>
					</div>
				);
			} else if (value.data_status_baru.status == "Ditolak") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {btnDetail}
          </div>
        );
      }
		} else {
			return '';
		}
	};

	tutupForm() {
		let status = { ...this.state.status };
		status.modalValidasi = false;
		status.modalCreate = false;
		status.modalPenerbitan = false;
		status.tolakValidasi = false;
		status.modalUpload = false;
		status.modalDetail = false;
		status.fotoPersyaratan = false;

		this.setState({
			status,
			alert: null,
			detailSurat: null,
			dataPelayanan: null,
			dataDokumenOutput: null,
			// linkGambar: listFile,
			dt: frmDef
		});
	}

	hideAlert = () => {
		let status = { ...this.state.status };
		status.alert = false;
		this.setState({ status });
	};

	terbitkanSurat = (e) => {
		e.preventDefault()

		if (!this.state.selectedTTD) {
			this.setState({
				status: {...this.state.status, alert: true},
				basicType: 'warning',
				basicTitle: 'Data Izin Usaha Mikro Kecil',
				pesanAlert: 'Pilih penandatangan terlebih dahulu'
			});
		} else {
			let no_surat = document.getElementById('no_surat_kecamatan').value

			let dataTTD = this.state.selectedTTD
	
			let dataPelayanan = this.state.dataPelayanan
			dataPelayanan = {...this.state.dataPelayanan, 
				no_surat,
				nama_ttd_surat_kecamatan: dataTTD.nama,
				pangkat_ttd_surat_kecamatan: dataTTD.pangkat,
				jabatan_ttd_surat_kecamatan: dataTTD.jabatan,
				nip_ttd_surat_kecamatan: dataTTD.nip,
				tanggal_surat_kecamatan: dateFormat(new Date(), 'yyyy-mm-dd'),
			}
			console.log(dataPelayanan);
			
			let bodyFormData = {
				status: 'Selesai',
				data_pelayanan: JSON.stringify(dataPelayanan)
			};
			console.log(bodyFormData);
	
			Post('pelayanan/verifikasi-status-kecamatan', this.state.detailSurat.uuid, bodyFormData, (data) => {
				this.tutupForm();
				console.log(data);
				if (data.status == 200) {
					let status = { ...this.state.status };
					status.alert = true;
					this.setState({
						status,
						basicType: 'success',
						basicTitle: 'Penerbitan Surat Kecamatan',
						pesanAlert: 'Berhasil menerbitkan surat'
					});
					if (this.state.noSuratAuto) {
						let idJenisLayanan = this.props.location.state.uuid;
						Get('pelayanan/set-nomor-surat/' + idJenisLayanan, null, (dtkat) => {
							console.log(dtkat);
							if (dtkat.results) {
								this.setState({ noSuratKecamatan: dtkat.results.nomor_surat })
							}
						});
					}
					this.componentDidMount(this.state.active_tab_icon);
				} else {
					let status = { ...this.state.status };
					status.alert = true;
					this.setState({
						status,
						basicType: 'danger',
						basicTitle: 'Penerbitan Surat Kecamatan',
						pesanAlert: 'Gagal menerbitkan surat'
					});
				}
				// this.tutupForm();
			});
		}

	}

	simpan = (e) => {
		e.preventDefault()

		// Check select value
		if (this.state.dt.jenis_kelamin_pemilik && 
			this.state.dt.jenis_usaha && 
			this.state.dt.kategori_omset && 
			this.state.dt.kategori_aset && 
			this.state.dt.sumber_bahan_baku && 
			this.state.dt.pemasaran && 
			this.state.dt.status_tempat_usaha && 
			this.state.selectedDesa ) {
			// Cek persyaratan 
			let emptyGambar = false
			Object.keys(this.state.dt.linkGambar).map((attribute) => {
				if(this.state.dt.linkGambar[attribute].link  == ''){
					emptyGambar = true
				}
			})
			// Check legalitas usaha
			if (
				this.state.dt.legalitas_usaha.siup == false &&
        this.state.dt.legalitas_usaha.situ == false &&
        this.state.dt.legalitas_usaha.tdp == false &&
        this.state.dt.legalitas_usaha.npwp == false &&
        this.state.dt.legalitas_usaha.domisili == false &&
        this.state.dt.legalitas_usaha.halal == false &&
        this.state.dt.legalitas_usaha.hak_paten == false &&
        this.state.dt.legalitas_usaha.p_irt == false
			) {
					this.setState({
            // show: true,
						status: {...this.state.status, alert: true},
            basicType: 'warning',
            basicTitle: 'Data Izin Usaha Mikro Kecil',
            pesanAlert: 'Pilih Legalitas Usaha Terlebih Dahulu'
          });
			} else if (emptyGambar) {
				this.setState({
					// show: true,
					status: {...this.state.status, alert: true},
					basicType: 'warning',
					basicTitle: 'Data Izin Usaha Mikro Kecil',
					pesanAlert: 'Upload Bukti Persyaratan Terlebih Dahulu'
				});
			} else {
				let uuid = this.state.detailSurat ? this.state.detailSurat.uuid : null
				console.log(uuid);

				let linkGambar = {};
				Object.keys(this.state.dt.linkGambar).map((attribute) => {
					linkGambar[attribute] = this.state.dt.linkGambar[attribute].link
				})

				let pelayanan = {
					id_desa: {
						value: this.state.selectedDesa.value,
						label: this.state.selectedDesa.label
					},
          tgl_surat: this.state.dataPelayanan ? this.state.dataPelayanan.tgl_surat : dateFormat(new Date(), 'yyyy-mm-dd'),
          no_surat: this.state.dataPelayanan ? this.state.dataPelayanan.no_surat : this.state.dt.no_surat,
          nama_pemohon: document.getElementById('nama_pelapor').value,
          nama_pemilik: document.getElementById('nama_pemilik').value,
          jenis_kelamin: this.state.dt.jenis_kelamin_pemilik.value,
          // nik: {
					// 	value: document.getElementById('nik_pemilik').value,
					// 	label: document.getElementById('nik_pemilik').value,
					// },
					nik: document.getElementById('nik_pemilik').value,
          alamat: document.getElementById('alamat_pemilik').value,
          rt: document.getElementById('rt_pemilik').value,
          rw: document.getElementById('rw_pemilik').value,
          telp: document.getElementById('telp_pemilik').value,
          email: document.getElementById('email_pemilik').value,
          kelurahan: document.getElementById('kelurahan_pemilik').value,
          kecamatan: document.getElementById('kecamatan_pemilik').value,
          kota: document.getElementById('kota_pemilik').value,
          kode_pos: document.getElementById('kode_pos_pemilik').value,
          npwp: document.getElementById('npwp_pemilik').value,
          jenis_npwp: this.state.dt.jenis_npwp_pemilik,
          pendidikan_terakhir: document.getElementById('pendidikan_terakhir_pemilik').value,

          nama_usaha: document.getElementById('nama_usaha').value,
          alamat_usaha: document.getElementById('alamat_usaha').value,
          dusun_usaha: {
						value: document.getElementById('dusun_usaha').value,
						label: document.getElementById('dusun_usaha').value,
					},
          rt_usaha: {
						value: document.getElementById('rt_usaha').value,
						label: document.getElementById('rt_usaha').value,
					},
          rw_usaha: {
						value: document.getElementById('rw_usaha').value,
						label: document.getElementById('rw_usaha').value,
					},
          telp_usaha: document.getElementById('telp_usaha').value,
          kelurahan_usaha: document.getElementById('kelurahan_usaha').value,
          kecamatan_usaha: document.getElementById('kecamatan_usaha').value,
          kota_usaha: document.getElementById('kota_usaha').value,
          kode_pos_usaha: document.getElementById('kode_pos_usaha').value,
          tahun_berdiri: document.getElementById('tahun_berdiri').value,
          bentuk_perusahaan: this.state.dt.bentuk_perusahaan,
          jenis_usaha: this.state.dt.jenis_usaha,
          uraian_usaha: document.getElementById('uraian_usaha').value,
          sarana_usaha: document.getElementById('sarana_usaha').value,
          jumlah_karyawan: document.getElementById('jumlah_karyawan').value,
          permodalan_sendiri: document.getElementById('permodalan_sendiri').value,
          permodalan_luar: document.getElementById('permodalan_luar').value,
          omset: {
            kategori_omset: this.state.dt.kategori_omset,
            nominal_omset: document.getElementById('nominal_omset').value
          },
          aset: {
            kategori_aset: this.state.dt.kategori_aset,
            nominal_aset: document.getElementById('nominal_aset').value
          },
          sumber_bahan_baku: this.state.dt.sumber_bahan_baku,
          pemasaran: this.state.dt.pemasaran,
          legalitas_usaha: this.state.dt.legalitas_usaha,
          status_tempat_usaha: this.state.dt.status_tempat_usaha,
					// linkGambar: this.state.dt.linkGambar,
					linkGambar,
        }

				console.log(pelayanan);

				let bodyRaw = { 
					uuid,
          id_desa: this.state.selectedDesa.value,
          nik_pelapor: document.getElementById('nik_pelapor').value,
          nama_pelapor: document.getElementById('nama_pelapor').value,
          pekerjaan_pelapor: document.getElementById('pekerjaan_pelapor').value,
          alamat_pelapor: document.getElementById('alamat_pelapor').value,
          no_hp_pelapor: document.getElementById('no_hp_pelapor').value,
          tgl_melapor: this.state.dataPelayanan ? this.state.detailSurat.tgl_melapor : dateFormat(new Date(), 'yyyy-mm-dd'),
          data_pelayanan: JSON.stringify(pelayanan),
          dokumen_input: '',
          dokumen_output: '',
          jenis_layanan: "IUMK",
          uuid_surat_desa: uuid,
        }
				console.log(bodyRaw);

				let psn = "";
        let resstat = 204;
        let metode = "create";
        if (uuid == "" || uuid == null) {
          psn = "Tambah";
          resstat = 201;
          bodyRaw.uuid = null;
        } else {
          psn = "Ubah";
          resstat = 200;
          metode = "update";
        }

				Post(
          "pelayanan/" + metode,
          uuid,
          bodyRaw,
          res => {
            this.tutupForm();
            this.state.status.btnForm = false;
            this.state.status.form = false;
            this.forceUpdate();

            console.log(res)
            if (res.status === resstat) {
              this.setState({
                showBio: false,
                // show: true,
                status: {...this.state.status, alert: true},
                basicType: "success",
                basicTitle: "Data Izin Usaha Mikro Kecil",
                pesanAlert: "Berhasil " + psn + " Izin Usaha Mikro Kecil",
                // successAlert: true,
              });
              this.componentDidMount();
            } else {
              this.setState({
                showBio: false,
                // show: true,
								status: {...this.state.status, alert: true},
                basicType: "danger",
                basicTitle: "Data Izin Usaha Mikro Kecil",
                pesanAlert: "Gagal " + psn + " Izin Usaha Mikro Kecil"
              });
            }
          }
        );

			}
		} else {
			this.setState({
				// show: true,
				status: {...this.state.status, alert: true},
				basicType: 'warning',
				basicTitle: 'Data Izin Usaha Mikro Kecil',
				pesanAlert: 'Isi seluruh data yang diperlukan dengan baik dan benar'
			});
		}
	}

	updateSurat = (uuid_surat) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Ya, saya yakin"
					confirmBtnBsStyle="warning"
					confirmBtnStyle={{ fontWeight: 600 }}
					cancelBtnText="Batal"
					cancelBtnBsStyle="default"
					cancelBtnStyle={{ fontWeight: 600 }}
					type="warning"
					title="Apakah anda yakin syarat yang diberikan telah sesuai ?"
					onCancel={() => {
						this.setState({ alert: null });
					}}
					onConfirm={() => {
						if (this.state.detailSurat) {
							let bodyFormData = {
								status: 'Diproses'
							};
							Post('pelayanan/verifikasi-status-kecamatan', this.state.detailSurat.uuid, bodyFormData, (data) => {
								console.log(data);
								if (data.status == 200) {
									let status = { ...this.state.status };
									status.alert = true;
									this.setState({
										status,
										basicType: 'success',
										basicTitle: 'Validasi Pengajuan Pelayanan',
										pesanAlert: 'Berhasil validasi data'
									});
									this.componentDidMount(this.state.active_tab_icon);
								} else {
									let status = { ...this.state.status };
									status.alert = true;
									this.setState({
										status,
										basicType: 'danger',
										basicTitle: 'Validasi Pengajuan Pelayanan',
										pesanAlert: 'Gagal validasi data'
									});
								}
								this.tutupForm();
							});
						}
					}}
				>
					Proses ini tidak dapat dibatalkan, pastikan sudah memeriksa seluruh persyaratan dengan teliti
				</SweetAlert>
			)
		});
	};

	tolakSurat = (uuid_surat) => {
		let alasan = document.getElementById('alasan').value;
		if (alasan == '') {
			let status = { ...this.state.status };
			status.alert = true;

			this.setState({
				status,
				basicType: 'warning',
				basicTitle: 'Penolakan Pengajuan Layanan',
				pesanAlert: 'Isikan alasan penolakan terlebih dahulu'
			});
		} else {
			this.setState({
				alert: (
					<SweetAlert
						showCancel
						confirmBtnText="Ya, saya yakin"
						confirmBtnBsStyle="warning"
						confirmBtnStyle={{ fontWeight: 600 }}
						cancelBtnText="Batal"
						cancelBtnBsStyle="default"
						cancelBtnStyle={{ fontWeight: 600 }}
						type="warning"
						title="Apakah anda yakin ingin menolak pengajuan layanan ini ?"
						onCancel={() => {
							this.setState({ alert: null });
						}}
						onConfirm={() => {
							if (this.state.detailSurat) {
								let bodyFormData = {
									status: 'Ditolak',
									keterangan: alasan
								};
								Post(
									'pelayanan/verifikasi-status-kecamatan',
									uuid_surat,
									bodyFormData,
									(data) => {
										console.log(data);
										if (data.status == 200) {
											let status = { ...this.state.status };
											status.alert = true;
											this.setState({
												status,
												basicType: 'success',
												basicTitle: 'Validasi Pengajuan Pelayanan',
												pesanAlert: 'Berhasil melakukan penolakan pengajuan layanan'
											});
											this.componentDidMount(this.state.active_tab_icon);
										} else {
											let status = { ...this.state.status };
											status.alert = true;
											this.setState({
												status,
												basicType: 'danger',
												basicTitle: 'Validasi Pengajuan Pelayanan',
												pesanAlert: 'Gagal melakukan penolakan pengajuan layanan'
											});
										}
										this.tutupForm();
									}
								);
							}
						}}
					>
						Proses ini tidak dapat dibatalkan, pastikan anda sudah teliti dalam melakukan proses penolakan
						pengajuan
					</SweetAlert>
				)
			});
			// this.tutupForm()
		}
	};

	onFileChange = (event, attribute) => {
    const file = event.target.files[0];
    const fileType = file.type.split('/')
    const type = fileType[1]

		// Disable tombol selesai sampai file berhasil diupload
		this.setState({ status: {...this.state.status, btnForm: true} })
    
    // if (type == "pdf") {
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
      let bodyFormData = new FormData();
      bodyFormData.append("files", file);

			let folder = 'img'
			if (type == "pdf") {
				folder = "pdf"
			}
  
      Post("upload-file/" + folder, null, bodyFormData, (response) => {
        const link = response.data.results
        let linkGambar = {...this.state.dt.linkGambar};

        linkGambar[attribute] = { link, type: folder }
  
        this.setState({ 
					dt: {...this.state.dt, linkGambar},
					status: {...this.state.status, btnForm: false}
				});
      });
    } else {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Upload file",
        pesanAlert: "Format file harus berupa jpg/jpeg/png/pdf",
      });
      // document.getElementById("file-upload").value = null
    }

  };

	uploadFiles = (e) => {
    e.preventDefault()

    let isUploadEnable = true
    // Cek upload button
    Object.keys(this.state.linkGambar).map((attribute) => {
      isUploadEnable = isUploadEnable && this.state.linkGambar[attribute] != ''
    })

    if (isUploadEnable) {
      let bodyFormData = {
        status: "Selesai",
        dokumen_output: this.state.linkGambar,
      };

      Post(
        "pelayanan/verifikasi-status-kecamatan",
        this.state.detailSurat.uuid,
        bodyFormData,
        (data) => {
          console.log(data);
          if (data.status == 200) {
            let status = { ...this.state.status };
            status.alert = true;
            this.setState({
              status,
              basicType: "success",
              basicTitle: "Upload Berkas",
              pesanAlert: "Berhasil upload berkas",
            });
            this.componentDidMount(this.state.active_tab_icon);
          } else {
            let status = { ...this.state.status };
            status.alert = true;
            this.setState({
              status,
              basicType: "danger",
              basicTitle: "Upload Berkas",
              pesanAlert: "Gagal upload berkas",
            });
          }
          this.tutupForm();
        }
      );
    } else {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Upload berkas",
        pesanAlert: "Pilih berkas terlebih dahulu",
      });
    }
  };

	toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
			this.setState({ active_tab_icon: tab });
			this.fetch({ tab })
    }
  }

	render() {
		if (this.props.location.state == undefined) {
      return <Redirect to="/" />;
    }

		var data = this.state.data;

		switch (this.state.active_tab_icon) {
      case "1":
        data = this.state.data
        break;

      case "2":
        data = this.state.dataDiproses
        break;

      case "3":
        data = this.state.dataSelesai
        break;

      case "4":
        data = this.state.dataDitolak
        break;
    }

		const columns = [
			{
				// dataField: 'tgl_melapor',
				dataField: 'create_at',
				text: 'Tanggal Melapor',
				formatter: (cell, obj) => {
          let date = new Date(obj.create_at)

          let dateformat = [date.getDate(),
                            date.getMonth()+1,
                            date.getFullYear()].join('-')+' '+
                           [date.getHours(),
                            date.getMinutes(),
                            date.getSeconds()].join(':');
          return dateformat;
        },
				sort: false
			},
			{
				dataField: 'jenis_layanan',
				text: 'Layanan',
				sort: true
			},
			{
				dataField: 'nik_pelapor',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'nama_pelapor',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_status_baru.status',
				text: 'status',
				sort: true
				// formatter: (value)=> {
				//   return value[0]
				// }
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];

		let bootstrapTable = (
			<div>
				<BootstrapTable keyField="id" data={data} columns={columns} />
				{data.length != 0 ? (
          <div className="pull-right text-white">
            {this.state.awal}
            {this.state.sebelum}
            {this.state.hal.map(dt => {
              return dt;
            })}
            {this.state.setelah}
            {this.state.akhir}
          </div>
        ) : ''}
			</div>
		)

		let uploadButton = true
    // Cek upload button
    Object.keys(this.state.linkGambar).map((attribute) => {
      uploadButton = uploadButton && this.state.linkGambar[attribute] != ''
    })

		let no = 0;
		let tableTTD = this.state.listPerangkat.map((dt, i) => {
			no++;
			return (
				<tr key={i}>
					<td>{no}</td>
					<td>{dt.jabatan}</td>
					<td>{dt.nama}</td>
					<td>{dt.nip ? dt.nip : '-'}</td>
					<td>{dt.pangkat}</td>
					<td>{dt.jabatan}</td>
					<td>
						<Button
							size="xs"
							onClick={() => {
								this.setState({ selectedTTD: {...dt, no: i} })
								console.log(this.state.selectedTTD);
							}}
							color={
								this.state.selectedTTD && this.state.selectedTTD.no == i ? (
									'danger'
								) : (
									'default'
								)
							}
							className="btn-icon"
						>
							{this.state.selectedTTD && this.state.selectedTTD.no == i ? (
								'Dipilih'
							) : (
								'Pilih'
							)}
						</Button>
					</td>
				</tr>
			);
		});
		if (this.state.listPerangkat.length < 1) {
			tableTTD = (
				<tr>
					<td colSpan={7} style={{ textAlign: "center" }}>Belum ada data perangkat pada instansi terkait, silahkan hubungi admin</td>
				</tr>
			)
		}

		return (
			<div>
				<SweetAlert
					show={this.state.status.alert}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={() => {
						this.setState({ status: {...this.state.status, alert: false} })
					}}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12 p-0 m-0">
							{/* <div className="card col-lg-12 p-10 bg-light">
								<div className="row" style={{ marginBottom: '10px' }}>
									<div className="col-sm-12 mb-0" style={{ color: 'black' }}>
										<h6 style={{ marginBottom: "0px", fontWeight: 400 }}>Penerbitan Izin Usaha Mikro</h6>
									</div>
								</div>
							</div> */}
							<div className="card col-lg-12 p-10">
								<div className="card-header" style={{ padding: '20px' }}>
									<div className="row">
										<div className="col-sm-6 text-left">
											<h6 style={{ color: 'black', fontSize: '17px' }} className="mb-0">
												Data Pengajuan Penerbitan Izin Usaha Mikro
											</h6>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react" style={{ paddingTop: '15px' }}>
								<div className="row" style={{ marginBottom: "15px" }}>
										<div className="col-sm-12" style={{ paddingLeft: "0px" }}>
											<Button
												color="success"
												className="btn btn-secondary"
												onClick={() => {
													this.setState({ 
														judul: 'Data Izin Usaha Mikro Kecil',
														status: {...this.state.status, modalCreate: true},
														detailSurat: null,
														dataPelayanan: null,
														selectedDesa: null,
														dt: frmDef
													})
												}}
											>
												Tambah Surat
											</Button>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-12 p-0">
											<Nav tabs className="tabs-color">
												<NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "1"
														})}
														onClick={() => {
															this.toggle_icon("1");
														}}
													>
														Diajukan
													</NavLink>
												</NavItem>
												{/* <NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "2"
														})}
														onClick={() => {
															this.toggle_icon("2");
														}}
													>
														Diproses
													</NavLink>
												</NavItem> */}
												<NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "3"
														})}
														onClick={() => {
															this.toggle_icon("3");
														}}
													>
														Selesai
													</NavLink>
												</NavItem>
												{/* <NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "4"
														})}
														onClick={() => {
															this.toggle_icon("4");
														}}
													>
														Ditolak
													</NavLink>
												</NavItem> */}
											</Nav>
											<TabContent activeTab={this.state.active_tab_icon}>
												<TabPane tabId="1">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "1" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="2">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "2" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="3">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "3" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="4">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "4" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
											</TabContent>
										</div>
									</div>
									<div className="row" style={{ marginTop: "10px" }}>
										<div className="col-sm-12">
											<Link to="/admin/dashboard">Kembali ke dashboard</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Modal Create */}
					<Modal
						open={this.state.status.modalCreate}
						closeOnEsc={false}
						closeOnOverlayClick={true}
						onClose={() => { this.tutupForm() }}
						styles={{ modal: { width: "100%" } }}
					>
						<Form className="theme-form" onSubmit={this.simpan}>
							<div className="modal-header" align="center">
								<h5 className="modal-title">{this.state.judul}</h5>
							</div>

							<div className="modal-body">
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>{this.state.dataPelayanan ? 'Desa' : 'Pilih Desa'}</b>
									</Label>
									<div className="col-sm-8">
										{this.state.dataPelayanan ? (
											<input
												className="form-control"
												style={{
													fontWeight: 400,
													fontSize: 'inherit',
													backgroundColor:"#c9d1d8"
												}}
												defaultValue={this.state.dataPelayanan.id_desa ? this.state.dataPelayanan.id_desa.label : ''}
												disabled
												type="text"
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ selectedDesa: e })
												}}
												defaultValue={this.state.selectedDesa}
												value={this.state.selectedDesa}
												name="penduduk"
												options={this.state.listDesa}
												placeholder="Pilih Desa"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								{/* Data Pelapor */}
								<FormGroup className="row" style={{ marginBottom: '0px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Data Pelapor</b>
									</Label>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										NIK Pelapor
									</Label>
									<div className="col-sm-8">
										<input
											className="form-control"
											id="nik_pelapor"
											type="text"
											placeholder="NIK Pelapor"
											defaultValue={this.state.detailSurat ? this.state.detailSurat.nik_pelapor : this.state.dt.nik_pelapor}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Nama
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="nama_pelapor"
											type="text"
											placeholder="Nama Pelapor"
											// value={this.state.dt.nama}
											defaultValue={this.state.detailSurat ? this.state.detailSurat.nama_pelapor : this.state.dt.nama_pelapor}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										No. HP Pelapor
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="no_hp_pelapor"
											type="text"
											placeholder="No. HP Pelapor"
											// value={this.state.dt.no_hp_pelapor}
											defaultValue={this.state.detailSurat ? this.state.detailSurat.no_hp_pelapor : this.state.dt.no_hp_pelapor}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								{/* <FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Jenis Kelamin Pelapor
									</Label>
									<div className="col-sm-8">
										{this.state.detailSurat && this.state.dataPelayanan ? (
											<input
												className="form-control"
												type="text"
												defaultValue={this.state.dataPelayanan.jenis_kelamin}
												style={{ backgroundColor:"#c9d1d8" }}
												disabled
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, jenis_kelamin_pelapor: e} })
												}}
												defaultValue={this.state.dt.jenis_kelamin_pelapor}
												value={this.state.dt.jenis_kelamin_pelapor}
												name="penduduk"
												options={[
													{value: 'Laki-Laki', label: 'Laki-Laki'},
													{value: 'Perempuan', label: 'Perempuan'},
												]}
												placeholder="Pilih Jenis Kelamin Pelapor"
												isClearable
											/>
										)}
									</div>
								</FormGroup> */}
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Pekerjaan Pelapor
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="pekerjaan_pelapor"
											type="text"
											placeholder="Pekerjaan Pelapor"
											// value={this.state.dt.no_hp_pelapor}
											defaultValue={this.state.detailSurat ? this.state.detailSurat.pekerjaan_pelapor : this.state.dt.pekerjaan_pelapor}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Alamat Pelapor
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="alamat_pelapor"
											type="text"
											placeholder="Alamat Pelapor"
											defaultValue={this.state.detailSurat ? this.state.detailSurat.alamat_pelapor : this.state.dt.alamat_pelapor}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>

								{/* Data Pemilik */}
								<FormGroup className="row" style={{ marginBottom: '0px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Data Pemilik</b>
									</Label>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										NIK Pemilik
									</Label>
									<div className="col-sm-8">
										<input
											className="form-control"
											id="nik_pemilik"
											type="text"
											placeholder="NIK Pemilik"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.nik : this.state.dt.nik_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Nama Pemilik
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="nama_pemilik"
											type="text"
											placeholder="Nama Pemilik"
											// value={this.state.dt.nama}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.nama_pemilik : this.state.dt.nama_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Email Pemilik
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="email_pemilik"
											type="text"
											placeholder="Email Pemilik"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.email : this.state.dt.email_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Jenis Kelamin Pemilik
									</Label>
									<div className="col-sm-8">
										{this.state.detailSurat && this.state.dataPelayanan ? (
											<input
												className="form-control"
												type="text"
												defaultValue={this.state.dataPelayanan.jenis_kelamin}
												style={{ backgroundColor:"#c9d1d8" }}
												disabled
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, jenis_kelamin_pemilik: e} })
												}}
												defaultValue={this.state.dt.jenis_kelamin_pemilik}
												value={this.state.dt.jenis_kelamin_pemilik}
												name="penduduk"
												options={[
													{value: 'Laki-Laki', label: 'Laki-Laki'},
													{value: 'Perempuan', label: 'Perempuan'},
												]}
												placeholder="Pilih Jenis Kelamin Pemilik"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										No. Telpon Pemilik
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="telp_pemilik"
											type="text"
											placeholder="No. Telpon Pemilik"
											// value={this.state.dt.telp_pemilik}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.telp : this.state.dt.telp_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Pendidikan Terakhir Pemilik
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="pendidikan_terakhir_pemilik"
											type="text"
											placeholder="Pendidikan Terakhir Pemilik"
											// value={this.state.dt.pendidikan_terakhir_pemilik}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.pendidikan_terakhir : this.state.dt.pendidikan_terakhir_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Alamat Pemilik
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="alamat_pemilik"
											type="text"
											placeholder="Alamat Pemilik"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.alamat : this.state.dt.alamat_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										Kabupaten / Kota
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="kota_pemilik"
											type="text"
											placeholder="Kota"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.kota : this.state.dt.kota_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										Kecamatan
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="kecamatan_pemilik"
											type="text"
											placeholder="Kecamatan"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.kecamatan : this.state.dt.kecamatan_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										Kelurahan
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="kelurahan_pemilik"
											type="text"
											placeholder="Kelurahan"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.kelurahan : this.state.dt.kelurahan_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										RW
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="rw_pemilik"
											type="text"
											placeholder="RW"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.rw : this.state.dt.rw_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										RT
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="rt_pemilik"
											type="text"
											placeholder="RT"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.rt : this.state.dt.rt_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										Kode POS
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="kode_pos_pemilik"
											type="text"
											placeholder="Kode POS"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.kode_pos : this.state.dt.kode_pos_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										NPWP
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="npwp_pemilik"
											type="text"
											placeholder="NPWP"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.npwp : this.state.dt.npwp_pemilik}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Jenis NPWP
									</Label>
									<div className="col-sm-8">
										{this.state.dataPelayanan ? (
											<input
												className="form-control"
												id="jenis_npwp_pemilik"
												type="text"
												placeholder="Jenis NPWP"
												defaultValue={this.state.dataPelayanan.jenis_npwp.label}
												style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
												disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
												required
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, jenis_npwp_pemilik: e} })
												}}
												defaultValue={this.state.dt.jenis_npwp_pemilik}
												value={this.state.dt.jenis_npwp_pemilik}
												options={[
													{value: 'Pribadi', label: 'Pribadi'},
                        	{value: 'Perusahaan', label: 'Perusahaan'},
												]}
												placeholder="Pilih Jenis NPWP"
												isClearable
											/>
										)}
									</div>
								</FormGroup>

								{/* Data Surat */}
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>DATA SURAT</b>
									</Label>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Legalitas Usaha</b>
									</Label>
									<div className="col-sm-8">
										<div className="row" style={{ width: "100%" }}>
											{this.state.dataPelayanan ? (
												Object.keys(this.state.dataPelayanan.legalitas_usaha).map((jenis, i) => {
													return (
														<div className="col-sm-4">
															<input type="checkbox"
																checked={this.state.dataPelayanan.legalitas_usaha[jenis]}
																readOnly
															/> {jenis.toUpperCase().replace("_", ' ')}
														</div>
													)
												})
											) : (
												Object.keys(this.state.dt.legalitas_usaha).map((jenis, i) => {
													return (
														<div className="col-sm-4">
															<input type="checkbox"
																checked={this.state.dt.legalitas_usaha[jenis]}
																onChange={e => {
																	let value = this.state.dt.legalitas_usaha[jenis]
																	this.setState({ 
																		dt: {...this.state.dt, 
																			legalitas_usaha: {...this.state.dt.legalitas_usaha, 
																				[jenis]: !value
																			}
																		} 
																	})
																}}
															/> {jenis.toUpperCase().replace("_", ' ')}
														</div>
													)
												})
											)}
										</div>
									</div>
								</FormGroup>

								{/* Data Usaha */}
								<FormGroup className="row" style={{ marginBottom: '0px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Data Usaha</b>
									</Label>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Nama Usaha</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="nama_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											type="text"
											placeholder="Contoh: CV. Jaya Bersama Abadi"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.nama_usaha : this.state.dt.nama_usaha}
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Jenis Usaha</b>
									</Label>
									<div className="col-sm-8">
										{this.state.dataPelayanan ? (
											<input
												id="jenis_usaha"
												className="form-control"
												style={{
													fontWeight: 400,
													fontSize: 'inherit'
												}}
												defaultValue={this.state.dataPelayanan.jenis_usaha.label}
												// value={this.state.dt.jenis_usaha}
												type="text"
												placeholder="Contoh: Konstruksi Bangunan"
												style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
												disabled={this.state.dataPelayanan ? true : false}
												required
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, jenis_usaha: e} })
												}}
												defaultValue={this.state.dt.jenis_usaha}
												value={this.state.dt.jenis_usaha}
												options={[
													{value: 'Perdagangan', label: 'Perdagangan'},
													{value: 'Makanan', label: 'Makanan'},
													{value: 'Jasa', label: 'Jasa'},
													{value: 'Handycraft', label: 'Handycraft'},
													{value: 'Fashion', label: 'Fashion'},
													{value: 'Lain-lain', label: 'Lain-lain'},
												]}
												placeholder="Pilih Jenis Usaha"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Alamat Usaha</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="alamat_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											type="text"
											placeholder="Contoh: CV. Jaya Bersama Abadi"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.alamat_usaha : this.state.dt.alamat_usaha}
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>No. Telp. Usaha</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="telp_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											type="text"
											placeholder="Contoh: CV. Jaya Bersama Abadi"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.telp_usaha : this.state.dt.telp_usaha}
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>

								<FormGroup className="row" style={{ marginBottom: '0px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Lokasi Usaha</b>
									</Label>
								</FormGroup>
								
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Kota Usaha</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="kota_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.kota_usaha : this.state.dt.kota_usaha}
											// value={this.state.dt.kota_usaha}
											type="text"
											placeholder="Contoh: Pekanbaru"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Kecamatan Usaha</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="kecamatan_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.kecamatan_usaha : this.state.dt.kecamatan_usaha}
											// value={this.state.dt.kecamatan_usaha}
											type="text"
											placeholder="Contoh: Marpoyan Damai"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Kelurahan Usaha</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="kelurahan_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.kelurahan_usaha : this.state.dt.kelurahan_usaha}
											// value={this.state.dt.kelurahan_usaha}
											type="text"
											placeholder="Contoh: Maharatu"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Dusun</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="dusun_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.dusun_usaha.label : this.state.dt.dusun_usaha.label}
											// value={this.state.dt.dusun_usaha}
											type="text"
											placeholder="Contoh: Durian Runtuh"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>RW</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="rw_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											// defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.lokasi_usaha.rw : this.state.dt.rw_usaha}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.rw_usaha.label : this.state.dt.rw_usaha.label}
											// value={this.state.dt.rw_usaha}
											type="number"
											placeholder="Contoh: 001"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>RT</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="rt_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											// defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.lokasi_usaha.rt : this.state.dt.rt_usaha}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.rt_usaha.label : this.state.dt.rt_usaha.label}
											// value={this.state.dt.rt_usaha}
											type="number"
											placeholder="Contoh: 002"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Kode POS</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="kode_pos_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.kode_pos_usaha : this.state.dt.kode_pos_usaha}
											// value={this.state.dt.kode_pos_usaha}
											type="text"
											placeholder="Contoh: 44554"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Tahun Berdiri</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="tahun_berdiri"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.tahun_berdiri : this.state.dt.tahun_berdiri}
											// value={this.state.dt.tahun_berdiri}
											type="number"
											placeholder="Contoh: 2021"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Bentuk Perusahaan</b>
									</Label>
									<div className="col-sm-8">
										{this.state.dataPelayanan ? (
											<input
												id="bentuk_perusahaan"
												className="form-control"
												style={{
													fontWeight: 400,
													fontSize: 'inherit'
												}}
												defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.bentuk_perusahaan.label : this.state.dt.bentuk_perusahaan}
												// value={this.state.dt.bentuk_perusahaan}
												type="text"
												placeholder="Contoh: PT"
												style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
												disabled={this.state.dataPelayanan ? true : false}
												required
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, bentuk_perusahaan: e} })
												}}
												defaultValue={this.state.dt.bentuk_perusahaan}
												value={this.state.dt.bentuk_perusahaan}
												options={[
													{value: 'CV', label: 'CV'},
													{value: 'Firma', label: 'Firma'},
													{value: 'PT', label: 'PT'},
													{value: 'PD', label: 'PD'},
													{value: 'Koperasi', label: 'Koperasi'},
													{value: 'Perseorangan', label: 'Perseorangan'},
												]}
												placeholder="Pilih Bentuk Perusahaan"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Keterangan Jenis Usaha</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="uraian_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.uraian_usaha : this.state.dt.uraian_usaha}
											// value={this.state.dt.uraian_usaha}
											type="text"
											placeholder="Contoh: Toko Kelontong menjual berbagai macam barang keperluan"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Sarana Usaha</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="sarana_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.sarana_usaha : this.state.dt.sarana_usaha}
											// value={this.state.dt.sarana_usaha}
											type="text"
											placeholder="Contoh: Transportasi Darat"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Jumlah Karyawan</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="jumlah_karyawan"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.jumlah_karyawan : this.state.dt.jumlah_karyawan}
											// value={this.state.dt.jumlah_karyawan}
											type="number"
											placeholder="Contoh: 5"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Modal Sendiri</b>
									</Label>
									<div className="col-sm-8">
										{/* <input
											id="permodalan_sendiri"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.permodalan_sendiri : this.state.dt.permodalan_sendiri}
											// value={this.state.dt.permodalan_sendiri}
											type="number"
											placeholder="Contoh: 02"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/> */}
										<NumberFormat
                      id="permodalan_sendiri"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
                      placeholder="Contoh : 10.500.000"
                      thousandSeparator={true}
                      prefix={'Rp. '}
                      className="form-control"
                      defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.permodalan_sendiri : this.state.dt.permodalan_sendiri}
                      // value={this.state.dt.permodalan_sendiri}
                      // onChange={(e) => {
                      //   this.changeSelectValue(e, 'permodalan_sendiri');
                      // }}
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
                    />
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Modal Luar</b>
									</Label>
									<div className="col-sm-8">
										{/* <input
											id="permodalan_luar"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.permodalan_luar : this.state.dt.permodalan_luar}
											// value={this.state.dt.permodalan_luar}
											type="number"
											placeholder="Contoh: 02"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/> */}
										<NumberFormat
                      id="permodalan_luar"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
                      placeholder="Contoh : 10.500.000"
                      thousandSeparator={true}
                      prefix={'Rp. '}
                      className="form-control"
                      defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.permodalan_luar : this.state.dt.permodalan_luar}
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
                    />
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Kategori Omset / Tahun</b>
									</Label>
									<div className="col-sm-8">
										{this.state.dataPelayanan ? (
											<input
												id="kategori_omset"
												className="form-control"
												style={{
													fontWeight: 400,
													fontSize: 'inherit'
												}}
												defaultValue={this.state.dataPelayanan.omset.kategori_omset.label}
												// value={this.state.dt.bentuk_perusahaan}
												type="text"
												placeholder="Contoh: 02"
												style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
												disabled={this.state.dataPelayanan ? true : false}
												required
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, kategori_omset: e} })
												}}
												defaultValue={this.state.dt.kategori_omset}
												value={this.state.dt.kategori_omset}
												options={[
													{value: 'Mikro <= 300 jt', label: 'Mikro <= 300 jt'},
													{value: 'Kecil 300 jt – 2,5 M', label: 'Kecil 300 jt – 2,5 M'},
													{value: 'Menengah 2,5 – 50 M', label: 'Menengah 2,5 – 50 M'},
												]}
												placeholder="Pilih Kategori Omset"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Nominal Omset / Tahun</b>
									</Label>
									<div className="col-sm-8">
										{/* <input
											id="nominal_omset"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.omset.nominal_omset : this.state.dt.nominal_omset}
											// value={this.state.dt.nominal_omset}
											type="text"
											placeholder="Contoh: 02"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/> */}
										<NumberFormat
                      id="nominal_omset"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
                      placeholder="Contoh : 10.500.000"
                      thousandSeparator={true}
                      prefix={'Rp. '}
                      className="form-control"
                      defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.omset.nominal_omset : this.state.dt.nominal_omset}
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
                    />
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Kategori Aset</b>
									</Label>
									<div className="col-sm-8">
										{this.state.dataPelayanan ? (
											<input
												id="kategori_aset"
												className="form-control"
												style={{
													fontWeight: 400,
													fontSize: 'inherit'
												}}
												defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.aset.kategori_aset.label : this.state.dt.kategori_aset}
												// value={this.state.dt.bentuk_perusahaan}
												type="text"
												placeholder="Contoh: 02"
												style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
												disabled={this.state.dataPelayanan ? true : false}
												required
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, kategori_aset: e} })
												}}
												defaultValue={this.state.dt.kategori_aset}
												value={this.state.dt.kategori_aset}
												options={[
													{value: 'Mikro <  50 jt', label: 'Mikro <  50 jt'},
													{value: 'Kecil 50-500 jt', label: 'Kecil 50-500 jt'},
													{value: 'Menengah 500 jt- 10 M', label: 'Menengah 500 jt- 10 M'},
												]}
												placeholder="Pilih Kategori Aset"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Nominal Aset</b>
									</Label>
									<div className="col-sm-8">
										{/* <input
											id="nominal_aset"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.aset.nominal_aset : this.state.dt.nominal_aset}
											// value={this.state.dt.nominal_aset}
											type="text"
											placeholder="Contoh: 02"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/> */}
										<NumberFormat
                      id="nominal_aset"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
                      placeholder="Contoh : 10.500.000"
                      thousandSeparator={true}
                      prefix={'Rp. '}
                      className="form-control"
                      defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.aset.nominal_aset : this.state.dt.nominal_aset}
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
                    />
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Sumber Bahan Baku</b>
									</Label>
									<div className="col-sm-8">
										{this.state.dataPelayanan ? (
											<input
												id="sumber_bahan_baku"
												className="form-control"
												style={{
													fontWeight: 400,
													fontSize: 'inherit'
												}}
												defaultValue={this.state.dataPelayanan.sumber_bahan_baku.label}
												// value={this.state.dataPelayanan.sumber_bahan_baku.label}
												type="text"
												placeholder="Contoh: 02"
												style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
												disabled={this.state.dataPelayanan ? true : false}
												required
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, sumber_bahan_baku: e} })
												}}
												defaultValue={this.state.dt.sumber_bahan_baku}
												value={this.state.dt.sumber_bahan_baku}
												options={[
													{
														value: 'Lokal',
														label: 'Lokal',
													},
													{
														value: 'Luar Kota',
														label: 'Luar Kota',
													},
													{
														value: 'Impor',
														label: 'Impor',
													},
												]}
												placeholder="Pilih Sumber Bahan Baku"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Daerah Pemasaran</b>
									</Label>
									<div className="col-sm-8">
										{this.state.dataPelayanan ? (
											<input
												id="pemasaran"
												className="form-control"
												style={{
													fontWeight: 400,
													fontSize: 'inherit'
												}}
												defaultValue={this.state.dataPelayanan.pemasaran.label}
												// value={this.state.dataPelayanan.pemasaran.label}
												type="text"
												placeholder="Contoh: 02"
												style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
												disabled={this.state.dataPelayanan ? true : false}
												required
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, pemasaran: e} })
												}}
												defaultValue={this.state.dt.pemasaran}
												value={this.state.dt.pemasaran}
												options={[
													{
														value: 'Lokal',
														label: 'Lokal',
													},
													{
														value: 'Regional',
														label: 'Regional',
													},
													{
														value: 'Nasional',
														label: 'Nasional',
													},
													{
														value: 'Impor',
														label: 'Impor',
													},
												]}
												placeholder="Pilih Daerah Pemasaran"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '15px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Status Tempat Usaha</b>
									</Label>
									<div className="col-sm-8">
										{this.state.dataPelayanan ? (
											<input
												id="status_tempat_usaha"
												className="form-control"
												style={{
													fontWeight: 400,
													fontSize: 'inherit'
												}}
												defaultValue={this.state.dataPelayanan.status_tempat_usaha.label}
												// value={this.state.dataPelayanan.status_tempat_usaha.label}
												type="text"
												placeholder="Contoh: 02"
												style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
												disabled={this.state.dataPelayanan ? true : false}
												required
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, status_tempat_usaha: e} })
												}}
												defaultValue={this.state.dt.status_tempat_usaha}
												value={this.state.dt.status_tempat_usaha}
												options={[
													{
														value: 'Milik Sendiri',
														label: 'Milik Sendiri',
													},
													{
														value: 'Sewa',
														label: 'Sewa',
													},
												]}
												placeholder="Pilih Status Tempat Usaha"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								
								{/* Form Upload */}
								{!this.state.dataPelayanan ? (
									Object.keys(this.state.dt.linkGambar).map((attribute) => {
										let titleFile = 'dokumen'
										switch (attribute) {
											case 'surat_pengantar_lokasi_usaha':
												titleFile = "Surat Pengantar Lokasi Usaha"
												break;
											case 'fotocopy_ktp':
												titleFile = "Fotocopy KTP"
												break;
											case 'fotocopy_kk':
												titleFile = "Fotocopy KK"
												break;
											case 'pas_foto':
												titleFile = "Pas Foto"
												break;
											case 'npwp':
												titleFile = "NPWP"
												break;
										}
	
										return (
											<FormGroup className="row" style={{ marginBottom: '10px' }}>
												<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
													<b>File {titleFile} (jpg/pdf)</b>
												</Label>
												<div
													className="col-sm-8"
													style={{ display: "flex", flexDirection: "column" }}
												>
													<input id="file-upload" type="file" onChange={(event) => {
														this.onFileChange(event, attribute)
													}} accept="image/png, image/jpg, image/jpeg, application/pdf" />
													{this.state.dt.linkGambar[attribute].link != "" ? (
														this.state.dt.linkGambar[attribute].type == "pdf" ? (
															<a href={this.state.dt.linkGambar[attribute].link}>Lihat file</a>
														) : (
															<a 
																href='/'
																onClick={(e) => {
																	e.preventDefault()
																	let status = { ...this.state.status };
																	status.fotoPersyaratan = true;
																	this.setState({
																		status,
																		fotoPersyaratan: this.state.dt.linkGambar[attribute].link,
																		titlePersyaratan: titleFile,
																	});
																}}
																>
																	Lihat file
															</a>
														)
													) : (
														""
													)}
												</div>
											</FormGroup>
										)
									})
								) : (
									<div className="row">
										{/* Looping foto persyaratan */}
										{Object.keys(this.state.dt.linkGambar).map((attribute) => {
											let titleFile = 'dokumen'
											switch (attribute) {
												case 'surat_pengantar_lokasi_usaha':
													titleFile = "Surat Pengantar Lokasi Usaha"
													break;
												case 'fotocopy_ktp':
													titleFile = "Fotocopy KTP"
													break;
												case 'fotocopy_kk':
													titleFile = "Fotocopy KK"
													break;
												case 'pas_foto':
													titleFile = "Pas Foto"
													break;
												case 'npwp':
													titleFile = "NPWP"
													break;
											}

											let linkGambarExist = false
											let explodeLinkGambar = []
											let typeFile = ''

											if (this.state.dataPelayanan.linkGambar && this.state.dataPelayanan.linkGambar[attribute] && this.state.dataPelayanan.linkGambar[attribute] != "") {
												linkGambarExist = true
												explodeLinkGambar = this.state.dataPelayanan.linkGambar[attribute].split('.')
												typeFile = explodeLinkGambar[explodeLinkGambar.length - 1]
											}


											return (
												<div
														className="col-md-4"
														style={{
															display: 'flex',
															flexDirection: 'column',
															alignItems: 'center'
														}}
													> 
														{/* {this.state.dataPelayanan.linkGambar && this.state.dataPelayanan.linkGambar[attribute] && this.state.dataPelayanan.linkGambar[attribute] != "" ? ( */}
														{linkGambarExist ? (
															typeFile != "pdf" ? (
																<img
																	src={this.state.dataPelayanan.linkGambar[attribute]}
																	style={{ width: "200px", height: "200px", objectFit: "cover", cursor: "pointer" }}
																	onClick={() => {
																		let status = { ...this.state.status };
																		status.fotoPersyaratan = true;
																		this.setState({
																			status,
																			fotoPersyaratan: this.state.dataPelayanan.linkGambar[attribute],
																			titlePersyaratan: titleFile,
																		});
																	}}
																/>
															) : (
																<a href={this.state.dataPelayanan.linkGambar[attribute]}>Lihat file</a>
															)
                            ) : (
                              <div style={{ height: "200px", width: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <p style={{ textAlign: "center", fontWeight: 600 }}>Tidak ada foto persyaratan</p>
                              </div>
                            )}
														<p style={{ textAlign: "center" }}>{titleFile}</p>
													</div>
											)
										})}
									</div>
								)}

							</div>
						
							<div className="modal-footer" align="center">
								<button
									type="button"
									className="btn btn-warning"
									disabled={this.state.status.btnForm}
									onClick={() => {
										this.tutupForm()
									}}
									>
									Tutup
								</button>
								{!this.state.dataPelayanan ? (
									<button
										type="submit"
										className="btn btn-success"
										disabled={this.state.status.btnForm}
										>
										Selesai
									</button>
								) : ''}
							</div>
						</Form>
					</Modal>

					{/* Modal validasi */}
					<Modal
						open={this.state.status.modalValidasi}
						styles={{
							modal: {
								width: '85%'
							}
						}}
						onClose={() => {
							// Tutup modal validasi
							let status = { ...this.state.status };
							status.modalValidasi = false;
							this.setState({ status });
						}}
					>
						<Form className="theme-form">
							<div className="modal-header">
								<h5 className="modal-title">Form Validasi Penerbitan Izin Usaha Mikro</h5>
							</div>
							<div className="modal-body">
								{this.state.detailSurat ? (
									<div>
										{/* Detail Surat */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Nama
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.nama_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												NIK
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.nik_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Jenis Layanan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.jenis_layanan}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Tanggal Pengajuan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.tgl_melapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Nomor HP
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.no_hp_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Pekerjaan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.pekerjaan_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Alamat
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.alamat_pelapor}
												</h6>
											</div>
										</FormGroup>
									</div>
								) : (
									''
								)}
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-warning"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										let status = { ...this.state.status };
										status.tolakValidasi = true;

										this.setState({ status });
									}}
									style={{ fontWeight: 600 }}
								>
									Tolak
								</button>
								<button
									type="submit"
									className="btn btn-success"
									// disabled={this.state.status.btnForm}
									onClick={(e) => {
										e.preventDefault();
										if (this.state.detailSurat) {
											this.updateSurat(this.state.detailSurat.uuid);
										}
									}}
									style={{ fontWeight: 600 }}
								>
									Terima
								</button>
							</div>
						</Form>
					</Modal>
					{/* Modal penerbitan */}
					<Modal
						open={this.state.status.modalPenerbitan}
						styles={{
							modal: {
								width: '85%'
							}
						}}
						onClose={() => {
							// Tutup modal validasi
							let status = { ...this.state.status };
							status.modalPenerbitan = false;
							this.setState({ status });
						}}
					>
						<Form className="theme-form" onSubmit={this.terbitkanSurat}>
							<div className="modal-header">
								<h5 className="modal-title">Form Penerbitan Izin Usaha Mikro</h5>
							</div>
							<div className="modal-body">
								{this.state.detailSurat ? (
									<div>
										<FormGroup className="row" style={{ marginBottom: '10px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												<b>Nomor Surat Kecamatan</b>
											</Label>
											<div className="col-sm-9">
												<input
													className="form-control"
													id="no_surat_kecamatan"
													type="text"
													placeholder="Nomor Surat Kecamatan"
													defaultValue={this.state.noSuratAuto ? this.state.noSuratKecamatan : this.state.dt.no_surat}
													style={ this.state.noSuratAuto ? { backgroundColor:"#c9d1d8" } : {}}
													disabled={ this.state.noSuratAuto ? true : false }
													required
												/>
												<small id="emailHelp" className="form-text text-muted">
													<a
														href="/"
														onClick={(e) => {
															e.preventDefault()
															this.setState({ noSuratAuto: !this.state.noSuratAuto })
														}}
													>
														Input nomor surat {this.state.noSuratAuto ? "manual" : "otomatis"}
													</a>
												</small>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												<b>Penandatangan Surat</b>
											</Label>
										</FormGroup>
										<div className="row">
											<div className="col-sm-12">
												<table className="table table-bordered table-stripes table-hovered">
													<thead>
														<tr>
															<th>No</th>
															<th>Jabatan</th>
															<th>Nama</th>
															<th>NIP</th>
															<th>Pangkat</th>
															<th>Jabatan</th>
															<th>
																<i className="fa fa-check" />
															</th>
														</tr>
													</thead>
													<tbody>{tableTTD}</tbody>
												</table>
											</div>
										</div>
										{/* <FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Nama Penandatangan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<input id="nama_ttd_surat_kecamatan" type="text" className="form-control" placeholder="Masukkan Nama Penandatangan Surat" required />
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Pangkat Penandatangan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<input id="pangkat_ttd_surat_kecamatan" type="text" className="form-control" placeholder="Masukkan Nama Pangkat Penandatangan Surat" required />
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Jabatan Penandatangan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<input id="jabatan_ttd_surat_kecamatan" type="text" className="form-control" placeholder="Masukkan Nama Jabatan Penandatangan Surat" required />
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												NIP Penandatangan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<input id="nip_ttd_surat_kecamatan" type="text" className="form-control" placeholder="Masukkan NIP Penandatangan Surat" required />
											</div>
										</FormGroup> */}
										
									</div>
								) : (
									''
								)}
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-warning"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										let status = { ...this.state.status };
										status.modalPenerbitan = false;

										this.setState({ status });
									}}
									style={{ fontWeight: 600 }}
								>
									Batal
								</button>
								<button
									type="submit"
									className="btn btn-success"
									// disabled={this.state.status.btnForm}
									style={{ fontWeight: 600 }}
								>
									Terbitkan
								</button>
							</div>
						</Form>
					</Modal>
					{/* Modal Detail Gambar */}
					<Modal
						open={this.state.status.fotoPersyaratan}
						styles={{
							modal: {
								width: '90%'
							}
						}}
						onClose={() => {
							let status = { ...this.state.status };
							status.fotoPersyaratan = false;
							this.setState({ status });
						}}
					>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.titlePersyaratan}</h5>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-md-12">
								{this.state.fotoPersyaratan != '' ? (
									<img
									src={this.state.fotoPersyaratan}
									style={{ width: "100%" }}
								/>
								) : (
									<h6 style={{ textAlign: "center" }}>Tidak ada Foto</h6>
								)}
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-default"
								// disabled={this.state.status.btnForm}
								onClick={() => {
									let status = { ...this.state.status };
									status.fotoPersyaratan = false;
									this.setState({ status });
								}}
								style={{ fontWeight: 600 }}
							>
								Tutup
							</button>
						</div>
					</Modal>
					{/* Modal form penolakan pengajuan */}
					<Modal
						open={this.state.status.tolakValidasi}
						styles={{
							modal: {
								width: '80%'
							}
						}}
						onClose={() => {
							let status = { ...this.state.status };
							status.tolakValidasi = false;
							this.setState({ status });
						}}
					>
						<Form className="theme-form">
							<div className="modal-header">
								<h5 className="modal-title">Penolakan Pengajuan Layanan</h5>
							</div>
							<div className="modal-body">
								<FormGroup className="row" style={{ marginBottom: '0px' }}>
									<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
										Alasan Penolakan
									</Label>
									<div className="col-sm-9" style={{ textAlign: 'center' }}>
										<Input type="textarea" id="alasan" nama="alasan" />
									</div>
								</FormGroup>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-info"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										let status = { ...this.state.status };
										status.tolakValidasi = false;
										this.setState({ status });
									}}
									style={{ fontWeight: 600 }}
								>
									Batal
								</button>
								<button
									type="submit"
									className="btn btn-success"
									// disabled={this.state.status.btnForm}
									onClick={(e) => {
										e.preventDefault();
										this.tolakSurat(this.state.detailSurat.uuid);
									}}
									style={{ fontWeight: 600 }}
								>
									Simpan
								</button>
							</div>
						</Form>
					</Modal>
					
					{/* Modal detail berkas */}
					<Modal
						open={this.state.status.modalDetail}
						styles={{
							modal: {
								width: '75%'
							}
						}}
						onClose={() => {
							let status = { ...this.state.status };
							status.modalDetail = false;
							this.setState({ status });
						}}
					>
						<Form className="theme-form">
							<div className="modal-header">
								<h5 className="modal-title">Form Pengajuan Penerbitan Izin Usaha Mikro</h5>
							</div>
							<div className="modal-body">
								{this.state.detailSurat && this.state.dataPelayanan ? (
									<div>
										<FormGroup className="row" style={{ marginBottom: "5px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-2"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Pelapor</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
                        <h6 className="mb-0">NIK Pelapor</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.detailSurat.nik_pelapor}
												</h6>
											</div>
										</FormGroup>
                    <FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Nama</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.detailSurat.nama_pelapor}
												</h6>
											</div>
										</FormGroup>
                    <FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Nomor HP</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.detailSurat.no_hp_pelapor}
												</h6>
											</div>
										</FormGroup>

										{/* Data Pemilik */}
										<FormGroup className="row" style={{ marginBottom: "5px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-2"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Pemilik</b>
                        </h6>
                      </Label>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">NIK Pemilik</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.nik}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Nama Pemilik</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.nama_pemilik}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Kode POS</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.kode_pos}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">No. Telp</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.telp}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Email</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.email}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">NPWP</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.npwp}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Jenis NPWP</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.jenis_npwp.value}
												</h6>
											</div>
										</FormGroup>

										{/* Data Usaha */}
										<FormGroup className="row" style={{ marginBottom: "5px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-2"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Usaha</b>
                        </h6>
                      </Label>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Nama Usaha</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.nama_usaha}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Alamat Usaha</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.alamat_usaha}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Dusun</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.dusun_usaha ? this.state.dataPelayanan.dusun_usaha.label : '-'}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">RW</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.rw_usaha.label}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">RT</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.rt_usaha.label}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Kode POS</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.kode_pos_usaha}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">No. Telp. Usaha</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.telp_usaha}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Tahun Berdiri</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.tahun_berdiri}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Bentuk Perusahaan</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.bentuk_perusahaan.label}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Jenis Usaha</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.jenis_usaha.label}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Keterangan Jenis Usaha</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.uraian_usaha}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Sarana Usaha</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.sarana_usaha}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Jumlah Karyawan</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.jumlah_karyawan}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Modal Sendiri</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.permodalan_sendiri}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Modal Luar</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.permodalan_luar}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Kategori Omset/Tahun</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.omset.kategori_omset.label}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Nominal Omset/Tahun</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.omset.nominal_omset}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Kategori Aset</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.aset.kategori_aset.label}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Nominal Aset</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.aset.nominal_aset}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Sumber Bahan Baku</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<div className="row" style={{ width: '100%' }}>
													{Object.keys(this.state.dataPelayanan.sumber_bahan_baku).map((bahanBaku, i) => {
														return (
															<div className="col-sm-4">
																<input type="checkbox"
																	checked={this.state.dataPelayanan.sumber_bahan_baku[bahanBaku]}
																	readOnly
																/> {bahanBaku.toUpperCase().replace("_", ' ')}
															</div>
														)
													})}
												</div>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Daerah Pemasaran</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<div className="row" style={{ width: '100%' }}>
													{Object.keys(this.state.dataPelayanan.pemasaran).map((jenis, i) => {
														return (
															<div className="col-sm-4">
																<input type="checkbox"
																	checked={this.state.dataPelayanan.pemasaran[jenis]}
																	readOnly
																/> {jenis.toUpperCase().replace("_", ' ')}
															</div>
														)
													})}
												</div>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Legalitas Usaha</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<div className="row" style={{ width: '100%' }}>
													{Object.keys(this.state.dataPelayanan.legalitas_usaha).map((legalitas, i) => {
														return (
															<div className="col-sm-4">
																<input type="checkbox"
																	checked={this.state.dataPelayanan.legalitas_usaha[legalitas]}
																	readOnly
																/> {legalitas.toUpperCase().replace("_", ' ')}
															</div>
														)
													})}
												</div>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600 }}>
												<h6 className="mb-0">Status Tempat Usaha</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<div className="row" style={{ width: '100%' }}> 
													{Object.keys(this.state.dataPelayanan.status_tempat_usaha).map((status, i) => {
														return (
															<div className="col-sm-4">
																<input type="checkbox"
																	checked={this.state.dataPelayanan.status_tempat_usaha[status]}
																	readOnly
																/> {status.toUpperCase().replace("_", ' ')}
															</div>
														)
													})}
												</div>
											</div>
										</FormGroup>

									</div>
								) : (
									''
								)}
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-info"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										this.tutupForm();
									}}
									style={{ fontWeight: 600 }}
								>
									Tutup
								</button>
							</div>
						</Form>
					</Modal>
				</div>
			</div>
		);
	}
}

export default IzinUsahaMikro;
