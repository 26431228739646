import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";
import { DataToken } from "../../../function/Format";

class MenuSuper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="mb-2 menuu"
        style={{
          display: "flex",
          justifyContent: "space-around",
          borderRadius: "10px",
          width: "100%"
        }}
      >
        <div className="dropdown-basic">
          {/* Dashboard */}
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-success"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="fa fa-home"></i> Dashboard
                </button>
              </div>
            </Link>
          </div>
          
          {/* Data Potensi */}
          {/* <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/admin/data-potensi"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-success"
                  style={{ borderRadius: "10px"}}
                >
                  <i className="fa fa-bar-chart-o"></i> Data Potensi
                </button>
              </div>
            </Link>
          </div> */}
          {/* User */}
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-success"
                style={{ borderRadius: "10px"}}
              >
                <i className="icofont icofont-users-alt-4"></i> User
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link to={process.env.PUBLIC_URL + "/admin/pengguna"}>
                  <i className="fa fa-user"></i> Pengguna
                </Link>
                {/* <Link to={process.env.PUBLIC_URL + "/admin/operator"}>
                  <i className="fa fa-user"></i> Operator
                </Link> */}
              </div>
            </div>
          </div>
          
        
         
        </div>
      </div>
    );
  }
}

export default MenuSuper;
