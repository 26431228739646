import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, Redirect } from 'react-router-dom';
import { Button, CardImg, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import API_URL, { Get, GetMarketplace, Post } from '../../function/Koneksi';
import classnames from "classnames";
import Datetime from 'react-datetime';

import "react-datetime/css/react-datetime.css";
import Select from 'react-select';

let frmDef = {
	nik_pelapor: '',
	nama_pelapor: '',
	jenis_kelamin_pelapor: null,
	agama_pelapor: null,
	status_perkawinan_pelapor: null,
	pekerjaan_pelapor: '',
	alamat_pelapor: '',
	tempat_lahir_pelapor: '',
	tanggal_lahir_pelapor: null,
	no_hp_pelapor: '',
	perihal:{
		izin_usaha: false,
		imb: false,
		siup: false,
		situ: false,
		tdp: false,
	},

	nomor_surat_desa: '',
	tanggal_surat_desa: null,
	nomor_surat_kecamatan: '',
	nama_usaha: '',
	lokasi_pembangunan: '',
	jenis_usaha: '',
	dusun_usaha: '',
	rt_usaha: '',
	rw_usaha: '',
	linkGambar: {
		surat_keterangan_usaha: {
			link: '',
			type: '',
		},
	},
}

const jwt = require('jsonwebtoken');
const dateFormat = require('dateformat');

const dataUser = jwt.decode(sessionStorage.getItem('access_token'));

class RekomendasiIzinUsaha extends Component {
	constructor(props) {
		super(props);
		this.state = {
			idWilayah: dataUser ? dataUser.sub.id_wilayah : null,
			listDesa: [],
			listPerangkat: [],
			selectedDesa: null,
			selectedTTD: null,

			data: [],
			dataDiproses: [],
			dataSelesai: [],
			dataDitolak: [],
			dataDokumenOutput: [],
			dt: frmDef,

			noSuratAuto: false,
			noSuratKecamatan: null,
			detailSurat: null,
			dataPelayanan: null,
			fotoPersyaratan: null,
			titlePersyaratan: '',
			linkGambar: '',
			active_tab_icon: "1",
			pagination: [],
			status: {
				alert: false,
				modalCreate: false,
				modalValidasi: false,
				modalPenerbitan: false,
				modalUpload: false,
				modalDetail: false,
				tolakValidasi: false,
				fotoPersyaratan: false,
				btnForm: false,
				btnAksi: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			// dt:frmDef,
			// ============== Select 2 ==============
			frmTingkat: [],
			tingkatStat: [],
			frmDetail: [],
			detailStat: [],
			judul: 'Tambah Jabatan Perangkat',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			tanggal_surat_kecamatan: new Date(),
			dataUser: jwt.decode(sessionStorage.getItem('access_token'))
		};
	}

	componentDidMount(tab = '1') {

		if (this.props.location.state) {
			console.log(this.props.location.state);
			console.log(this.props.location.akun);
	
			this.fetch({ tab })
		
			this.setState({ idWilayah: this.state.dataUser.sub.id_wilayah })	
	
			let jenisWilayah = this.props.location.state.data_instansi.jenis
			let idJenisLayanan = this.props.location.state.uuid;
	
			// Get nomor surat otomatis
			Get('pelayanan/get-nomor-surat/' + idJenisLayanan, null, (dtkat) => {
				console.log(dtkat);
				if (dtkat.results) {
					this.setState({ 
						noSuratAuto: true,
						noSuratKecamatan: dtkat.results.nomor_surat,
					})
				}
			});
	
			// Get List Desa
			GetMarketplace('kecamatan/list' + jenisWilayah + '/' + this.state.dataUser.sub.id_wilayah, null, (data) => {
				console.log(data);
				let listDesa = [];
	
				if (data.result) {
					data.result.map((val) => {
						listDesa.push({ value: val.id, label: val.nama })
					})
					this.setState({ listDesa })
					console.log(listDesa);
				}
			})
	
			// Get list perangkat
			Get('perangkat/list-instansi/' + jenisWilayah + '/' + this.state.dataUser.sub.id_wilayah, null, (val) => {
				console.log(val);
				if (val.results) {
					this.setState({ listPerangkat: val.results })
				}
			});
			
		}

	}

	fetch = (params = {}) => {
		let link = '';
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '&page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '&page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '&page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '&page=1';
					break;
				default:
					link = '&page=' + params.page;
					break;
			}
		}

		let endPoint = '';
    switch (params.tab) {
      case '1':
        endPoint = 'pelayanan?jenis_layanan=Rekomendasi Izin Usaha&status=Diajukan';
        break;

      case '2':
        endPoint = 'pelayanan?jenis_layanan=Rekomendasi Izin Usaha&status=Diproses';
        break;

      case '3':
        endPoint = 'pelayanan?jenis_layanan=Rekomendasi Izin Usaha&status=Selesai';
        break;

      case '4':
        endPoint = 'pelayanan?jenis_layanan=Rekomendasi Izin Usaha&status=Ditolak';
        break;

      default:
        break;
    }

		// Get data from db
		Get(endPoint + link, null, (dtkat) => {
			if (dtkat.results.data) {
				switch (params.tab) {
					case '1':
						this.setState({
							data: dtkat.results.data,
							loading: false,
							pagination: dtkat.results
						});
						break;
	
					case '2':
						this.setState({
							dataDiproses: dtkat.results.data,
							loading: false,
							pagination: dtkat.results
						});
						break;
	
					case '3':
						this.setState({
							dataSelesai: dtkat.results.data,
							loading: false,
							pagination: dtkat.results
						});
						break;
	
					case '4':
						this.setState({
							dataDitolak: dtkat.results.data,
							loading: false,
							pagination: dtkat.results
						});
						break;
				}
				this.pagination(dtkat.results, params.tab);
			}
		});
	};

	pagination = (data, tab) => {
		// const data = this.state.pagination;
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						key={i}
						onClick={() => this.fetch({ page: i, tab: tab })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p, tab: tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n, tab: tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}

			awal = (
				<a
					onClick={() => this.fetch({ page: 1, tab: tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page, tab: tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	tombolAksi = (cell, value) => {
		
		const btnDetail = (
      <Button
        size="sm"
        color="info"
        className="btn-square"
        onClick={() => {
          let status = { ...this.state.status };
          // status.modalDetail = true;
          status.modalCreate = true;
          this.setState({
						judul: 'Detail Surat Rekomendasi Izin Usaha',
            status,
            detailSurat: value,
            dataPelayanan: JSON.parse(value.data_pelayanan),
            // dataPelapor: JSON.parse(value.data_pelapor),
						// dataDokumenOutput: JSON.parse(value.dokumen_output)
          });
					console.log(value);
          console.log(JSON.parse(value.data_pelayanan));
          // console.log(JSON.parse(value.data_pelayanan));
          // console.log(JSON.parse(value.data_pelapor));
        }}
        style={{ 
					padding: "0.25rem 0.5rem", 
					borderRadius: "50%",
					width: "30px",
					height: "30px",
				}}
				title="Detail"
      >
        <i className="fa fa-eye"></i>
        {/* Detail */}
      </Button>
    )

		if (value.data_status.length > 0) {
			if (value.data_status_baru.status == 'Diajukan') {
				return (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
						{btnDetail}
						{/* Btn Edit */}
						<Button
							size="sm"
							color="success"
							className="btn-square"
							onClick={() => {
								let status = { ...this.state.status };
								status.modalCreate = true;

								let dataPelayanan = JSON.parse(value.data_pelayanan)
								console.log(value);
								console.log(dataPelayanan);

								let linkGambar = {}
								Object.keys(dataPelayanan.linkGambar).map((attribute) => {
									let type = dataPelayanan.linkGambar[attribute].split('.')
									linkGambar[attribute] = {
										link: dataPelayanan.linkGambar[attribute],
										type: type[type.length - 1],
									}
								})
								console.log(linkGambar);

								this.setState({
									judul: "Edit Surat Rekomendasi Usaha",
									status,
									detailSurat: value,
									dataPelayanan: null,
									selectedDesa: dataPelayanan.id_desa,
									dt: {
										nik_pelapor: value.nik_pelapor,
										nama_pelapor: value.nama_pelapor,
										jenis_kelamin_pelapor: {
											value: dataPelayanan.jenis_kelamin,
											label: dataPelayanan.jenis_kelamin,
										},
										agama_pelapor: {
											value: dataPelayanan.agama,
											label: dataPelayanan.agama,
										},
										status_perkawinan_pelapor: {
											value: dataPelayanan.status_perkawinan,
											label: dataPelayanan.status_perkawinan,
										},
										pekerjaan_pelapor: dataPelayanan.pekerjaan,
										alamat_pelapor: value.alamat_pelapor,
										tempat_lahir_pelapor: dataPelayanan.tempat_lahir,
										tanggal_lahir_pelapor: new Date(dataPelayanan.tanggal_lahir),
										no_hp_pelapor: value.no_hp_pelapor,
										perihal: dataPelayanan.perihal,

										nomor_surat_desa: dataPelayanan.nomor_surat_desa,
										tanggal_surat_desa: new Date(dataPelayanan.tanggal_surat_desa),
										nomor_surat_kecamatan: dataPelayanan.nomor_surat_kecamatan,
										nama_usaha: dataPelayanan.nama_usaha,
										lokasi_pembangunan: dataPelayanan.lokasi_lokasi_pembangunan,
										jenis_usaha: dataPelayanan.jenis_usaha,
										dusun_usaha: dataPelayanan.lokasi_usaha.dusun.value,
										rt_usaha: dataPelayanan.lokasi_usaha.rt.value,
										rw_usaha: dataPelayanan.lokasi_usaha.rw.value,
										// linkGambar: dataPelayanan.linkGambar ? dataPelayanan.linkGambar : this.state.dt.linkGambar
										linkGambar
									}
								});
								console.log(this.state.dt);
								console.log(value);
								console.log(JSON.parse(value.data_pelayanan));
							}}
							style={{ 
								padding: "0.25rem 0.5rem", 
								borderRadius: "50%",
								width: "30px",
								height: "30px",
							}}
							title="Edit"
						>
							<i className="fa fa-pencil"></i>
						</Button>
						{/* Btn Penerbitan */}
						<Button
							size="sm"
							color="warning"
							className="btn-square"
							onClick={() => {
								this.setState({ 
									status: {...this.state.status, modalPenerbitan: true},
									detailSurat: value,
									dataPelayanan: JSON.parse(value.data_pelayanan),
									selectedTTD: null
								});
							}}
							style={{ borderRadius: "25px" }}
						>
							Terbitkan
						</Button>
					</div>
				);
			} else if (value.data_status_baru.status == 'Diproses') {
				return (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
						{btnDetail}
						<Button
							size="sm"
							color="success"
							className="btn-square"
							onClick={() => {
								// let status = { ...this.state.status };
								// status.modalUpload = true;
								// this.setState({ status, detailSurat: value });
								this.setState({ 
									status: {...this.state.status, modalPenerbitan: true},
									detailSurat: value,
									dataPelayanan: JSON.parse(value.data_pelayanan),
									// alert : (
									// 	<SweetAlert
									// 		showCancel
									// 		confirmBtnText="Ya, saya yakin"
									// 		confirmBtnBsStyle="warning"
									// 		confirmBtnStyle={{ fontWeight: 600 }}
									// 		cancelBtnText="Batal"
									// 		cancelBtnBsStyle="default"
									// 		cancelBtnStyle={{ fontWeight: 600 }}
									// 		type="warning"
									// 		title="Apakah anda yakin surat ini telah selesai ?"
									// 		onCancel={() => {
									// 			this.setState({ alert: null });
									// 		}}
									// 		onConfirm={() => {
									// 			this.tutupForm();

									// 			let bodyFormData = {
									// 				status: "Selesai",
									// 				dokumen_output: '',
									// 			};
									
									// 			Post(
									// 				"pelayanan/verifikasi-status-kecamatan",
									// 				this.state.detailSurat.uuid,
									// 				bodyFormData,
									// 				(data) => {
									// 					console.log(data);
									// 					if (data.status == 200) {
									// 						let status = { ...this.state.status };
									// 						status.alert = true;
									// 						this.setState({
									// 							status,
									// 							basicType: "success",
									// 							basicTitle: "Penerbitan Surat",
									// 							pesanAlert: "Berhasil menerbitkan surat",
									// 						});
									// 						this.componentDidMount(this.state.active_tab_icon);
									// 					} else {
									// 						let status = { ...this.state.status };
									// 						status.alert = true;
									// 						this.setState({
									// 							status,
									// 							basicType: "danger",
									// 							basicTitle: "Penerbitan Surat",
									// 							pesanAlert: "Gagal menerbitkan surat",
									// 						});
									// 					}
									// 				}
									// 			);
									// 		}}
									// 	>
									// 		Proses ini tidak dapat dibatalkan, pastikan sudah memeriksa seluruh data dengan teliti
									// 	</SweetAlert>
									// )
								});
							}}
							style={{ padding: '0.25rem 0.5rem', borderRadius: "25px" }}
						>
							Terbitkan
						</Button>
					</div>
				);
			} else if (value.data_status_baru.status == "Selesai") {
				return (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
						{btnDetail}
						<a
							href={ API_URL + 'cetak-surat/surat-rekomendasi-usaha/' + value.uuid }
							target="blank"
						>
							<Button
								size="sm"
								color="success"
								className="btn-square"
								style={{ padding: "0.25rem 0.5rem", borderRadius: "25px" }}
							>
								<span style={{ color: 'white' }}>Cetak</span>
							</Button>
						</a>
					</div>
				);
			} else if (value.data_status_baru.status == "Ditolak") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {btnDetail}
          </div>
        );
      }
		} else {
			return '';
		}
	};

	tutupForm() {
		let status = { ...this.state.status };
		status.modalCreate = false;
		status.modalValidasi = false;
		status.modalPenerbitan = false;
		status.tolakValidasi = false;
		status.modalUpload = false;
		status.modalDetail = false;
		status.fotoPersyaratan = false;

		this.setState({
			status,
			alert: null,
			detailSurat: null,
			dataPelayanan: null,
			dataDokumenOutput: null,
			// linkGambar: listFile,
			dt: frmDef
		});
	}

	hideAlert = () => {
		let status = { ...this.state.status };
		status.alert = false;
		this.setState({ status });
	};

	terbitkanSurat = (e) => {
		e.preventDefault()

		if (!this.state.selectedTTD) {
			this.setState({
				status: {...this.state.status, alert: true},
				basicType: 'warning',
				basicTitle: 'Data Rekomendasi Usaha',
				pesanAlert: 'Pilih penandatangan terlebih dahulu'
			});
		} else {
			let nomor_surat_kecamatan = document.getElementById('no_surat_kecamatan').value

			let dataTTD = this.state.selectedTTD

			let dataPelayanan = this.state.dataPelayanan
			dataPelayanan = {...this.state.dataPelayanan, 
				nomor_surat_kecamatan,
				nama_ttd_surat_kecamatan: dataTTD.nama,
				pangkat_ttd_surat_kecamatan: dataTTD.pangkat,
				jabatan_ttd_surat_kecamatan: dataTTD.jabatan,
				nip_ttd_surat_kecamatan: dataTTD.nip,
				tanggal_surat_kecamatan: dateFormat(new Date(), 'yyyy-mm-dd'),
			}
			
			let bodyFormData = {
				status: 'Selesai',
				data_pelayanan: JSON.stringify(dataPelayanan)
			};
			console.log(bodyFormData);

			Post('pelayanan/verifikasi-status-kecamatan', this.state.detailSurat.uuid, bodyFormData, (data) => {
				this.tutupForm();
				console.log(data);
				if (data.status == 200) {
					let status = { ...this.state.status };
					status.alert = true;
					this.setState({
						status,
						basicType: 'success',
						basicTitle: 'Penerbitan Surat Kecamatan',
						pesanAlert: 'Berhasil menerbitkan surat'
					});

					if (this.state.noSuratAuto) {
						let idJenisLayanan = this.props.location.state.uuid;
						Get('pelayanan/set-nomor-surat/' + idJenisLayanan, null, (dtkat) => {
							console.log(dtkat);
							if (dtkat.results) {
								this.setState({ noSuratKecamatan: dtkat.results.nomor_surat })
							}
						});
					}

					this.componentDidMount(this.state.active_tab_icon);
				} else {
					let status = { ...this.state.status };
					status.alert = true;
					this.setState({
						status,
						basicType: 'danger',
						basicTitle: 'Penerbitan Surat Kecamatan',
						pesanAlert: 'Gagal menerbitkan surat'
					});
				}
				// this.tutupForm();
			});

		}
	}

	simpan = (e) => {
		e.preventDefault()

		// Check select value
		if (this.state.dt.jenis_kelamin_pelapor && 
			this.state.dt.agama_pelapor && 
			this.state.dt.status_perkawinan_pelapor && 
			this.state.selectedDesa ) {
			// Cek persyaratan 
			let emptyGambar = false
			Object.keys(this.state.dt.linkGambar).map((attribute) => {
				if(this.state.dt.linkGambar[attribute].link  == ''){
					emptyGambar = true
				}
			})
			// Check perihal izin usaha
			if (this.state.dt.perihal.izin_usaha == false &&
				this.state.dt.perihal.imb == false &&
				this.state.dt.perihal.siup == false &&
				this.state.dt.perihal.situ == false &&
				this.state.dt.perihal.tdp == false ) {
					this.setState({
            // show: true,
						status: {...this.state.status, alert: true},
            basicType: 'warning',
            basicTitle: 'Data Rekomendasi Usaha',
            pesanAlert: 'Pilih Perihal Pembuatan Surat Terlebih Dahulu'
          });
			} else if (emptyGambar) {
				this.setState({
					// show: true,
					status: {...this.state.status, alert: true},
					basicType: 'warning',
					basicTitle: 'Data Rekomendasi Usaha',
					pesanAlert: 'Upload Bukti Persyaratan Terlebih Dahulu'
				});
			} else {

				let uuid = this.state.detailSurat ? this.state.detailSurat.uuid : null

				let lokasi_lokasi_pembangunan = this.state.dt.perihal.imb ? document.getElementById('lokasi_pembangunan').value : ''

				let linkGambar = {};
				Object.keys(this.state.dt.linkGambar).map((attribute) => {
					linkGambar[attribute] = this.state.dt.linkGambar[attribute].link
				})

				let detail = {
					nomor_surat_desa: document.getElementById('nomor_surat_desa').value,
					tanggal_surat_desa: this.state.dataPelayanan ? this.state.dataPelayanan.tanggal_surat_desa : dateFormat(this.state.dt.tanggal_surat_desa, 'yyyy-mm-dd'),
					nomor_surat_kecamatan: this.state.dataPelayanan ? this.state.dataPelayanan.nomor_surat_kecamatan : this.state.dt.nomor_surat_kecamatan,
					// tanggal_surat_kecamatan: dateFormat(new Date(), 'yyyy-mm-dd'),
					tanggal_surat_kecamatan: '',
					perihal: this.state.dt.perihal,
					tempat_lahir: document.getElementById('tempat_lahir_pelapor').value,
					tanggal_lahir: dateFormat(this.state.dt.tanggal_lahir_pelapor, 'yyyy-mm-dd') ,
					jenis_kelamin: this.state.dt.jenis_kelamin_pelapor.value,
					agama: this.state.dt.agama_pelapor.value,
					status_perkawinan: this.state.dt.status_perkawinan_pelapor.value,
					pekerjaan: document.getElementById('pekerjaan_pelapor').value,
					nama_usaha: document.getElementById('nama_usaha').value,
					jenis_usaha: document.getElementById('jenis_usaha').value,
					lokasi_usaha: {
						dusun: {
							value: document.getElementById('dusun_usaha').value,
							label: document.getElementById('dusun_usaha').value
						},
						rt: {
							value: document.getElementById('rt_usaha').value,
							label: document.getElementById('rt_usaha').value
						},
						rw: {
							value: document.getElementById('rw_usaha').value,
							label: document.getElementById('rw_usaha').value
						},
					},
					jenis_desa: 'Desa',
					id_desa: {
						value: this.state.selectedDesa.value,
						label: this.state.selectedDesa.label
					},
					nama_desa: this.state.selectedDesa.label,
					lokasi_lokasi_pembangunan,
					nama_ttd: null,
					pangkat_ttd: null,
					nip_ttd: null,
					// linkGambar: this.state.dt.linkGambar,
					linkGambar,
				}
				console.log(detail);

				let bodyRaw = { 
					uuid,
          id_desa: this.state.selectedDesa.value,
          nik_pelapor: document.getElementById('nik_pelapor').value,
          nama_pelapor: document.getElementById('nama_pelapor').value,
          pekerjaan_pelapor: document.getElementById('pekerjaan_pelapor').value,
          alamat_pelapor: document.getElementById('alamat_pelapor').value,
          no_hp_pelapor: document.getElementById('no_hp_pelapor').value,
          tgl_melapor: this.state.dataPelayanan ? this.state.detailSurat.tgl_melapor : dateFormat(new Date(), 'yyyy-mm-dd'),
          data_pelayanan: JSON.stringify(detail),
          dokumen_input: '',
          dokumen_output: '',
          jenis_layanan: "Rekomendasi Izin Usaha",
          uuid_surat_desa: uuid,
        }
				console.log(bodyRaw);

				let psn = "";
        let resstat = 204;
        let metode = "create";
        if (uuid == "" || uuid == null) {
          psn = "Tambah";
          resstat = 201;
          bodyRaw.uuid = null;
        } else {
					psn = "Ubah";
          resstat = 200;
          metode = "update";
        }

				Post(
          "pelayanan/" + metode,
          uuid,
          bodyRaw,
          res => {
            this.tutupForm();
            this.state.status.btnForm = false;
            this.state.status.form = false;
            this.forceUpdate();

            console.log("Respon Simpan")
            console.log(res)
            if (res.status === resstat) {
              this.setState({
                showBio: false,
                show: true,
                status: {...this.state.status, alert: true},
                basicType: "success",
                basicTitle: "Data Rekomendasi Usaha",
                pesanAlert: "Berhasil " + psn + " Rekomendasi Usaha",
                // successAlert: true,
              });
              this.componentDidMount();
            } else {
              this.setState({
                showBio: false,
                show: true,
								status: {...this.state.status, alert: true},
                basicType: "danger",
                basicTitle: "Data Rekomendasi Usaha",
                pesanAlert: "Gagal " + psn + " Rekomendasi Usaha"
              });
            }
          }
        );

			}
		} else {
			this.setState({
				// show: true,
				status: {...this.state.status, alert: true},
				basicType: 'warning',
				basicTitle: 'Data Rekomendasi Usaha',
				pesanAlert: 'Isi seluruh data yang diperlukan dengan baik dan benar'
			});
		}
	}

	updateSurat = (uuid_surat) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Ya, saya yakin"
					confirmBtnBsStyle="warning"
					confirmBtnStyle={{ fontWeight: 600 }}
					cancelBtnText="Batal"
					cancelBtnBsStyle="default"
					cancelBtnStyle={{ fontWeight: 600 }}
					type="warning"
					title="Apakah anda yakin syarat yang diberikan telah sesuai ?"
					onCancel={() => {
						this.setState({ alert: null });
					}}
					onConfirm={() => {
						if (this.state.detailSurat) {
							let bodyFormData = {
								status: 'Diproses'
							};
							Post('pelayanan/verifikasi-status-kecamatan', this.state.detailSurat.uuid, bodyFormData, (data) => {
								console.log(data);
								if (data.status == 200) {
									let status = { ...this.state.status };
									status.alert = true;
									this.setState({
										status,
										basicType: 'success',
										basicTitle: 'Validasi Pengajuan Pelayanan',
										pesanAlert: 'Berhasil validasi data'
									});
									this.componentDidMount(this.state.active_tab_icon);
								} else {
									let status = { ...this.state.status };
									status.alert = true;
									this.setState({
										status,
										basicType: 'danger',
										basicTitle: 'Validasi Pengajuan Pelayanan',
										pesanAlert: 'Gagal validasi data'
									});
								}
								this.tutupForm();
							});
						}
					}}
				>
					Proses ini tidak dapat dibatalkan, pastikan sudah memeriksa seluruh persyaratan dengan teliti
				</SweetAlert>
			)
		});
	};

	tolakSurat = (uuid_surat) => {
		let alasan = document.getElementById('alasan').value;
		if (alasan == '') {
			let status = { ...this.state.status };
			status.alert = true;

			this.setState({
				status,
				basicType: 'warning',
				basicTitle: 'Penolakan Pengajuan Layanan',
				pesanAlert: 'Isikan alasan penolakan terlebih dahulu'
			});
		} else {
			this.setState({
				alert: (
					<SweetAlert
						showCancel
						confirmBtnText="Ya, saya yakin"
						confirmBtnBsStyle="warning"
						confirmBtnStyle={{ fontWeight: 600 }}
						cancelBtnText="Batal"
						cancelBtnBsStyle="default"
						cancelBtnStyle={{ fontWeight: 600 }}
						type="warning"
						title="Apakah anda yakin ingin menolak pengajuan layanan ini ?"
						onCancel={() => {
							this.setState({ alert: null });
						}}
						onConfirm={() => {
							if (this.state.detailSurat) {
								let bodyFormData = {
									status: 'Ditolak',
									keterangan: alasan
								};
								Post(
									'pelayanan/verifikasi-status-kecamatan',
									uuid_surat,
									bodyFormData,
									(data) => {
										console.log(data);
										if (data.status == 200) {
											let status = { ...this.state.status };
											status.alert = true;
											this.setState({
												status,
												basicType: 'success',
												basicTitle: 'Validasi Pengajuan Pelayanan',
												pesanAlert: 'Berhasil melakukan penolakan pengajuan layanan'
											});
											this.componentDidMount(this.state.active_tab_icon);
										} else {
											let status = { ...this.state.status };
											status.alert = true;
											this.setState({
												status,
												basicType: 'danger',
												basicTitle: 'Validasi Pengajuan Pelayanan',
												pesanAlert: 'Gagal melakukan penolakan pengajuan layanan'
											});
										}
										this.tutupForm();
									}
								);
							}
						}}
					>
						Proses ini tidak dapat dibatalkan, pastikan anda sudah teliti dalam melakukan proses penolakan
						pengajuan
					</SweetAlert>
				)
			});
			// this.tutupForm()
		}
	};

	onFileChange = (event, attribute) => {
    const file = event.target.files[0];
    const fileType = file.type.split('/')
    const type = fileType[1]

		// Disable tombol selesai sampai file berhasil diupload
		this.setState({ status: {...this.state.status, btnForm: true} })
    
    // if (type == "pdf") {
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
      let bodyFormData = new FormData();
      bodyFormData.append("files", file);

			let folder = 'img'
			if (type == "pdf") {
				folder = "pdf"
			}
  
      Post("upload-file/" + folder, null, bodyFormData, (response) => {
        const link = response.data.results
        let linkGambar = {...this.state.dt.linkGambar};

        linkGambar[attribute] = { link, type: folder }
  
        this.setState({ 
					dt: {...this.state.dt, linkGambar},
					status: {...this.state.status, btnForm: false}
				});
      });
    } else {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Upload file",
        pesanAlert: "Format file harus berupa jpg/jpeg/png/pdf",
      });
      // document.getElementById("file-upload").value = null
    }

  };

	uploadFiles = (e) => {
    e.preventDefault()

    let isUploadEnable = true
    // Cek upload button
    Object.keys(this.state.linkGambar).map((attribute) => {
      isUploadEnable = isUploadEnable && this.state.linkGambar[attribute] != ''
    })

    if (isUploadEnable) {
      let bodyFormData = {
        status: "Selesai",
        dokumen_output: this.state.linkGambar,
      };

      Post(
        "pelayanan/verifikasi-status-kecamatan",
        this.state.detailSurat.uuid,
        bodyFormData,
        (data) => {
          console.log(data);
          if (data.status == 200) {
            let status = { ...this.state.status };
            status.alert = true;
            this.setState({
              status,
              basicType: "success",
              basicTitle: "Upload Berkas",
              pesanAlert: "Berhasil upload berkas",
            });
            this.componentDidMount(this.state.active_tab_icon);
          } else {
            let status = { ...this.state.status };
            status.alert = true;
            this.setState({
              status,
              basicType: "danger",
              basicTitle: "Upload Berkas",
              pesanAlert: "Gagal upload berkas",
            });
          }
          this.tutupForm();
        }
      );
    } else {
      let status = { ...this.state.status };
      status.alert = true;

      this.setState({
        status,
        basicType: "warning",
        basicTitle: "Upload berkas",
        pesanAlert: "Pilih berkas terlebih dahulu",
      });
    }
  };

	toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
			this.setState({ active_tab_icon: tab });
			this.fetch({ tab })
    }
  }

	render() {
		if (this.props.location.state == undefined) {
      return <Redirect to="/" />;
    }
		
		var data = this.state.data;

		switch (this.state.active_tab_icon) {
      case "1":
        data = this.state.data
        break;

      case "2":
        data = this.state.dataDiproses
        break;

      case "3":
        data = this.state.dataSelesai
        break;

      case "4":
        data = this.state.dataDitolak
        break;
    }

		const columns = [
			{
				// dataField: 'tgl_melapor',
				dataField: 'create_at',
				text: 'Tanggal Melapor',
				formatter: (cell, obj) => {
          let date = new Date(obj.create_at)

          let dateformat = [date.getDate(),
                            date.getMonth()+1,
                            date.getFullYear()].join('-')+' '+
                           [date.getHours(),
                            date.getMinutes(),
                            date.getSeconds()].join(':');
          return dateformat;
        },
				sort: false
			},
			{
				dataField: 'jenis_layanan',
				text: 'Layanan',
				sort: true
			},
			{
				dataField: 'nik_pelapor',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'nama_pelapor',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'data_status_baru.status',
				text: 'status',
				sort: true
				// formatter: (value)=> {
				//   return value[0]
				// }
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];

		let bootstrapTable = (
			<div>
				<BootstrapTable keyField="id" data={data} columns={columns} />
				{data.length != 0 ? (
          <div className="pull-right text-white">
            {this.state.awal}
            {this.state.sebelum}
            {this.state.hal.map(dt => {
              return dt;
            })}
            {this.state.setelah}
            {this.state.akhir}
          </div>
        ) : ''}
			</div>
		)

		let uploadButton = true
    // Cek upload button
    Object.keys(this.state.linkGambar).map((attribute) => {
      uploadButton = uploadButton && this.state.linkGambar[attribute] != ''
    })

		let no = 0;
		let tableTTD = this.state.listPerangkat.map((dt, i) => {
			no++;
			return (
				<tr key={i}>
					<td>{no}</td>
					<td>{dt.jabatan}</td>
					<td>{dt.nama}</td>
					<td>{dt.nip ? dt.nip : '-'}</td>
					<td>{dt.pangkat}</td>
					<td>{dt.jabatan}</td>
					<td>
						<Button
							size="xs"
							onClick={() => {
								this.setState({ selectedTTD: {...dt, no: i} })
								console.log(this.state.selectedTTD);
							}}
							color={
								this.state.selectedTTD && this.state.selectedTTD.no == i ? (
									'danger'
								) : (
									'default'
								)
							}
							className="btn-icon"
						>
							{this.state.selectedTTD && this.state.selectedTTD.no == i ? (
								'Dipilih'
							) : (
								'Pilih'
							)}
						</Button>
					</td>
				</tr>
			);
		});
		if (this.state.listPerangkat.length < 1) {
			tableTTD = (
				<tr>
					<td colSpan={7} style={{ textAlign: "center" }}>Belum ada data perangkat pada instansi terkait, silahkan hubungi admin</td>
				</tr>
			)
		}

		return (
			<div>
				<SweetAlert
					show={this.state.status.alert}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={() => {
						this.setState({ status: {...this.state.status, alert: false} })
					}}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12 p-0 m-0">
							{/* <div className="card col-lg-12 p-10 bg-light">
								<div className="row" style={{ marginBottom: '10px' }}>
									<div className="col-sm-12 mb-0" style={{ color: 'black' }}>
										<h6 style={{ marginBottom: "0px", fontWeight: 400 }}>Penerbitan Rekomendasi Izin Usaha</h6>
									</div>
								</div>
							</div> */}
							<div className="card col-lg-12 p-10">
								<div className="card-header" style={{ padding: '20px' }}>
									<div className="row">
										<div className="col-sm-6 text-left">
											<h6 style={{ color: 'black', fontSize: '17px' }} className="mb-0">
												Data Pengajuan Penerbitan Rekomendasi Izin Usaha
											</h6>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react" style={{ paddingTop: '15px' }}>
									<div className="row" style={{ marginBottom: "15px" }}>
										<div className="col-sm-12" style={{ paddingLeft: "0px" }}>
											<Button
												color="success"
												className="btn btn-secondary"
												onClick={() => {
													this.setState({ 
														judul: 'Data Rekomendasi Usaha',
														status: {...this.state.status, modalCreate: true},
														detailSurat: null,
														dataPelayanan: null,
														selectedDesa: null,
														dt: frmDef
													})
												}}
											>
												Tambah Surat
											</Button>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-12 p-0">
											<Nav tabs className="tabs-color">
												<NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "1"
														})}
														onClick={() => {
															this.toggle_icon("1");
														}}
													>
														Diajukan
													</NavLink>
												</NavItem>
												{/* <NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "2"
														})}
														onClick={() => {
															this.toggle_icon("2");
														}}
													>
														Diproses
													</NavLink>
												</NavItem> */}
												<NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "3"
														})}
														onClick={() => {
															this.toggle_icon("3");
														}}
													>
														Selesai
													</NavLink>
												</NavItem>
												{/* <NavItem style={{ cursor: "pointer" }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === "4"
														})}
														onClick={() => {
															this.toggle_icon("4");
														}}
													>
														Ditolak
													</NavLink>
												</NavItem> */}
											</Nav>
											<TabContent activeTab={this.state.active_tab_icon}>
												<TabPane tabId="1">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "1" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="2">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "2" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="3">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "3" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId="4">
													<div style={{ marginTop: "15px" }}>
														<div className="row">
															<div className="col-sm-12">
																{this.state.active_tab_icon == "4" ? bootstrapTable : ''}
															</div>
														</div>
													</div>
												</TabPane>
											</TabContent>
										</div>
									</div>
									<div className="row" style={{ marginTop: "10px" }}>
										<div className="col-sm-12">
											<Link to="/admin/dashboard">Kembali ke dashboard</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Modal Create */}
					<Modal
						open={this.state.status.modalCreate}
						closeOnEsc={false}
						closeOnOverlayClick={true}
						onClose={() => { this.tutupForm() }}
						styles={{ modal: { width: "100%" } }}
					>
						<Form className="theme-form" onSubmit={this.simpan}>
							<div className="modal-header" align="center">
								<h5 className="modal-title">{this.state.judul}</h5>
							</div>

							<div className="modal-body">
								
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>{this.state.detailSurat ? 'Desa' : 'Pilih Desa'}</b>
									</Label>
									<div className="col-sm-8">
										{this.state.detailSurat && this.state.dataPelayanan ? (
											<input
												className="form-control"
												style={{
													fontWeight: 400,
													fontSize: 'inherit',
													backgroundColor:"#c9d1d8"
												}}
												defaultValue={this.state.dataPelayanan.id_desa ? this.state.dataPelayanan.id_desa.label : ''}
												disabled
												type="text"
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ selectedDesa: e })
												}}
												defaultValue={this.state.selectedDesa}
												value={this.state.selectedDesa}
												name="penduduk"
												options={this.state.listDesa}
												placeholder="Pilih Desa"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										No. Surat Desa
									</Label>
									<div className="col-sm-8">
										<input
											className="form-control"
											id="nomor_surat_desa"
											type="text"
											placeholder="Nomor Surat Desa"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.nomor_surat_desa : this.state.dt.nomor_surat_desa}
											style={this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Tanggal Surat Desa
									</Label>
									<div className="col-sm-8">
										{this.state.dataPelayanan ? (
											<input
												className="form-control"
												type="text"
												defaultValue={this.state.dataPelayanan.tanggal_surat_desa}
												style={{ backgroundColor:"#c9d1d8" }}
												disabled
											/>
										) : (
											<Datetime
												timeFormat={false}
												dateFormat="DD/MM/yyyy"
												input={true}
												closeOnSelect={true}
												initialValue={this.state.dt.tanggal_surat_desa}
												value={this.state.dt.tanggal_surat_desa}
												onChange={(e) => {
													console.log(e);
													if (typeof e._isValid != 'undefined') {
														let tgl = e.format('YYYY-MM-DD');
														this.setState({ dt: {...this.state.dt, tanggal_surat_desa: new Date(tgl)}  })
													}
												}}
												inputProps={{ 
													required: true,
													placeholder: 'Tanggal Surat Desa',
													style: { width: '100%' }
												 }}
											/>
										)}
									</div>
								</FormGroup>
								{/* Data Pelapor */}
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Data Pelapor</b>
									</Label>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>NIK Pelapor</b>
									</Label>
									<div className="col-sm-8">
										<input
											className="form-control"
											id="nik_pelapor"
											type="text"
											placeholder="NIK Pelapor"
											defaultValue={this.state.detailSurat ? this.state.detailSurat.nik_pelapor : this.state.dt.nik_pelapor}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Nama
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="nama_pelapor"
											type="text"
											placeholder="Nama Pelapor"
											// value={this.state.dt.nama}
											defaultValue={this.state.detailSurat ? this.state.detailSurat.nama_pelapor : this.state.dt.nama_pelapor}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Tempat Lahir
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="tempat_lahir_pelapor"
											type="text"
											placeholder="Tempat Lahir Pelapor"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.tempat_lahir : this.state.dt.tempat_lahir_pelapor}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Tanggal Lahir
									</Label>
									<div className="col-sm-8">
										{this.state.detailSurat && this.state.dataPelayanan ? (
											<input
												className="form-control"
												type="text"
												defaultValue={this.state.dataPelayanan.tanggal_lahir}
												style={{ backgroundColor:"#c9d1d8" }}
												disabled
											/>
										) : (
											<Datetime
												timeFormat={false}
												dateFormat="DD/MM/yyyy"
												input={true}
												closeOnSelect={true}
												initialValue={this.state.dt.tanggal_lahir_pelapor}
												value={this.state.dt.tanggal_lahir_pelapor}
												onChange={(e) => {
													console.log(e);
													if (typeof e._isValid != 'undefined') {
														let tgl = e.format('YYYY-MM-DD');
														this.setState({ dt: {...this.state.dt, tanggal_lahir_pelapor: new Date(tgl)}  })
													}
												}}
												inputProps={{ 
													required: true,
													placeholder: 'Tanggal Lahir Pelapor',
													style: { width: '100%' }
												 }}
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										No. HP Pelapor
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="no_hp_pelapor"
											type="text"
											placeholder="No. HP Pelapor"
											// value={this.state.dt.no_hp_pelapor}
											defaultValue={this.state.detailSurat ? this.state.detailSurat.no_hp_pelapor : this.state.dt.no_hp_pelapor}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Jenis Kelamin Pelapor
									</Label>
									<div className="col-sm-8">
										{this.state.detailSurat && this.state.dataPelayanan ? (
											<input
												className="form-control"
												type="text"
												defaultValue={this.state.dataPelayanan.jenis_kelamin}
												style={{ backgroundColor:"#c9d1d8" }}
												disabled
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, jenis_kelamin_pelapor: e} })
												}}
												defaultValue={this.state.dt.jenis_kelamin_pelapor}
												value={this.state.dt.jenis_kelamin_pelapor}
												name="penduduk"
												options={[
													{value: 'Laki-Laki', label: 'Laki-Laki'},
													{value: 'Perempuan', label: 'Perempuan'},
												]}
												placeholder="Pilih Jenis Kelamin Pelapor"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Agama Pelapor
									</Label>
									<div className="col-sm-8">
										{this.state.detailSurat && this.state.dataPelayanan ? (
											<input
												className="form-control"
												type="text"
												defaultValue={this.state.dataPelayanan.agama}
												style={{ backgroundColor:"#c9d1d8" }}
												disabled
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, agama_pelapor: e} })
												}}
												defaultValue={this.state.dt.agama_pelapor}
												value={this.state.dt.agama_pelapor}
												name="penduduk"
												options={[
													{value: 'Islam', label: 'Islam'},
													{value: 'Kristen Protestan', label: 'Kristen Protestan'},
													{value: 'Katolik', label: 'Katolik'},
													{value: 'Hindu', label: 'Hindu'},
													{value: 'Buddha', label: 'Buddha'},
													{value: 'Kong Hu Cu', label: 'Kong Hu Cu'},
												]}
												placeholder="Pilih Agama Pelapor"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Status Perkawinan Pelapor
									</Label>
									<div className="col-sm-8">
										{this.state.detailSurat && this.state.dataPelayanan ? (
											<input
												className="form-control"
												type="text"
												defaultValue={this.state.dataPelayanan.status_perkawinan}
												style={{ backgroundColor:"#c9d1d8" }}
												disabled
											/>
										) : (
											<Select
												classNamePrefix="select"
												onChange={e => {
													console.log(e);
													this.setState({ dt: {...this.state.dt, status_perkawinan_pelapor: e} })
												}}
												defaultValue={this.state.dt.status_perkawinan_pelapor}
												value={this.state.dt.status_perkawinan_pelapor}
												name="penduduk"
												options={[
													{value: 'Belum kawin', label: 'Belum kawin'},
													{value: 'Kawin Tercatat', label: 'Kawin Tercatat'},
													{value: 'Kawin Belum Tercatat', label: 'Kawin Belum Tercatat'},
													{value: 'Cerai hidup', label: 'Cerai hidup'},
													{value: 'Cerai mati', label: 'Cerai mati'},
												]}
												placeholder="Pilih Status Perkawinan Pelapor Pelapor"
												isClearable
											/>
										)}
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Pekerjaan Pelapor
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="pekerjaan_pelapor"
											type="text"
											placeholder="Pekerjaan Pelapor"
											// value={this.state.dt.no_hp_pelapor}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.pekerjaan : this.state.dt.pekerjaan_pelapor}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										Alamat Pelapor
									</Label>
									<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control"
											id="alamat_pelapor"
											type="text"
											placeholder="Alamat Pelapor"
											defaultValue={this.state.detailSurat ? this.state.detailSurat.alamat_pelapor : this.state.dt.alamat_pelapor}
											style={ this.state.detailSurat && this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.detailSurat && this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>

								{/* Data Surat */}
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>DATA SURAT</b>
									</Label>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Perihal</b>
									</Label>
									<div className="col-sm-8">
										<div className="row" style={{ width: "100%" }}>
											{this.state.dataPelayanan ? (
												Object.keys(this.state.dataPelayanan.perihal).map((jenis, i) => {
													return (
														<div className="col-sm-4">
															<input type="checkbox"
																checked={this.state.dataPelayanan.perihal[jenis]}
																readOnly
															/> {jenis.toUpperCase().replace("_", ' ')}
														</div>
													)
												})
											) : (
												<>
													<div className="col-sm-4">
														<input type="checkbox"
															defaultChecked={this.state.dt.perihal.izin_usaha}
															checked={this.state.dt.perihal.izin_usaha}
															onChange={e => {
																let value = this.state.dt.perihal.izin_usaha
																this.setState({ 
																	dt: {...this.state.dt, 
																		perihal: {...this.state.dt.perihal, 
																			izin_usaha: !value
																		}
																	} 
																})
																// this.changeCheck(e, "izin_usaha");
															}}
														/> Izin Usaha
													</div>
													<div className="col-sm-4">
														<input type="checkbox"
															defaultChecked={this.state.dt.perihal.imb}
															checked={this.state.dt.perihal.imb}
															onChange={e => {
																let value = this.state.dt.perihal.imb
																this.setState({ 
																	dt: {...this.state.dt, 
																		perihal: {...this.state.dt.perihal, 
																			imb: !value
																		}
																	} 
																})
																// this.changeCheck(e, "imb");
															}}
														/> IMB
													</div>
													<div className="col-sm-4">
														<input type="checkbox"
															defaultChecked={this.state.dt.perihal.siup}
															checked={this.state.dt.perihal.siup}
															onChange={e => {
																let value = this.state.dt.perihal.siup
																this.setState({ 
																	dt: {...this.state.dt, 
																		perihal: {...this.state.dt.perihal, 
																			siup: !value
																		}
																	} 
																})
																// this.changeCheck(e, "siup");
															}}
														/> SIUP
													</div>
													<div className="col-sm-4">
														<input type="checkbox"
															defaultChecked={this.state.dt.perihal.situ}
															checked={this.state.dt.perihal.situ}
															onChange={e => {
																let value = this.state.dt.perihal.situ
																this.setState({ 
																	dt: {...this.state.dt, 
																		perihal: {...this.state.dt.perihal, 
																			situ: !value
																		}
																	} 
																})
																// this.changeCheck(e, "situ");
															}}
														/> SITU 
													</div>
													<div className="col-sm-4">
														<input type="checkbox"
															defaultChecked={this.state.dt.perihal.tdp}
															checked={this.state.dt.perihal.tdp}
															onChange={e => {
																let value = this.state.dt.perihal.tdp
																this.setState({ 
																	dt: {...this.state.dt, 
																		perihal: {...this.state.dt.perihal, 
																			tdp: !value
																		}
																	} 
																})
																// this.changeCheck(e, "tdp");
															}}
														/> TDP
													</div>
												</>
											)}
										</div>
									</div>
								</FormGroup>

								{/* Data Usaha */}
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Data Usaha</b>
									</Label>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Nama Usaha</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="nama_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											type="text"
											placeholder="Contoh: CV. Jaya Bersama Abadi"
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.nama_usaha : this.state.dt.nama_usaha}
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								{ (this.state.dataPelayanan && this.state.dataPelayanan.perihal.imb == true) || this.state.dt.perihal.imb == true ? (
									<FormGroup className="row" style={{ marginBottom: '10px' }}>
										<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
											<b>Lokasi Bangunan</b>
										</Label>
										<div className="col-sm-8">
											<input
												id="lokasi_pembangunan"
												className="form-control"
												style={{
													fontWeight: 400,
													fontSize: 'inherit'
												}}
												defaultValue={ this.state.dataPelayanan ? this.state.dataPelayanan.lokasi_lokasi_pembangunan : this.state.dt.lokasi_pembangunan}
												required
												type="text"
												placeholder="Contoh: Dusun Tani Mulya RT.002/RW.001 Desa Sei Simpang Dua Kec. Kampar Kiri Hilir Kab. Kampar"
												style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
												disabled={this.state.dataPelayanan ? true : false}
											/>
										</div>
									</FormGroup>
								) : ''}

								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Jenis Usaha</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="jenis_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.jenis_usaha : this.state.dt.jenis_usaha}
											// value={this.state.dt.jenis_usaha}
											type="text"
											placeholder="Contoh: Konstruksi Bangunan"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '0px' }}>
									<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Lokasi Usaha</b>
									</Label>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>Dusun</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="dusun_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.lokasi_usaha.dusun.label : this.state.dt.dusun_usaha}
											// value={this.state.dt.dusun_usaha}
											type="text"
											placeholder="Contoh: Kampung Lama"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '10px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>RW</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="rw_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.lokasi_usaha.rw.label : this.state.dt.rw_usaha}
											// value={this.state.dt.rw_usaha}
											type="number"
											placeholder="Contoh: 01"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '15px' }}>
									<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
										<b>RT</b>
									</Label>
									<div className="col-sm-8">
										<input
											id="rt_usaha"
											className="form-control"
											style={{
												fontWeight: 400,
												fontSize: 'inherit'
											}}
											defaultValue={this.state.dataPelayanan ? this.state.dataPelayanan.lokasi_usaha.rt.label : this.state.dt.rt_usaha}
											// value={this.state.dt.rt_usaha}
											type="number"
											placeholder="Contoh: 02"
											style={ this.state.dataPelayanan ? { backgroundColor:"#c9d1d8" } : {}}
											disabled={this.state.dataPelayanan ? true : false}
											required
										/>
									</div>
								</FormGroup>

								{/* Form Upload */}
								{!this.state.dataPelayanan ? (
									Object.keys(this.state.dt.linkGambar).map((attribute) => {
										let titleFile = 'dokumen'
										switch (attribute) {
											case 'surat_keterangan_usaha':
												titleFile = "Surat Keterangan Usaha"
												break;
										}
	
										return (
											<FormGroup className="row" style={{ marginBottom: '10px' }}>
												<Label className="col-sm-4 pl-4 col-form-label" style={{ fontWeight: 600 }}>
													<b>File {titleFile} (jpg/pdf)</b>
												</Label>
												<div
													className="col-sm-8"
													style={{ display: "flex", flexDirection: "column" }}
												>
													<input id="file-upload" type="file" onChange={(event) => {
														this.onFileChange(event, attribute)
													}} accept="image/png, image/jpg, image/jpeg, application/pdf" />
													{this.state.dt.linkGambar[attribute].link != "" ? (
														this.state.dt.linkGambar[attribute].type == "pdf" ? (
															<a href={this.state.dt.linkGambar[attribute].link}>Lihat file</a>
														) : (
															<a 
																href='/'
																onClick={(e) => {
																	e.preventDefault()
																	let status = { ...this.state.status };
																	status.fotoPersyaratan = true;
																	this.setState({
																		status,
																		fotoPersyaratan: this.state.dt.linkGambar[attribute].link,
																		titlePersyaratan: titleFile,
																	});
																}}
																>
																	Lihat file
															</a>
														)
													) : (
														""
													)}
												</div>
											</FormGroup>
										)
									})
								) : (
									<div className="row">
										{/* Looping foto persyaratan */}
										{Object.keys(this.state.dt.linkGambar).map((attribute) => {
											let titleFile = 'dokumen'
											switch (attribute) {
												case 'surat_keterangan_usaha':
													titleFile = "Surat Keterangan Usaha"
													break;
											}
											let linkGambarExist = false
											let explodeLinkGambar = []
											let typeFile = ''

											if (this.state.dataPelayanan.linkGambar && this.state.dataPelayanan.linkGambar[attribute] && this.state.dataPelayanan.linkGambar[attribute] != "") {
												linkGambarExist = true
												explodeLinkGambar = this.state.dataPelayanan.linkGambar[attribute].split('.')
												typeFile = explodeLinkGambar[explodeLinkGambar.length - 1]
											}

											return (
												<div
														className="col-md-4"
														style={{
															display: 'flex',
															flexDirection: 'column',
															alignItems: 'center'
														}}
													> 
														{linkGambarExist ? (
															typeFile != "pdf" ? (
																<img
																	src={this.state.dataPelayanan.linkGambar[attribute]}
																	style={{ width: "200px", height: "200px", objectFit: "cover", cursor: "pointer" }}
																	onClick={() => {
																		let status = { ...this.state.status };
																		status.fotoPersyaratan = true;
																		this.setState({
																			status,
																			fotoPersyaratan: this.state.dataPelayanan.linkGambar[attribute],
																			titlePersyaratan: titleFile,
																		});
																	}}
																/>
															) : (
																<a href={this.state.dataPelayanan.linkGambar[attribute]}>Lihat file</a>
															)
                            ) : (
                              <div style={{ height: "200px", width: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <p style={{ textAlign: "center", fontWeight: 600 }}>Tidak ada foto persyaratan</p>
                              </div>
                            )}
														<p style={{ textAlign: "center" }}>{titleFile}</p>
													</div>
											)
										})}
									</div>
								)}
								
							</div>
						
							<div className="modal-footer" align="center">
								<button
									type="button"
									className="btn btn-warning"
									disabled={this.state.status.btnForm}
									onClick={() => {
										this.tutupForm()
									}}
									>
									Tutup
								</button>
								{!this.state.dataPelayanan ? (
									<button
										type="submit"
										className="btn btn-success"
										disabled={this.state.status.btnForm}
										>
										Selesai
									</button>
								) : ''}
							</div>
						</Form>
					</Modal>

					{/* Modal validasi */}
					<Modal
						open={this.state.status.modalValidasi}
						styles={{
							modal: {
								width: '85%'
							}
						}}
						onClose={() => {
							// Tutup modal validasi
							let status = { ...this.state.status };
							status.modalValidasi = false;
							this.setState({ status });
						}}
					>
						<Form className="theme-form">
							<div className="modal-header">
								<h5 className="modal-title">Form Validasi Penerbitan Rekomendasi Izin Usaha</h5>
							</div>
							<div className="modal-body">
								{this.state.detailSurat ? (
									<div>
										{/* Detail Surat */}
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Nama
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.nama_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												NIK
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.nik_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Jenis Layanan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.jenis_layanan}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Tanggal Pengajuan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.tgl_melapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Nomor HP
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.no_hp_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Pekerjaan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.pekerjaan_pelapor}
												</h6>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Alamat
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<h6 style={{ marginBottom: '0px', fontWeight: 400 }}>
													{this.state.detailSurat.alamat_pelapor}
												</h6>
											</div>
										</FormGroup>
									</div>
								) : (
									''
								)}
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-warning"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										let status = { ...this.state.status };
										status.tolakValidasi = true;

										this.setState({ status });
									}}
									style={{ fontWeight: 600 }}
								>
									Tolak
								</button>
								<button
									type="submit"
									className="btn btn-success"
									// disabled={this.state.status.btnForm}
									onClick={(e) => {
										e.preventDefault();
										if (this.state.detailSurat) {
											this.updateSurat(this.state.detailSurat.uuid);
										}
									}}
									style={{ fontWeight: 600 }}
								>
									Terima
								</button>
							</div>
						</Form>
					</Modal>
					{/* Modal penerbitan */}
					<Modal
						open={this.state.status.modalPenerbitan}
						styles={{
							modal: {
								width: '85%'
							}
						}}
						onClose={() => {
							// Tutup modal validasi
							let status = { ...this.state.status };
							status.modalPenerbitan = false;
							this.setState({ status });
						}}
					>
						<Form className="theme-form" onSubmit={this.terbitkanSurat}>
							<div className="modal-header">
								<h5 className="modal-title">Form Penerbitan Izin Usaha Mikro</h5>
							</div>
							<div className="modal-body">
								{this.state.detailSurat ? (
									<div>
										<FormGroup className="row" style={{ marginBottom: '10px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												<b>Nomor Surat Kecamatan</b>
											</Label>
											<div className="col-sm-9">
												<input
													className="form-control"
													id="no_surat_kecamatan"
													type="text"
													placeholder="Nomor Surat Kecamatan"
													defaultValue={this.state.noSuratAuto ? this.state.noSuratKecamatan : this.state.dt.nomor_surat_kecamatan}
													// value={this.state.noSuratAuto ? this.state.noSuratKecamatan : this.state.dt.nomor_surat_kecamatan}
													style={ this.state.noSuratAuto ? { backgroundColor:"#c9d1d8" } : {}}
													disabled={ this.state.noSuratAuto ? true : false }
													required
												/>
												<small id="emailHelp" className="form-text text-muted">
													<a
														href="/"
														onClick={(e) => {
															e.preventDefault()
															this.setState({ noSuratAuto: !this.state.noSuratAuto })
														}}
													>
														Input nomor surat {this.state.noSuratAuto ? "manual" : "otomatis"}
													</a>
												</small>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												<b>Penandatangan Surat</b>
											</Label>
										</FormGroup>
										<div className="row">
											<div className="col-sm-12">
												<table className="table table-bordered table-stripes table-hovered">
													<thead>
														<tr>
															<th>No</th>
															<th>Jabatan</th>
															<th>Nama</th>
															<th>NIP</th>
															<th>Pangkat</th>
															<th>Jabatan</th>
															<th>
																<i className="fa fa-check" />
															</th>
														</tr>
													</thead>
													<tbody>{tableTTD}</tbody>
												</table>
											</div>
										</div>
										{/* <FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Nama Penandatangan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<input id="nama_ttd_surat_kecamatan" type="text" className="form-control" placeholder="Masukkan Nama Penandatangan Surat" required />
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Pangkat Penandatangan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<input id="pangkat_ttd_surat_kecamatan" type="text" className="form-control" placeholder="Masukkan Nama Pangkat Penandatangan Surat" required />
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												Jabatan Penandatangan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<input id="jabatan_ttd_surat_kecamatan" type="text" className="form-control" placeholder="Masukkan Nama Jabatan Penandatangan Surat" required />
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '0px' }}>
											<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
												NIP Penandatangan
											</Label>
											<div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
												<input id="nip_ttd_surat_kecamatan" type="text" className="form-control" placeholder="Masukkan NIP Penandatangan Surat" required />
											</div>
										</FormGroup> */}
										
									</div>
								) : (
									''
								)}
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-warning"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										let status = { ...this.state.status };
										status.modalPenerbitan = false;

										this.setState({ status });
									}}
									style={{ fontWeight: 600 }}
								>
									Batal
								</button>
								<button
									type="submit"
									className="btn btn-success"
									// disabled={this.state.status.btnForm}
									style={{ fontWeight: 600 }}
								>
									Terbitkan
								</button>
							</div>
						</Form>
					</Modal>
					{/* Modal Detail Gambar */}
					<Modal
						open={this.state.status.fotoPersyaratan}
						styles={{
							modal: {
								width: '90%'
							}
						}}
						onClose={() => {
							let status = { ...this.state.status };
							status.fotoPersyaratan = false;
							this.setState({ status });
						}}
					>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.titlePersyaratan}</h5>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-md-12">
								{this.state.fotoPersyaratan != '' ? (
									<img
									src={this.state.fotoPersyaratan}
									style={{ width: "100%" }}
								/>
								) : (
									<h6 style={{ textAlign: "center" }}>Tidak ada Foto</h6>
								)}
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-default"
								// disabled={this.state.status.btnForm}
								onClick={() => {
									let status = { ...this.state.status };
									status.fotoPersyaratan = false;
									this.setState({ status });
								}}
								style={{ fontWeight: 600 }}
							>
								Tutup
							</button>
						</div>
					</Modal>
					{/* Modal form penolakan pengajuan */}
					<Modal
						open={this.state.status.tolakValidasi}
						styles={{
							modal: {
								width: '80%'
							}
						}}
						onClose={() => {
							let status = { ...this.state.status };
							status.tolakValidasi = false;
							this.setState({ status });
						}}
					>
						<Form className="theme-form">
							<div className="modal-header">
								<h5 className="modal-title">Penolakan Pengajuan Layanan</h5>
							</div>
							<div className="modal-body">
								<FormGroup className="row" style={{ marginBottom: '0px' }}>
									<Label className="col-sm-3 col-form-label" style={{ fontWeight: 600 }}>
										Alasan Penolakan
									</Label>
									<div className="col-sm-9" style={{ textAlign: 'center' }}>
										<Input type="textarea" id="alasan" nama="alasan" />
									</div>
								</FormGroup>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-info"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										let status = { ...this.state.status };
										status.tolakValidasi = false;
										this.setState({ status });
									}}
									style={{ fontWeight: 600 }}
								>
									Batal
								</button>
								<button
									type="submit"
									className="btn btn-success"
									// disabled={this.state.status.btnForm}
									onClick={(e) => {
										e.preventDefault();
										this.tolakSurat(this.state.detailSurat.uuid);
									}}
									style={{ fontWeight: 600 }}
								>
									Simpan
								</button>
							</div>
						</Form>
					</Modal>
					
					{/* Modal detail berkas */}
					<Modal
						open={this.state.status.modalDetail}
						styles={{
							modal: {
								width: '75%'
							}
						}}
						onClose={() => {
							let status = { ...this.state.status };
							status.modalDetail = false;
							this.setState({ status });
						}}
					>
						<Form className="theme-form">
							<div className="modal-header">
								<h5 className="modal-title">{this.state.judul}</h5>
							</div>
							<div className="modal-body">
								{this.state.detailSurat && this.state.dataPelayanan ? (
									<div>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                        <h6 className="mb-0"><b>Desa</b></h6>
											</Label>
											<div className="col-sm-8">
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.dataPelayanan.id_desa ? this.state.dataPelayanan.id_desa.label : '-'}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: "5px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-2"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Pelapor</b>
                        </h6>
                      </Label>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                        <h6 className="mb-0">NIK Pelapor</h6>
											</Label>
											<div className="col-sm-8">
												{/* <h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.detailSurat.nik_pelapor}
												</h6> */}
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.detailSurat.nik_pelapor}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>
                    <FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">Nama</h6>
											</Label>
											<div className="col-sm-8">
												{/* <h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.detailSurat.nama_pelapor}
												</h6> */}
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.detailSurat.nama_pelapor}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>
                    <FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">Nomor HP</h6>
											</Label>
											<div className="col-sm-8">
												{/* <h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.detailSurat.no_hp_pelapor}
												</h6> */}
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.detailSurat.no_hp_pelapor}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>
                    <FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">Status Perkawinan</h6>
											</Label>
											<div className="col-sm-8">
												{/* <h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.status_perkawinan}
												</h6> */}
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.dataPelayanan.status_perkawinan}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>

										{/* Data Surat */}
										<FormGroup className="row" style={{ marginBottom: "5px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-2"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Surat</b>
                        </h6>
                      </Label>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">No. Surat Desa</h6>
											</Label>
											<div className="col-sm-8">
												{/* <h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.no_surat}
												</h6> */}
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.dataPelayanan.nomor_surat_desa}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">Tgl. Surat Desa</h6>
											</Label>
											<div className="col-sm-8">
												{/* <h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.tgl_surat}
												</h6> */}
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.dataPelayanan.tanggal_surat_desa}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">Perihal</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<div className="row" style={{ width: '100%' }}>
													{Object.keys(this.state.dataPelayanan.perihal).map((jenis, i) => {
														return (
															<div className="col-sm-4">
																<input type="checkbox"
																	checked={this.state.dataPelayanan.perihal[jenis]}
																	readOnly
																/> {jenis.toUpperCase().replace("_", ' ')}
															</div>
														)
													})}
												</div>
											</div>
										</FormGroup>

										{/* Data Usaha */}
										<FormGroup className="row" style={{ marginBottom: "5px" }}>
                      <Label
                        className="col-sm-4 col-form-label pb-1 pt-2"
                        style={{ fontWeight: 1000 }}
                      >
                        <h6 style={{ margin: "0px" }}>
                          <b>Data Usaha</b>
                        </h6>
                      </Label>
                    </FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">Nama Usaha</h6>
											</Label>
											<div className="col-sm-8">
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.dataPelayanan.nama_usaha}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>
										{ this.state.dataPelayanan.perihal.imb==true ? (
											<FormGroup className="row" style={{ marginBottom: '10px' }}>
												<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
													<h6 className="mb-0">Lokasi Bangunan</h6>
												</Label>
												<div className="col-sm-8">
													<input
														className="form-control"
														style={{
															fontWeight: 400,
															fontSize: 'inherit',
															backgroundColor:"#c9d1d8"
														}}
														defaultValue={this.state.dataPelayanan.lokasi_lokasi_pembangunan}
														disabled
														type="text"
													/>
												</div>
											</FormGroup>
										) : ''}
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">Jenis Usaha</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.dataPelayanan.jenis_usaha}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">Lokasi Usaha</h6>
											</Label>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0 pl-4" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">Dusun</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												{/* <h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.lokasi_usaha.dusun ? this.state.dataPelayanan.lokasi_usaha.dusun.label : '-'}
												</h6> */}
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.dataPelayanan.lokasi_usaha.dusun ? this.state.dataPelayanan.lokasi_usaha.dusun.label : '-'}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0 pl-4" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">RT</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												{/* <h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.lokasi_usaha.rt ? this.state.dataPelayanan.lokasi_usaha.rt.label : '-'}
												</h6> */}
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.dataPelayanan.lokasi_usaha.rt ? this.state.dataPelayanan.lokasi_usaha.rt.label : '-'}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>
										<FormGroup className="row" style={{ marginBottom: '7px' }}>
											<Label className="col-sm-4 col-form-label pb-0 pt-0 pl-4" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
												<h6 className="mb-0">RW</h6>
											</Label>
											<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												{/* <h6
													style={{
														marginBottom: '0px',
														fontWeight: 400
													}}
												>
													{this.state.dataPelayanan.lokasi_usaha.rw ? this.state.dataPelayanan.lokasi_usaha.rw.label : '-'}
												</h6> */}
												<input
													className="form-control"
													style={{
														fontWeight: 400,
														fontSize: 'inherit',
														backgroundColor:"#c9d1d8"
													}}
													defaultValue={this.state.dataPelayanan.lokasi_usaha.rw ? this.state.dataPelayanan.lokasi_usaha.rw.label : '-'}
													disabled
													type="text"
												/>
											</div>
										</FormGroup>
										
									</div>
								) : (
									''
								)}
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-info"
									// disabled={this.state.status.btnForm}
									onClick={() => {
										this.tutupForm();
									}}
									style={{ fontWeight: 600 }}
								>
									Tutup
								</button>
							</div>
						</Form>
					</Modal>
				</div>
			</div>
		);
	}
}

export default RekomendasiIzinUsaha;
