import axios from "axios";

//Dev Dashboard Eksternal
const API_URL = "https://ws-prod-dashboard-external.digidesa.com/api/v1/";
// const API_URL = "https://ws-dev-dashboard-external.digidesa.com/api/v1/";

const API_URL2 = 'https://ws-marketplace-desa-prod.digidesa.com/api/v1/web/';
// const API_URL2 = "https://ws-marketplace-desa-univ.digidesa.com/api/v1/web/";

export function Get(table, id, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "public";

	// console.log(token);
	if (id === null) {
		axios
			.get(`${API_URL + table}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				if (err.response) {
					cb(err.response.data);
					// cb(err.response.headers);
					// cb(err.response.status);
					// console.log(error.response.status);
					// console.log(error.response.headers);
				}
				// cb(err);
			});
	} else {
		axios
			.get(`${API_URL + table}/${id}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				cb(err);
			});
	}
}

// Marketplace
export function GetMarketplace(table, id, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "";

	// console.log(token);
	if (id === null) {
		axios
			.get(`${API_URL2 + table}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				cb(err);
			});
	} else {
		axios
			.get(`${API_URL2 + table}/${id}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function Delete(table, id, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "";

	axios
		.delete(`${API_URL + table}/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		})
		.then(response => response.status)
		.then(status => {
			cb(status);
		})
		.catch(err => {
			cb(err);
		});
}

export function Post(table, id, data, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "";
	// console.log(token)
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => {
				cb(response);
			})
			.catch(err => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => {
				cb(response);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function Put(table, id, data, cb) {
	axios
		.put(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then(response => {
			cb(response);
		})
		.catch(err => {
			cb(err);
		});
}

export function Patch(table, id, data, cb) {
	axios
		.patch(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then(response => {
			cb(response);
		})
		.catch(err => {
			cb(err);
		});
}

export function cekLogin(table, data, cb) {
	axios
		.post(`${API_URL + table}`, data, { validateStatus: false })
		.then(response => {
			cb(response);
		})
		.catch(response => {
			cb(response);
		});
}
export function PostStatus(table, id, data, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "";
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => response.status)
			.then(status => {
				cb(status);
			})
			.catch(err => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => response.status)
			.then(status => {
				cb(status);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function PostUser(table, id, data, cb) {
	if (id === null) {
		axios
			.post("http://localhost/ppdx/web/" + table, data, {
				headers: { "Content-Type": "multipart/form-data" }
			})
			.then(response => response.status)
			.then(status => {
				cb(status);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export default API_URL;
