import React, { Component } from "react";

// Import authService
import {
  Post,
  Get,
  Delete,
  cekLogin,
  API_URL
} from "../../../function/Koneksi";

import ReactList from "react-list";
import { Link } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroller";
class Notificationbaru extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total: 0,
      pagination: {
        total: 0
      },
      pesan: "Memuat notifikasi...."
    };
  }

  componentDidMount() {
    
  }
  cekNotifikasi = dt => {
    Get("list-notif/update/" + dt.uuid, null, res => {
      console.log(res);
      this.componentDidMount();
    });
  };
  fetchMoreData = () => {
    // console.log(this.state.pagination)
    if (this.state.pagination.current_page < this.state.pagination.last_page) {
      let link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
      // console.log(link)
      this.setState({ pesan: "Memuat notifikasi...." });

      Get("list-notif" + link, null, res => {
        let data = res.results.data.data;
        let results = res.results;
        this.setState({
          data: [...this.state.data, ...res.results.data.data],
          pagination: results.data
        });
        // console.log(this.state.data)
      });
    } else {
      this.setState({ pesan: "" });
    }
  };
  renderItem = (index, key) => {
    let data = this.state.data;
    console.log(data);
    return data.length != 0 ? (
      <li key={key}>
        <div className="media">
          <div className="media-body">
            <h6 className="mt-0">{data[index].konten}</h6>
            <span>
              <i className="icofont icofont-clock-time p-r-5"></i>Just Now
            </span>
          </div>
        </div>
      </li>
    ) : null;
  };

  render() {
    return (
      <li className="onhover-dropdown">
        <a className="txt-dark">
          <img
            className="align-self-center pull-right"
            src={require("../../../assets/images/dashboard/notification.png")}
            alt="header-notification"
          />
          <span className="badge badge-pill badge-primary notification">
            {this.state.total}
          </span>
        </a>
        <ul className="notification-dropdown onhover-show-div custom-scrollbar ">
          <li>
            Notifikasi{" "}
            <span className="badge badge-pill badge-secondary text-white text-uppercase pull-right">
              {this.state.total} baru
            </span>
          </li>
          <div
            style={{ overflow: "auto", maxHeight: 200 }}
            ref={ref => (this.scrollParentRef = ref)}
          >
            <InfiniteScroll
              initialLoad={false}
              loadMore={this.fetchMoreData}
              hasMore={true}
              useWindow={false}
              getScrollParent={() => this.scrollParentRef}
              loader={
                this.state.pesan != "" ? (
                  <li key={0}>
                    <div className="media">
                      <div className="media-body">
                        <h6 className="mt-0">{this.state.pesan}.</h6>
                      </div>
                    </div>
                  </li>
                ) : (
                  <></>
                )
              }
            >
              {this.state.data.map((dt, idx) => {
                let cek =
                  dt.konten.indexOf("setujui") !== -1
                    ? { pathname: "/admin/disposisi", state: { tab: "2" } }
                    : { pathname: "/admin/disposisi", state: { tab: "1" } };
                return (
                  <li key={idx}>
                    <Link to={cek} onClick={() => this.cekNotifikasi(dt)}>
                      <div className="media">
                        <div className="media-body">
                          <h6
                            className={
                              dt.status == 0 ? "mt-0 txt-danger " : "mt-0"
                            }
                          >
                            {dt.konten}
                          </h6>
                          <span>
                            <i className="icofont icofont-clock-time p-r-5"></i>
                            {new Date(dt.created_at).toLocaleDateString(
                              "id-ID"
                            )}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </InfiniteScroll>
          </div>
          {/* <div style={{overflow: 'auto', maxHeight: 400}}>
          <ReactList
            itemRenderer={this.renderItem}
            length={this.state.data.length}
            type="uniform"
            pageSize={3}
          />
          </div>
           */}
          <li className="text-center">
            Detail
            <Link to={{ pathname: "/admin/notifikasi" }}> semua </Link>
            notifikasi{" "}
          </li>
        </ul>
      </li>
    );
  }
}

export default Notificationbaru;
