import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/site.css';

import SweetAlert from 'react-bootstrap-sweetalert';
// import {DESA} from "../function/Koneksi"
import { Nama_DESA, DESA, NAMA_KABUPATEN, PROVINSI, KECAMATAN } from '../function/Koneksi';

// koneksi
import { Post, Get, Delete, cekLogin } from '../function/Koneksi';
import { DataToken } from '../function/Format';

const jwt = require('jsonwebtoken');
// let LIST_LAYANAN = JSON.parse(LAYANAN_DESA);
class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			layanan: [],
			perizinan: [],
			listPelayanan: [],
			jumlahMenungguEksekusi: 0,
			jumlahSelesai: 0,
			jumlahCatatanMenungguEksekusi: 0,
			jumlahVerifikasiMenungguEksekusi: 0,
			jumlahPengaduanDiajukan: 0,
			menuUtama: 'inherit',
			menuWilayah: 'none',
			menuPengaturan: 'none',
			menuPerangkat: 'none',
			menuLaporan: 'none',
			menuAdministrasi: 'none',
			menuSuku: 'none',
			menuLayanan: 'none',
			menuPerubahanData: 'none',

			jumlah_diajukan_iumk:0,
			jumlah_diajukan_usaha:0,
			total_diajukan:"",

			link_gambar_show: null,
			data_cek: '',
			akun: DataToken(sessionStorage.getItem('access_token')),
			dataUser: jwt.decode(sessionStorage.getItem('access_token')),
			bannerDashboard: null
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	getData = (fil = null) => {
		if (fil) {
			let filJenisSurat = {
				nama: fil
			};
			Post('jenis-surat/list', null, filJenisSurat, (data) => {
				let layanan = [];
				let perizinan = [];
				data.data.results.map((dt) => {
					if (dt.kategori == 'Layanan Masyarakat') {
						layanan.push(dt);
					}
					if (dt.kategori == 'Perizinan Masyarakat') {
						perizinan.push(dt);
					}
				});
				this.setState({ layanan, perizinan });
			});
		} else {
			//   Get("jenis-surat/list", null, data => {
			//     let layanan = [];
			//     let perizinan = [];
			//     data.results.map(dt => {
			//       if (dt.kategori == "Layanan Masyarakat") {
			//         layanan.push(dt);
			//       }
			//       if (dt.kategori == "Perizinan Masyarakat") {
			//         perizinan.push(dt);
			//       }
			//     });
			//     this.setState({ layanan, perizinan });
			//   });
		}
	};

	componentDidMount() {
		// LIST_LAYANAN = JSON.parse(localStorage.getItem("LAYANAN_DESA"));
		this.getData();

		if (this.state.dataUser) {
			if (this.state.dataUser.sub.jenis == "Kecamatan") {
				this.setState({
					bannerDashboard: (
						<img
							src={require('../assets/images/banner/kecamatan digidesa.png')}
							alt=""
							style={{
								maxHeight: '250px',
								width: '100%',
								borderRadius: '10px'
							}}
						/>
					)
				})
			}
		}

		Get("jenis-pelayanan", null, dtkat => {
			console.log(dtkat);
			if (dtkat.results) {
				// let listPelayanan = []
				// dtkat.results.data.map((val) => {
				// 	listPelayanan.push({ data: val })
				// })
				this.setState({ listPelayanan: dtkat.results.data })
			}
		});

		Get("pelayanan?jenis_layanan=IUMK&status=Diajukan", null, dtkat => {
			if (dtkat.results) {
				Get("pelayanan?jenis_layanan=Rekomendasi Izin Usaha&status=Diajukan", null, dtkat2 => {
					if (dtkat2.results) {
						this.setState({ 
							jumlah_diajukan_iumk: dtkat.results.total,
							jumlah_diajukan_usaha: dtkat2.results.total
						});
						// this.setState({ jumlah_diajukan_usaha: dtkat2.results.total });
					}
				});
			}
		});


		// Get("list-pengajuan-layanan/selesai", null, dtkat => {
		//   this.setState({ jumlahSelesai: dtkat.results.total });
		// });

		// Get("pengaduan?status=Diajukan", null, dtkat => {
		//   this.setState({ jumlahPengaduanDiajukan: dtkat.results.total });
		// });

		// Get("catatan-penduduk?status=0", null, dtkat => {
		//   this.setState({ jumlahCatatanMenungguEksekusi: dtkat.results.total });
		// });

		// Get("pengguna?status=1", null, dtkat => {
		//   this.setState({
		//     jumlahVerifikasiMenungguEksekusi: dtkat.results.total
		//   });
		// });

		// Get('banner', null, (dtkat) => {
		// 	if (dtkat.results == null || dtkat.results == 'undefined') {
		// 		this.setState({
		// 			data_cek: 'Kosong',
		// 			loading: false,
		// 			data: {
		// 				uuid: null
		// 			}
		// 		});
		// 	} else {
		// 		let set_link = '';
		// 		let statData = '';

		// 		if (dtkat.results.link_web) {
		// 			let ambil = JSON.parse(dtkat.results.link_web);
		// 			if (ambil.banner_dashboard == null || ambil.banner_dashboard == '[]') {
		// 				statData = 'Kosong';
		// 				set_link = '../assets/images/banner/kampung-tengah.png';
		// 			} else {
		// 				statData = 'Tidak Kosong';
		// 				set_link = ambil.banner_dashboard;
		// 			}
		// 		} else {
		// 			statData = 'Kosong';
		// 			set_link = '../assets/images/banner/kampung-tengah.png';
		// 		}

		// 		this.setState({
		// 			data_cek: statData,
		// 			link_gambar_show: set_link
		// 		});
		// 	}
		// });
	}

	render() {

		let total_diajukan = this.state.jumlah_diajukan_iumk+this.state.jumlah_diajukan_usaha;
		
		const dataUser = jwt.decode(sessionStorage.getItem('access_token'));
		const jenisUser = dataUser.sub.jenis;
		console.log(this.state.akun);
		const tampilLayananCapil = (
			<>
				<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
					<Link to={{ pathname: '/akta-kelahiran' }}>
						<div className="dashboard-over">
							<b>
								<span>AKL</span>
								<h6>Akta Kelahiran</h6>
							</b>
						</div>
					</Link>
				</div>
				<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
					<Link to={{ pathname: '/akta-kematian' }}>
						<div className="dashboard-over">
							<b>
								<span>AKM</span>
								<h6>Akta Kematian</h6>
							</b>
						</div>
					</Link>
				</div>
				<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
					<Link to={{ pathname: '/pindah-datang' }}>
						<div className="dashboard-over">
							<b>
								<span>PD</span>
								<h6>Pindah / Datang</h6>
							</b>
						</div>
					</Link>
				</div>
				<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
					<Link to={{ pathname: '/kartu-keluarga' }}>
						<div className="dashboard-over">
							<b>
								<span>KK</span>
								<h6>Kartu Keluarga</h6>
							</b>
						</div>
					</Link>
				</div>
				<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
					{/* <Link to={{ pathname: "/akta-perkawinan"}}> */}
					<Link to={{ pathname: '/coming_soon' }}>
						<div className="dashboard-over">
							<b>
								<span>AP</span>
								<h6>Akta Perkawinan</h6>
							</b>
						</div>
					</Link>
				</div>
				<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
					{/* <Link to={{ pathname: "/akta-perceraian"}}> */}
					<Link to={{ pathname: '/coming_soon' }}>
						<div className="dashboard-over">
							<b>
								<span>AC</span>
								<h6>Akta Perceraian</h6>
							</b>
						</div>
					</Link>
				</div>
				<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
					{/* <Link to={{ pathname: "/kartu-tanda-penduduk"}}> */}
					<Link to={{ pathname: '/coming_soon' }}>
						<div className="dashboard-over">
							<b>
								<span>KTP</span>
								<h6>Kartu Tanda Penduduk</h6>
							</b>
						</div>
					</Link>
				</div>
				<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
					{/* <Link to={{ pathname: "/kartu-identitas-anak"}}> */}
					<Link to={{ pathname: '/coming_soon' }}>
						<div className="dashboard-over">
							<b>
								<span>KIA</span>
								<h6>Kartu Identitas Anak (KIA)</h6>
							</b>
						</div>
					</Link>
				</div>
			</>
		);

		const tampilLayananKua = (
			<>
				<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
					<Link to={{ pathname: '/pendaftaran-pernikahan' }}>
						<div className="dashboard-over">
							<b>
								<span>PP</span>
								<h6>Pendaftaran Pernikahan</h6>
							</b>
						</div>
					</Link>
				</div>
			</>
		);

    	const tampilLayananAdmin = 
		(
		<>
		<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
			<Link to={{ pathname: "/admin/pengguna"}}>
				<div className="dashboard-over">
				<b>
					<span><i className="fa fa-user"></i></span>
					<h6>Kelola Data User</h6>
				</b>
				</div>
			</Link>
		</div>
		<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
			<Link to={{ pathname: "/admin/instansi"}}>
				<div className="dashboard-over">
				<b>
					<span><i className="fa fa-building"></i></span>
					<h6>Kelola Data Instansi</h6>
				</b>
				</div>
			</Link>
		</div>
		<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
			<Link to={{ pathname: "/admin/perangkat"}}>
				<div className="dashboard-over">
				<b>
					<span><i className="fa fa-users"></i></span>
					<h6>Kelola Data Perangkat</h6>
				</b>
				</div>
			</Link>
		</div>
	  	</>
        );

		const tampilLayananKecamatan = (
			<>
				{this.state.listPelayanan.map((val) => {
					let pathname = ''
					let inisial = ''

					switch (val.nama.toLowerCase()) {
						case 'surat izin usaha mikro kecil':
							pathname = '/izin-usaha-mikro'
							inisial = 'IUM'
							break;
						case 'surat rekomendasi izin usaha':
							pathname = '/rekomendasi-izin-usaha'
							inisial = 'RIU'
							break;
					}

					if (pathname != '') {
						return (
							<div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
								{/* <Link to={{ pathname: '/coming_soon' }}> */}
								<Link to={{ pathname, state: val }}>
									<div className="dashboard-over">
										<b>
											<span>{inisial}</span>
											<h6>{val.nama}</h6>
										</b>
									</div>
								</Link>
							</div>
						)
					} else {
						return ''
					}

				})}
			</>
		);

		// let bannerAplikasi = null;
		// if (this.state.data_cek == 'Kosong') {
			let bannerAplikasi = (
				<img
					src={require('../assets/images/banner/kampung-tengah.png')}
					alt=""
					style={{
						maxHeight: '250px',
						width: '100%',
						borderRadius: '10px'
					}}
				/>
			);
			
			if (this.state.bannerDashboard) {
				bannerAplikasi = this.state.bannerDashboard
			}

		return (
			<div>
			  <SweetAlert
				show={this.state.show}
				type={this.state.basicType}
				title={this.state.basicTitle}
				confirmBtnBsStyle="success"
				onConfirm={this.closeAlert}
			  >
				{this.state.pesanAlert}
			  </SweetAlert>
			  {this.state.alert}
			  <div className="container-fluid">
				{/* <div className="row"> */}
				{this.state.akun.sub.jenis && this.state.akun.sub.jenis == "Capil" ? (
				  <div className="row">
				  <div className="col-sm-12">
					<div className="row">
					  <div className="col-sm-12 col-md-6 col-lg-8">
						<div className="card layanan-baru">
						  <div className="card-body">
							<div className="row">{tampilLayananCapil}</div>
						  </div>
						</div>
					  </div>
					  <div className="col-sm-12 col-md-6 col-lg-4">
						<div className="row">
						  <div className="col-sm-12 col-md-12">
							<div className="card" style={{ borderRadius: "10px" }}>
							  <div className="text-center">
								{bannerAplikasi}
							  </div>
							</div>
						  </div>
						  {/* pengajuan selesai */}
						  <div className="col-sm-12 col-md-12">
							{/* <Link
							  to={{
								pathname:
								  process.env.PUBLIC_URL + "/admin/disposisi",
								tabProps: "5"
							  }}
							> */}
							  <div className="pengajuan-selesai">
								<b>
								  <span>
									{/* <b>24%</b> */}
									{this.state.jumlahSelesai}
								  </span>
								  <h4>Pengajuan</h4>
								  {/* <span>100 Pengajuan</span> */}
								</b>
							  </div>
							{/* </Link> */}
						  </div>
	
						  
						  {/* pengajuan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname:
								  process.env.PUBLIC_URL + "/admin/disposisi",
								tabProps: "2"
							  }}
							>
							  <div className="pengajuan-menunggu-eksekusi">
								<b>
								  <span>{this.state.jumlahMenungguEksekusi}</span>
								  <h4>Proses</h4>
								  <h6>Menunggu Eksekusi</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						  {/* pengaduan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<div className="pengaduan-menunggu-eksekusi">
							  <b>
								<span>{this.state.jumlahPengaduanDiajukan}</span>
								<h4>Selesai</h4>
								<h6>Diajukan</h6>
							  </b>
							</div>
						  </div> */}
						  {/* catatan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname: process.env.PUBLIC_URL + "/admin/catatan",
								tabProps: "1"
							  }}
							>
							  <div className="catatan-menunggu-eksekusi">
								<b>
								  <span>
									{this.state.jumlahCatatanMenungguEksekusi}
								  </span>
								  <h4>Tolak</h4>
								  <h6>Belum Selesai</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						  {/* verifikasi menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname:
								  process.env.PUBLIC_URL + "/admin/verifikasi",
								tabProps: "2"
							  }}
							>
							  <div className="verifikasi-menunggu-eksekusi">
								<b>
								  <span>
									{this.state.jumlahVerifikasiMenungguEksekusi}
								  </span>
								  <h4>Verifikasi</h4>
								  <h6>Menunggu Verifikasi</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						</div>
					  </div>
					</div>
				  </div>
				  <div className="col-sm-12">
					<div className="row" style={{ marginTop: "50px" }}>
					  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						<a
						  href="https://solusidatamadani.co.id/web"
						  target="_blank"
						>
						  <div style={{ color: "#707070", textAlign: "center" }}>
							<img
							  src={require("../assets/images/esdeem.png")}
							  alt=""
							  style={{ maxWidth: "70%", maxHeight: "60px" }}
							/>
						  </div>
						</a>
					  </div>
					  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						<div
						  style={{
							color: "#707070",
							textAlign: "center",
							wordWrap: "break-word"
						  }}
						>
						  <h5>
							<b>PT. Solusi Data Madani</b>
						  </h5>
						  <h6>We Make IT Easier</h6>
						  <h7>2021 All Rights Reserved</h7>
						</div>
					  </div>
					  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						<div
						  style={{
							color: "#707070",
							textAlign: "center",
							wordWrap: "break-word"
						  }}
						>
						  <h5>
							<b>Hubungi Kami</b>
						  </h5>
						  <a
							href="tel: (0761) 849 861"
							target="_blank"
							style={{ color: "#707070" }}
						  >
							<h6>(0761) 849 861</h6>
						  </a>
						  <a
							href="mailto: info@solusidatamadani.co.id"
							target="_blank"
							style={{ color: "#707070" }}
						  >
							<h7>info@solusidatamadani.co.id</h7>
						  </a>
						</div>
					  </div>
					  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						<div
						  style={{
							color: "#707070",
							textAlign: "center",
							wordWrap: "break-word"
						  }}
						>
						  <span style={{ fontSize: "30px" }}>
							<a
							  href="https://wa.me/6282392462636"
							  target="_blank"
							  style={{ color: "#707070" }}
							>
							  <i className="icofont icofont-brand-whatsapp"></i>{" "}
							</a>
							<a
							  href="https://www.instagram.com/digidesa"
							  target="_blank"
							  style={{ color: "#707070" }}
							>
							  <i className="fa fa-instagram"></i>
							</a>
						  </span>
						  <a
							href="https://www.digidesa.com"
							target="_blank"
							style={{ color: "#707070" }}
						  >
							<h6>www.digidesa.com</h6>
						  </a>
						</div>
					  </div>
					</div>
				  </div>
				</div>
				) 
				: this.state.akun.sub.jenis && this.state.akun.sub.jenis == "KUA" ? (
				<div className="row">
					<div className="col-sm-12">
					  <div className="row">
						<div className="col-sm-12 col-md-6 col-lg-8">
						  <div className="card layanan-baru">
							<div className="card-body">
							  <div className="row">{tampilLayananKua}</div>
							</div>
						  </div>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-4">
						  <div className="row">
							<div className="col-sm-12 col-md-12">
							  <div className="card" style={{ borderRadius: "10px" }}>
								<div className="text-center">
								  {bannerAplikasi}
								</div>
							  </div>
							</div>
							{/* pengajuan selesai */}
							<div className="col-sm-12 col-md-12">
							  {/* <Link
								to={{
								  pathname:
									process.env.PUBLIC_URL + "/admin/disposisi",
								  tabProps: "5"
								}}
							  > */}
								<div className="pengajuan-selesai">
								  <b>
									<span>
									  {/* <b>24%</b> */}
									  {this.state.jumlahSelesai}
									</span>
									<h4>Pengajuan</h4>
									{/* <span>100 Pengajuan</span> */}
								  </b>
								</div>
							  {/* </Link> */}
							</div>
	  
							
							
						  {/* pengajuan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname:
								  process.env.PUBLIC_URL + "/admin/disposisi",
								tabProps: "2"
							  }}
							>
							  <div className="pengajuan-menunggu-eksekusi">
								<b>
								  <span>{this.state.jumlahMenungguEksekusi}</span>
								  <h4>Proses</h4>
								  <h6>Menunggu Eksekusi</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						  {/* pengaduan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<div className="pengaduan-menunggu-eksekusi">
							  <b>
								<span>{this.state.jumlahPengaduanDiajukan}</span>
								<h4>Selesai</h4>
								<h6>Diajukan</h6>
							  </b>
							</div>
						  </div> */}
						  {/* catatan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname: process.env.PUBLIC_URL + "/admin/catatan",
								tabProps: "1"
							  }}
							>
							  <div className="catatan-menunggu-eksekusi">
								<b>
								  <span>
									{this.state.jumlahCatatanMenungguEksekusi}
								  </span>
								  <h4>Tolak</h4>
								  <h6>Belum Selesai</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						  {/* verifikasi menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname:
								  process.env.PUBLIC_URL + "/admin/verifikasi",
								tabProps: "2"
							  }}
							>
							  <div className="verifikasi-menunggu-eksekusi">
								<b>
								  <span>
									{this.state.jumlahVerifikasiMenungguEksekusi}
								  </span>
								  <h4>Verifikasi</h4>
								  <h6>Menunggu Verifikasi</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						</div>
					  </div>
					</div>
				  </div>
					<div className="col-sm-12">
					  <div className="row" style={{ marginTop: "50px" }}>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <a
							href="https://solusidatamadani.co.id/web"
							target="_blank"
						  >
							<div style={{ color: "#707070", textAlign: "center" }}>
							  <img
								src={require("../assets/images/esdeem.png")}
								alt=""
								style={{ maxWidth: "70%", maxHeight: "60px" }}
							  />
							</div>
						  </a>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <div
							style={{
							  color: "#707070",
							  textAlign: "center",
							  wordWrap: "break-word"
							}}
						  >
							<h5>
							  <b>PT. Solusi Data Madani</b>
							</h5>
							<h6>We Make IT Easier</h6>
							<h7>2021 All Rights Reserved</h7>
						  </div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <div
							style={{
							  color: "#707070",
							  textAlign: "center",
							  wordWrap: "break-word"
							}}
						  >
							<h5>
							  <b>Hubungi Kami</b>
							</h5>
							<a
							  href="tel: (0761) 849 861"
							  target="_blank"
							  style={{ color: "#707070" }}
							>
							  <h6>(0761) 849 861</h6>
							</a>
							<a
							  href="mailto: info@solusidatamadani.co.id"
							  target="_blank"
							  style={{ color: "#707070" }}
							>
							  <h7>info@solusidatamadani.co.id</h7>
							</a>
						  </div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <div
							style={{
							  color: "#707070",
							  textAlign: "center",
							  wordWrap: "break-word"
							}}
						  >
							<span style={{ fontSize: "30px" }}>
							  <a
								href="https://wa.me/6282392462636"
								target="_blank"
								style={{ color: "#707070" }}
							  >
								<i className="icofont icofont-brand-whatsapp"></i>{" "}
							  </a>
							  <a
								href="https://www.instagram.com/digidesa"
								target="_blank"
								style={{ color: "#707070" }}
							  >
								<i className="fa fa-instagram"></i>
							  </a>
							</span>
							<a
							  href="https://www.digidesa.com"
							  target="_blank"
							  style={{ color: "#707070" }}
							>
							  <h6>www.digidesa.com</h6>
							</a>
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				)
				: this.state.akun.sub.jenis && this.state.akun.sub.jenis == "Kecamatan" ? (
				<div className="row">
					<div className="col-sm-12">
					  <div className="row">
						<div className="col-sm-12 col-md-6 col-lg-8">
						  <div className="card layanan-baru">
							<div className="card-body">
							  <div className="row">{tampilLayananKecamatan}</div>
							</div>
						  </div>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-4">
						  <div className="row">
							<div className="col-sm-12 col-md-12">
							  <div className="card" style={{ borderRadius: "10px" }}>
								<div className="text-center">
								  {bannerAplikasi}
								</div>
							  </div>
							</div>
							{/* pengajuan selesai */}
							<div className="col-sm-12 col-md-12">
							  {/* <Link
								to={{
								  pathname:
									process.env.PUBLIC_URL + "/admin/disposisi",
								  tabProps: "5"
								}}
							  > */}
								<div className="pengajuan-selesai">
								  <b>
									<span>
									  {/* <b>24%</b> */}
									  {total_diajukan}
									</span>
									<h4>Pengajuan</h4>
									{/* <span>100 Pengajuan</span> */}
								  </b>
								</div>
							  {/* </Link> */}
							</div>
						</div>
					  </div>
					</div>
				  </div>
					<div className="col-sm-12">
					  <div className="row" style={{ marginTop: "50px" }}>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <a
							href="https://solusidatamadani.co.id/web"
							target="_blank"
						  >
							<div style={{ color: "#707070", textAlign: "center" }}>
							  <img
								src={require("../assets/images/esdeem.png")}
								alt=""
								style={{ maxWidth: "70%", maxHeight: "60px" }}
							  />
							</div>
						  </a>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <div
							style={{
							  color: "#707070",
							  textAlign: "center",
							  wordWrap: "break-word"
							}}
						  >
							<h5>
							  <b>PT. Solusi Data Madani</b>
							</h5>
							<h6>We Make IT Easier</h6>
							<h7>2021 All Rights Reserved</h7>
						  </div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <div
							style={{
							  color: "#707070",
							  textAlign: "center",
							  wordWrap: "break-word"
							}}
						  >
							<h5>
							  <b>Hubungi Kami</b>
							</h5>
							<a
							  href="tel: (0761) 849 861"
							  target="_blank"
							  style={{ color: "#707070" }}
							>
							  <h6>(0761) 849 861</h6>
							</a>
							<a
							  href="mailto: info@solusidatamadani.co.id"
							  target="_blank"
							  style={{ color: "#707070" }}
							>
							  <h7>info@solusidatamadani.co.id</h7>
							</a>
						  </div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <div
							style={{
							  color: "#707070",
							  textAlign: "center",
							  wordWrap: "break-word"
							}}
						  >
							<span style={{ fontSize: "30px" }}>
							  <a
								href="https://wa.me/6282392462636"
								target="_blank"
								style={{ color: "#707070" }}
							  >
								<i className="icofont icofont-brand-whatsapp"></i>{" "}
							  </a>
							  <a
								href="https://www.instagram.com/digidesa"
								target="_blank"
								style={{ color: "#707070" }}
							  >
								<i className="fa fa-instagram"></i>
							  </a>
							</span>
							<a
							  href="https://www.digidesa.com"
							  target="_blank"
							  style={{ color: "#707070" }}
							>
							  <h6>www.digidesa.com</h6>
							</a>
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				)
				: 
				(
				  <div className="row">
					<div className="col-sm-12">
					  <div className="row">
						<div className="col-sm-12 col-md-6 col-lg-8">
						  <div className="card layanan-baru">
							<div className="card-body">
							  <div className="row">{tampilLayananAdmin}</div>
							</div>
						  </div>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-4">
						  <div className="row">
							<div className="col-sm-12 col-md-12">
							  <div className="card" style={{ borderRadius: "10px" }}>
								<div className="text-center">
								  {bannerAplikasi}
								</div>
							  </div>
							</div>
							{/* pengajuan selesai */}
							<div className="col-sm-12 col-md-12">
							  {/* <Link
								to={{
								  pathname:
									process.env.PUBLIC_URL + "/admin/disposisi",
								  tabProps: "5"
								}}
							  > */}
								<div className="pengajuan-selesai">
								  <b>
									<span>
									  {/* <b>24%</b> */}
									  {this.state.jumlahSelesai}
									</span>
									<h4>Pengajuan</h4>
									{/* <span>100 Pengajuan</span> */}
								  </b>
								</div>
							  {/* </Link> */}
							</div>
	  
							
							
						  {/* pengajuan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname:
								  process.env.PUBLIC_URL + "/admin/disposisi",
								tabProps: "2"
							  }}
							>
							  <div className="pengajuan-menunggu-eksekusi">
								<b>
								  <span>{this.state.jumlahMenungguEksekusi}</span>
								  <h4>Proses</h4>
								  <h6>Menunggu Eksekusi</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						  {/* pengaduan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<div className="pengaduan-menunggu-eksekusi">
							  <b>
								<span>{this.state.jumlahPengaduanDiajukan}</span>
								<h4>Selesai</h4>
								<h6>Diajukan</h6>
							  </b>
							</div>
						  </div> */}
						  {/* catatan menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname: process.env.PUBLIC_URL + "/admin/catatan",
								tabProps: "1"
							  }}
							>
							  <div className="catatan-menunggu-eksekusi">
								<b>
								  <span>
									{this.state.jumlahCatatanMenungguEksekusi}
								  </span>
								  <h4>Tolak</h4>
								  <h6>Belum Selesai</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						  {/* verifikasi menunggu eksekusi */}
						  {/* <div className="col-sm-12 col-md-12">
							<Link
							  to={{
								pathname:
								  process.env.PUBLIC_URL + "/admin/verifikasi",
								tabProps: "2"
							  }}
							>
							  <div className="verifikasi-menunggu-eksekusi">
								<b>
								  <span>
									{this.state.jumlahVerifikasiMenungguEksekusi}
								  </span>
								  <h4>Verifikasi</h4>
								  <h6>Menunggu Verifikasi</h6>
								</b>
							  </div>
							</Link>
						  </div> */}
						</div>
					  </div>
					</div>
				  </div>
					<div className="col-sm-12">
					  <div className="row" style={{ marginTop: "50px" }}>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <a
							href="https://solusidatamadani.co.id/web"
							target="_blank"
						  >
							<div style={{ color: "#707070", textAlign: "center" }}>
							  <img
								src={require("../assets/images/esdeem.png")}
								alt=""
								style={{ maxWidth: "70%", maxHeight: "60px" }}
							  />
							</div>
						  </a>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <div
							style={{
							  color: "#707070",
							  textAlign: "center",
							  wordWrap: "break-word"
							}}
						  >
							<h5>
							  <b>PT. Solusi Data Madani</b>
							</h5>
							<h6>We Make IT Easier</h6>
							<h7>2021 All Rights Reserved</h7>
						  </div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <div
							style={{
							  color: "#707070",
							  textAlign: "center",
							  wordWrap: "break-word"
							}}
						  >
							<h5>
							  <b>Hubungi Kami</b>
							</h5>
							<a
							  href="tel: (0761) 849 861"
							  target="_blank"
							  style={{ color: "#707070" }}
							>
							  <h6>(0761) 849 861</h6>
							</a>
							<a
							  href="mailto: info@solusidatamadani.co.id"
							  target="_blank"
							  style={{ color: "#707070" }}
							>
							  <h7>info@solusidatamadani.co.id</h7>
							</a>
						  </div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-3 mb-4">
						  <div
							style={{
							  color: "#707070",
							  textAlign: "center",
							  wordWrap: "break-word"
							}}
						  >
							<span style={{ fontSize: "30px" }}>
							  <a
								href="https://wa.me/6282392462636"
								target="_blank"
								style={{ color: "#707070" }}
							  >
								<i className="icofont icofont-brand-whatsapp"></i>{" "}
							  </a>
							  <a
								href="https://www.instagram.com/digidesa"
								target="_blank"
								style={{ color: "#707070" }}
							  >
								<i className="fa fa-instagram"></i>
							  </a>
							</span>
							<a
							  href="https://www.digidesa.com"
							  target="_blank"
							  style={{ color: "#707070" }}
							>
							  <h6>www.digidesa.com</h6>
							</a>
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				)
			  }
				{/* </div> */}
			  </div>
			</div>
		  );
		}
	  }
	  
	  export default Dashboard;